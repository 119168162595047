import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import PageBreadcrumb from "../../PageBreadcrumb";
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../utils";
import { Menu, Switch, Button, Tabs } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import LeadAllReminderList from "./allReminder"
import LeadMyReminderList from "./myReminder"
const LeadReminderList = (props) => {
  const { dir = 0 } = props;
  const { TabPane } = Tabs;

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: "Reminder",
    },
  
  ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };
  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,   MODULE_CODED_VALUES.CRM_LEAD_ALL_REMINDER);
  },[])

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
       {
        permissionsData?.update ? <Menu.Item
        key="1"
        value="edit"
        onClick={() => {
          props.updateState({
            leadSourceListDrawer: true,
            ...item,
          });
        }}
      >
        <i className={ICONS.EDIT} /> Edit
      </Menu.Item>: ""
       }
        
{
  permissionsData?.delete ?  <Menu.Item
  key="2"
  value="delete"
  onClick={() => {
    props.showModal({
      modalBody: "Are you sure you want to delete?",
      handleSubmit: () => {
        props.deleteCrmLeadSource(item, props);
      },
    });
  }}
>
  <i className={ICONS.DELETE} /> Delete
</Menu.Item>: ""
}
       
      </Menu>
    );
  };
  const callTabApi = (key, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 0,
      createdByEmpId: props.companyInfo?.relationshipEmployees?.relationshipEmployeeId,
      pageSize: 25,
    };
// fetchCallLogsByRid,fetchCallLogsByUserId
    if (key === 'all_reminder') {
      // will do something
      props.getAllLeadReminder(payload);
      props.updateState({
        isMyLeads: false,
      })
    } else if (key === 'my_reminder') {
      //will do something
      props.getAllLeadReminderByUserId(payload);
      props.updateState({
        isMyLeads: true,
      })
    }


  }
 

  return (
   
<>
<div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            Leads Reminder
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>
    <div className="view-container">
    {permissionsData.create ?<Tabs type="line"
          onChange={(key) => {
            callTabApi(key, props);
          }}
        >
          <TabPane
            tab="All Reminder"
            key="all_reminder"
          >
            <LeadAllReminderList {...props} />

          </TabPane>
          <TabPane
            tab="My Reminder"
            key="my_reminder"
          >
            <LeadMyReminderList {...props} />

          </TabPane>

        </Tabs>:
            <LeadMyReminderList {...props} />
        
        }
    </div>
</>
  );
};

export default injectIntl(LeadReminderList);
