import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { showToasterMessage } from '../../../../../utils';

class BankInfo extends Component {
    constructor(props){
        super(props)
    }
    render() {
        const {permissionsData} = this.props;
        const bankDetailList = this.props.companyInfoData.bankDetails || [];

        const agGridStyle = {
            height: '100%',
            width: '100%'
        };

        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }
        const getRowHeight = (params) => {
            return 30;
        }

        const columnDefs = [
            {
                headerName: "#",
                resizable: true,
                width: 50,
               // rowDrag: true,
                cellRendererFramework: function (link) {
                    return <div>
                        {link.rowIndex + 1}
                    </div>
                }
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.bankName' defaultMessage='' />  </div>;
                },
                field: "name",
                resizable: true,
                editable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                },
                width: 300,
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.payeeName' defaultMessage='' />  </div>;
                },
                field: "payeeName",
                resizable: true,
                editable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                },
                width: 300,
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.accountNumber' defaultMessage='' />  </div>;
                },
                field: "accountNumber",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.ifsc' defaultMessage='' />  </div>;
                },
                field: "ifscCode",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                        this.gridApi.refreshCells({ force: true });
                        params.api.stopEditing(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.routingNumber' defaultMessage='' />  </div>;
                },
                field: "routingNumber",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                field: 'action',  lockPosition: true, pinned: 'right',
                width: 180,
                cellRendererFramework: (params) => <div>
                    {(params.data.origination !== 'System') ? <div>
                        <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    {
                                        permissionsData.create ?  <Link onClick={() => {

                                            this.gridApi.refreshCells({ force: true }); // its need to reflact data in node
                                            let rowData = this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data;
                                            if (rowData.accountNumber && rowData.ifscCode && rowData.name && rowData.payeeName) {
                                                const modalData = {
                                                    modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' values={{ value: '' }} />,
                                                    handleSubmit: () => {
                                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId
                                                        this.props.createBankDetail(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.props);
                                                        // this.props.popModalFromStack();
                                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                                        this.gridApi.refreshCells({ force: true });
                                                    }
                                                };
                                                this.props.pushModalToStack(modalData);
                                            } else {
                                            if (!rowData.name) {
                                                showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.name.validation', defaultMessage: '' }) })
                                            } else if (!rowData.payeeName) {
                                                showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.payeeName.validation', defaultMessage: '' }) })
                                            } else if (!rowData.accountNumber) {
                                                showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.accountNumber.validation', defaultMessage: '' }) })
                                            } else if (!rowData.ifscCode) {
                                                showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.ifsc.validation', defaultMessage: '' }) })
                                            }
                                                this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                                this.gridApi.refreshCells({ force: true });
                                        }
    
                                        }} >
                                            <i className="fa fa-save" />
                                        </Link>: ""
                                    }
                                   
                                    {
                                        permissionsData.delete ? <Link onClick={() => {
                                            params.api.stopEditing(true);
                                            this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                            this.gridApi.refreshCells({ force: true });
                                        }} >
                                            <i className="fa fa-ban" />
                                        </Link>: ""
                                    }
                                    
                                </div>
                            }

                            {!params.node.data.isToEdit &&
                                <div className="table-cell-action" >
                                    {
                                        permissionsData.update ?  <Link onClick={() => {
                                            this.gridApi.forEachNode((rowNode, index) =>{
                                                this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                            });
                                            this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                            this.gridApi.refreshCells({ columns: ["action"], force: true });
                                            var startEditingParams = {
                                                rowIndex: params.node.rowIndex,
                                                colKey: params.column.getId(),
                                              };
                                            params.api.startEditingCell(startEditingParams);
                                        }}>
                                            <i className="fa fa-edit" />
                                        </Link>: ""
                                    }
                                   {
                                    permissionsData.delete ?  <Link onClick={() => {
                                        const modalData = {
                                            modalBody: <FormattedMessage id='deleteItem.confirmation' defaultMessage='' values={{value:''}} />  ,
                                            handleSubmit: () => {
                                                this.props.deleteBankDetail(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.props);
                                            //   this.props.popModalFromStack()
                                            }
                                          };
                                          this.props.pushModalToStack(modalData);
                                    }}>
                                        <i className="fa fa-remove" />
                                    </Link>: ""
                                   }
                                   
                                </div>}
                        </div>
                    </div>
                        : <div></div>
                    }
                </div>
            }
        ]

        const onGridReady = (params) => {
            this.gridApi = params.api;
          }
        return (
            <Fragment>
                <div className=''>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={bankDetailList || []}
                            rowDragManaged={true}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid>
                    </div>
                    <Button className="add-new-record" disabled={!(permissionsData.create)} onClick={
                            () => {
                                let newRowIndex = -1;
                                this.gridApi.forEachNode((rowNode, index) =>{
                                    newRowIndex = index;
                                    this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                });
                                this.gridApi.stopEditing();
                                this.gridApi.updateRowData({ add: [{isToEdit: true}] });
                                this.gridApi.startEditingCell({
                                    rowIndex: newRowIndex+1,
                                    colKey: "name"
                                });
                                this.gridApi.refreshCells({ force: true });
                            }
                        }><FormattedMessage id='modal.settings.companyInfo.bankInfo.newAccount' defaultMessage='' />  </Button>
                </div>
            </Fragment>
        )
    }
}

export default BankInfo;
