import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import { Button, Card, Col, Row } from "antd";
import "./index.scss";
import { ERP_THEMES } from "../../../static/constants";
import themePlaceholderImage from '../../../assets/images/theme-placeholder.png'
import impulse from '../../../assets/images/theme.png'

import tanakTheme from '../../../assets/images/tanak-theme.jpg'
const Themes = (props) => {
  console.log(props ,"HEader Props")
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.appearance" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.appearance.themes"
          defaultMessage=""
        />
      ),
    },
  ];


   const handleCustomize = () =>{
    props.history.push('themes/customize')
   }

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.appearance.themes"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>

      <div className="view-container">
        <Row gutter={[16,16]}>
          <Col span={8}>
            <div className="themes-page-conatiner">
              <Card className="theme-card">
                  <div className="card-content-wrapper">
                    <img src={impulse} alt="img" />
                  </div>
                <div className="card-footer">
                  <div>
                    <p className="themes-name-style">{ ERP_THEMES[props.relationshipId] === "Vogue" ? 'Active:': ''} Impulse</p>
                  </div>

                  <div>
                    {
                      ERP_THEMES[props.relationshipId] === "Vogue"  ? <Button onClick={handleCustomize} className="customize-btn">Customize</Button>
                     : "" 
                    }
                  </div>
                </div>
              </Card>
            </div>
          </Col>
          <Col span={8}>
            <div className="themes-page-conatiner">
              <Card className="theme-card">
                  <div className="card-content-wrapper">
                    <img src={tanakTheme} alt="img" />
                  </div>
                <div className="card-footer">
                  <div>
                    <p className="themes-name-style">{ERP_THEMES[props.relationshipId] === "Dapper" ? 'Active:': ''} Breeze</p>
                  </div>

                  <div>
                  {
                     ERP_THEMES[props.relationshipId] === "Dapper"  ? <Button onClick={handleCustomize} className="customize-btn">Customize</Button>
                     : "" 
                    }
                  </div>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Themes;
