import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal, hideModal ,fetchCountries } from '../../../../../actions/commonActions';
import SupportAndSocialComp from '../../../../../components/modal/modalBody/eStore/SupportAndSocial';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
import { updateOrCreateEcomSettings } from '../../../../eStore/menus/action';
import { updateAdditionalValue } from '../Checkout/action';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded } from '../../../../../utils';
class SupportAndSocial extends Component {
    constructor(props){
        super(props);
        this.state= {
          isHeaderMenuLoaded:true,
          additionalValue :{},
          addressValue:{},
         initialFormState: {
                facebookUrl:"facebook.com/",
                twitterUrl:"twitter.com/",
                instagramUrl:"instagram.com/",
                linkedinUrl:"linkedin.com/",
                youtubeUrl:"youtube.com/",
                pinterestUrl: "pinterest.com/",
              }
        }
    }
    componentDidMount() {
        const companyInfo = this.props.userInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
       
        };
       
        this.props.fetchE_StoreData(payload)
        fetchDataIfNeeded('fetchCountries', 'countries', this.props);
      }
      componentWillReceiveProps(props){
        if(this.state.isHeaderMenuLoaded && Object.keys(props.storeData || {}).length ){
          
          let value = props.storeData;
         
              const obj = {
                facebookUrl: value?.facebookUrl||"facebook.com/",
                twitterUrl: value?.twitterUrl||"twitter.com/",
                instagramUrl: value?.instagramUrl || "instagram.com/",
                linkedinUrl: value?.linkedinUrl || "linkedin.com/",
                youtubeUrl: value?.linkedinUrl || "youtube.com/",
                pinterestUrl: value?.pinterestUrl || "pinterest.com/",
              }

              
              this.setState({ isHeaderMenuLoaded: false ,initialFormState: obj ,additionalValue: JSON.parse(value?.additionalValues || "{}")})
             
  
  
            
         
        }
    
      }
    render() {
        return <>
            <SupportAndSocialComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        remarksData: state.settings.remarksData,
        userInfo: state.common.userInfo,
        countries: state.common.countries,
        storeData:state.common.store_values,
        companyInfo: state.common.companyInfo,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
 fetchE_StoreData,
 updateOrCreateEcomSettings,
 showModal,
 hideModal,
 updateAdditionalValue,
 fetchCountries,
}, dispatch);

export default connect(mapStateToProps ,mapDispatchToProps)(SupportAndSocial);