import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import DropdownAction from "antd/lib/dropdown";
import { MultipleSelectDropdown } from "../../general/MultipleSelectDropdown";
import { Pagination, Menu, Popover, Input, Empty, Checkbox } from "antd";
import {
  getMomentDateForUIReadOnly,
  sortTable,
  capitalizeFirstLetter,
  formatDateForBackendPlain,
  showToasterMessage,
  checkACLPermission,
  getPermissionData,
} from "../../../utils";
import { Dropdown } from "../../general/Dropdown";
import * as find from "lodash.find";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import { InsertRowRightOutlined } from "@ant-design/icons";
import LeadProfile from "../../../containers/modal/modalBody/leads/profile";
import moment from "moment-timezone";
import * as debounce from "lodash.debounce";

const LeadsTable = (props) => {
  const {
    companyInfo,
    pageNumber = 1,
    dir = 0,
    updateState,
    allLeadList,
    allLeadListCount,
    isMyLeads = false,
    searchedByText,
    searchedCustomers,
    permissions
  } = props;

  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CRM_OPPORTUNITY);
  },[])
 
  const { Search } = Input;

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("lead_table"), cellIndex, 1 - dir);
  };

  const _getCustomerDataFromLead = (item) => {
    const customerData = {
      relationshipId: item.relationshipId,
      companyName: (item.crmLeadDetailsList || []).find(
        (obj) => obj.label === "Full Name"
      ).value,
      formId: item.crmLeadFormId,
      boContactList: [
        {
          cellPhone: (item.crmLeadDetailsList || []).find(
            (obj) => obj.label === "Mobile No."
          ).value,
          emailAddress: (item.crmLeadDetailsList || []).find(
            (obj) => obj.label === "Email"
          ).value,
          firstName: (
            (item.crmLeadDetailsList || []).find(
              (obj) => obj.label === "Full Name"
            ).value || ""
          ).split(" ")[0],
          lastName: (
            (item.crmLeadDetailsList || []).find(
              (obj) => obj.label === "Full Name"
            ).value || ""
          ).split(" ")[1],
          leadId: item.crmLeadId,

          isPrimaryContact: 1,
        },
      ],
      crmLeadId: item.crmLeadId,
      sourceId: item.leadSourceId,
      sourceName: item.leadSourceName,
      leadStatusId: (props.crmLeadStatusList || []).find(
        (obj) => obj.statusName === "Customer"
      ).leadStatusId,
      leadStatusName: (props.crmLeadStatusList || []).find(
        (obj) => obj.statusName === "Customer"
      ).statusName,
      customerType: "individual",
      isLeadToCustomer: true,
    };
    return customerData;
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        { permissionsData?.update && !item.isLeadToCustomer  ? (
          <Menu.Item
            key="1"
            value="customer"
            onClick={() => {
              const customerData = _getCustomerDataFromLead(item);

              props.showModal({
                modalBody: "Are you sure to convert lead to customer?",
                handleSubmit: () => {
                  props.updateHeaderState({
                    customer: {
                      ...props.customer,
                      customerDrawerVisible: true,
                      isUpdate: Object.values(item || {}).length ? true : "",
                      customerData: customerData,
                    },
                  });
                },
              });
            }}
          >
            <i className={"fi fi-rr-arrow-up-right-from-square"} /> Customer
          </Menu.Item>
        ) : (
          ""
        )}

        { (permissionsData?.delete && !isMyLeads) ? (
          <Menu.Item
            key="3"
            value="delete"
            onClick={() => {
              props.showModal({
                modalBody: "Are you sure you want to delete?",
                handleSubmit: () => {
                  props.deleteLead({ ...item }, props);
                },
              });
            }}
          >
            <i className={ICONS.DELETE} /> <FormattedMessage id="delete.text" />
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
    );
  };

  const handleRowClick = (e) => {
    props.updateState({
      activeRowData: e,
      activeRowId: e.crmLeadId,
    });
  };

  const getLabelValue = (item, label) => {
    if (
      !item ||
      !item.crmLeadDetailsList ||
      !item.crmLeadDetailsList.length ||
      !label
    ) {
      return <div className="empty-data"></div>;
    } else {
      const lblObj = find(item.crmLeadDetailsList, { label: label }) || {};
      return lblObj.value || <div className="empty-data"></div>;
    }
  };

  const isReminderExist = (e) => {
    for (let i = 0; i < (props.allLeadReminderList || []).length; i++) {
      if (props.allLeadReminderList[i].crmLeadId === e.crmLeadId) {
        return true;
      }
    }
    return false;
  };

  const isOldDate = (item) => {
    const remObj =
      (props.allLeadReminderList || []).find(
        (obj) => obj.crmLeadId === item.crmLeadId
      ) || {};
    const currentDateTime = moment.utc();
    const providedDateTime = moment.utc(
      remObj.remiderDateAndTime,
      CONSTANTS.BACKEND_FORMAT
    );
    if (providedDateTime.isBefore(currentDateTime)) {
      return true;
    }
    return false;
  };
  const handelClose = ()=>{
    props.updateStateLayer({
      endTime:formatDateForBackendPlain(new Date())
    })
    
props.popModalFromStack();

  }

  const openLeadProfile = (rowData) => {
    if (rowData.isLeadToCustomer) {
      return;
    }
    const modalData = {
      title: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Lead profile</div>
        </div>
      ),
      modalBody: <LeadProfile {...props} leadData={rowData} />,
      alertCancel: true,
      onCancel: () => {
        props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit:handelClose
        })
      },
      hideFooter: true,
      hideTitle: true,
      width: "100%",
      height: "100%",
      maskClosable: true,
      keyboard: false,
      wrapClassName: "new-transaction-wrapper",
    };
    props.pushModalToStack(modalData);
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber,
      pageSize,
    };
    props.fetchAllLeadList(payload);
    props.getAllLeadListCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber,
      pageSize,
    };
    props.fetchAllLeadList(payload);
    props.getAllLeadListCount(payload);
  };

  let allLeadsAssigned = [];
  if (isMyLeads) {
    allLeadsAssigned = allLeadList[pageNumber].filter((obj) =>
      obj.crmLeadEmpAssignedList
        .map((obj) => obj.relEmpId)
        .includes(props.userInfo?.relationshipEmployeeId)
    );
  } else {
    allLeadsAssigned = allLeadList[pageNumber];
  }

  const onSearchedInputChange = debounce((searchedByText, props) => {
    handleSearchedText(searchedByText, props);
  }, 100);

  const handleSearchedText = (searchedByText, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize || 25,
      searchedText: searchedByText,
      filters: { ...props.filters, searchedStr: searchedByText },
      companyInfo: props.companyInfo,
    };
    if (searchedByText.length) {
      if (searchedByText.length >= 3) {
        props.fetchAllLeadList(payload);
        props.getAllLeadListCount(payload);
      }
    } else {
      props.fetchAllLeadList({
        ...payload,
        filters: { ...props.filters, searchedStr: undefined },
      });
      props.getAllLeadListCount({
        ...payload,
        filters: { ...props.filters, searchedStr: undefined },
      });
    }
  };

  return (
    <>
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">
        Opportunities
          </div>
          {allLeadListCount ? (
            <>
              <div className="vertical-sep" />
              <div>{allLeadListCount || ""}</div>
            </>
          ) : (
            ""
          )}
        </div>

        {/* <div className="right-actions">
          <Search
            placeholder="Search by Name, Email, Mobile No."
            value={props.searchedText}
            onChange={(e) => {
              updateState({ searchedText: e.target.value, pageNumber: 1 });
              onSearchedInputChange(e.target.value, props);
            }}
            onSearch={(searchedText) => {
              updateState({ searchedText, pageNumber: 1 });
              onSearchedInputChange(searchedText, props);
              handleSearchedText(searchedText, props);
            }}
          />


          <button
            className="action-btn"
            onClick={() => {
              props.updateState({
                allLeadsFilterDrawerVisible: true,
              });
            }}
          >
            <i className={`${ICONS.FILTER} mr5`} />
            <FormattedMessage id="filter.text" defaultMessage="" />
          </button>

          <button
            className="action-btn"
            onClick={() => {

              if(props.selectedLead?.length){
                props.updateState({
                  allLeadsTransferDrawerVisible: true,
                });
              }else{
                showToasterMessage({
                  messageType: "warning",
                  description: "Select Lead to transfer",
                });
              }
              
            }}
          >
            <i className={`${ICONS.FILTER} mr5`} />
            Transfer
          </button>

          <button>
            <Popover
              content={""}
              title={<div className="text-center">Add/Remove</div>}
              placement="left"
              trigger="click"
            >
              <InsertRowRightOutlined /> Columns
            </Popover>
          </button>
        </div> */}
      </div>
      <div className="table-container">
        <table id="lead_table">
          <thead>
            <tr>
              <th>
                #
              </th>
              <th width="6%">
                {/* <Checkbox
                  onChange={(e) => {
                    selectAll(e.target.checked);
                  }}
                  checked={isCheckedAll()}
                /> */}
                {/* &nbsp;
                <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                  <i className="fi fi-rr-angle-down"></i>
                </DropdownAction> */}
                S.No.
              </th>

              <th onClick={sortColumn}>
                Date Created <i className={ICONS.SORT} />
              </th>
              {/* <th onClick={sortColumn}>
                Form Name <i className={ICONS.SORT} />
              </th> */}
              <th>Reminder</th>

              <th onClick={sortColumn}>
                Full Name <i className={ICONS.SORT} />
              </th>
              <th onClick={sortColumn}>
                Mobile No. <i className={ICONS.SORT} />
              </th>

              <th onClick={sortColumn}>
                Email <i className={ICONS.SORT} />
              </th>

              <th onClick={sortColumn}>
                Source <i className={ICONS.SORT} />
              </th>

              {/* <th onClick={sortColumn}>
                Source Referral URL <i className={ICONS.SORT} />
              </th> */}
              <th>Status</th>
              <th>Assign to</th>
              {
                (permissionsData?.update || permissionsData?.delete) ?  <th>Action</th>: ""
              }
             
            </tr>
          </thead>
          <tbody>
            {(allLeadsAssigned || []).length ? (
              allLeadsAssigned.map((e, i) => {
                return (
                  <tr key={"lead" + i}>
                    <td><Checkbox  
                    
                    onClick={(event)=>{
                      const {checked} = event.target;
                      let selected = props.selectedLead || [];
                      if(checked){
                        selected.push(e.crmLeadId);
                      }else{
                       selected = (props.selectedLead || [])?.filter(item=>item!==e.crmLeadId);
                      }
                      props.updateState({
                        selectedLead:selected
                      });
                    }}
                    checked={props.selectedLead?.includes(e.crmLeadId)}
                    /></td>
                    <td>{i + 1}</td>
                    <td
                      className="one-line-view"
                      // onClick={() => handleRowClick(e)}
                    >
                      {e.dateCreated
                        ? getMomentDateForUIReadOnly({
                            date: e.dateCreated,
                            format: CONSTANTS.TABLE_DATE_FORMAT,
                          })
                        : 
                        <div className="empty-data"></div>
                        
                        }
                    </td>
                    {/* <td  onClick={() => handleRowClick(e)} >{e.formName || "-"}</td> */}
                    <td onClick={() => handleRowClick(e)}>
                      {isReminderExist(e) ? (
                        isOldDate(e) ? (
                          <div className="reminder-exp">
                            <i className="ffi fi-rs-time-quarter-past mt5" />{" "}
                            &nbsp; Expired
                          </div>
                        ) : (
                          <div className="reminder-up">
                            <i className="fi fi-rr-clock mt5" /> &nbsp; Upcoming
                          </div>
                        )
                      ) : (
                        <div className="empty-data"></div>
                      )}
                    </td>
                    <td>
                      <div
                        className={!e.isLeadToCustomer ? "link" : ""}
                        onClick={() => openLeadProfile(e, props)}
                      >
                        {capitalizeFirstLetter(getLabelValue(e, "Full Name"))}
                      </div>
                    </td>
                    <td onClick={() => handleRowClick(e)}>
                      {getLabelValue(e, "Mobile No.")}
                    </td>
                    <td onClick={() => handleRowClick(e)}>
                      {getLabelValue(e, "Email")}
                    </td>
                    <td onClick={() => handleRowClick(e)}>
                      {e.leadSourceName || 
                        <div className="empty-data"></div>
                      
                      }
                    </td>
                    {/* <td onClick={() => handleRowClick(e)}>{e.source_referral_url || "-"}</td> */}
                    <td>
                      {/* <Dropdown
                        style={{ minWidth: "150px", maxHeight: "38px" }}
                        items={props.crmLeadStatusList}
                        placeholder={"Select Status"}
                        valueKeyName="statusName"
                        optionKeyName="leadStatusId"
                        value={e.statusName}
                        disabled={e.isLeadToCustomer}
                        onSelect={(id, options) => {
                          let payload = e;
                          payload.statusId = options.key;
                          payload.statusName = options.name;
                          payload.relEmpId =
                            props.userInfo.relationshipEmployeeId;
                          props.changeLeadStatus(payload, props);
                        }}
                      /> */}
                      {e.statusName||""}
                    </td>
                    <td>
                      {/* <MultipleSelectDropdown
                        style={{ width: "150px", maxHeight: "38px" }}
                        items={props.leadAssignList || []}
                        placeholder={"Assign to"}
                        mode="multiple"
                        allowClear={false}
                        disabled={e.isLeadToCustomer}
                        // onDeSelect={(empId, options) => {
                        //     let tempList = props.selectedAssignList || [];
                        //     const empExits = tempList.find((obj)=> obj.relEmployeeId === options.key)
                        //     if(empExits){
                        //         tempList = tempList.filter((obj) => obj.relEmployeeId !== options.key);
                        //         props.updateState({selectedAssignList: tempList});
                        //     }
                        // }}
                        selectedValue={(e.crmLeadEmpAssignedList || []).map(
                          (obj) => {
                            return obj.relEmpId;
                          }
                        )}
                        valueKeyName="fullName"
                        valueKeyName2="hierarchyName"
                        optionKeyName="relationshipEmployeeId"
                        onSelect={(empId, options) => {
                          let tempList = [];
                          tempList.push({
                            relEmpId: Number(options.key),
                            relationshipId: props.companyInfo.relationshipId,
                            crmLeadId: e.crmLeadId,
                          });
                          props.changeCrmLeadEmpAssignedList(tempList, props);
                        }}

                      /> */}
                      {(e.crmLeadEmpAssignedList || []).map(
                          (obj) => {
                            return obj.firstName;
                          }
                        )?.join(",")}
                    </td>
                    <td style={{ paddingLeft: "50px" }}>
                      {
                        (permissionsData?.update || permissionsData?.delete) ?   <div className="action-icon">
                        <DropdownAction
                       
                          overlay={actionMenu(e)}
                          trigger={["click"]}
                        >
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </div>: ""
                      }
                     
                    </td>
                  </tr>
                );
                // })
              })
            ) : (
              <tr key="empty">
                <td colSpan={"100%"}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={allLeadListCount}
            // showTotal={true}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default injectIntl(LeadsTable);
