import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageUserComponent from "../../../../../components/modal/modalBody/settings/ManageUser";
import { fetchSalutation, deleteEmployee, showModal, hideModal } from "../../../../../actions/commonActions";
import { createOrUpdteUser, fetchAllEmployees } from "./action"
import find from 'lodash.find';
// import Administration from '../../../../../components/modal/modalBody/settings/ManageUser/Administration';
import { getAllStudentForm } from './../../../../Student/studentFormList/action';
import EmployeeDrawer from '../../../../drawer/company/EmployeeManagement/Employee';
import { MODULE_CODED_VALUES } from '../../../../../static/constants';

class ManageUser extends Component {
    constructor(props) {
        super(props);
   //   this is add to check if we need to show all tabs or not according to the data coming
        let tabsToshow={
            sales:false,
            purchase:false,
            inventory:false,
            finance:false,
            administraction:false,
            integration:false,
            school:false,
            crm:false,
            hrms:false,
            eStore:false
        }

        let salesDataSource = [
            {
                key: 'cust-1',
                title: 'Customers',
                moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-2',
                title: 'Customers Billing Address',
                moduleCode: MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-3',
                title: 'Customers Shipping Address',
                moduleCode: MODULE_CODED_VALUES.CUSTOMER_ADDRESS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-4',
                title: 'Customers Activity',
                moduleCode: MODULE_CODED_VALUES.CUSTOMER_ACTIVITY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-5',
                title: 'Customers Communication',
                moduleCode: MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-6',
                title: 'Sales Inquiry',
                moduleCode: MODULE_CODED_VALUES.SALES_INQUIRY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-7',
                title: 'Sales Quote',
                moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-8',
                title: 'Sales Quote Multicurrency',
                view: false,
                moduleCode: MODULE_CODED_VALUES.SALES_QUOTE_MULTI_CURRENCY,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-9',
                title: 'Sales Order',
                moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-9',
                title: 'Sales Order Multicurrency',
                view: false,
                moduleCode: MODULE_CODED_VALUES.SALES_ORDER_MULTI_CURRENCY,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-10',
                title: 'Sales Order Package',
                view: false,
                moduleCode: MODULE_CODED_VALUES.SALES_ORDER_PACKAGE,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-11',
                title: 'Sales Order Shipment',
                view: false,
                moduleCode: MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-12',
                title: 'Sales Invoice',
                moduleCode: MODULE_CODED_VALUES.SALES_INVOICE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-13',
                title: 'Sales Invoice Multicurrency',
                view: false,
                moduleCode: MODULE_CODED_VALUES.SALES_INVOICE_MULTI_CURRENCY,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-14',
                title: 'Sales Payment',
                view: false,
                moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-15',
                title: 'Credit Memo',
                view: false,
                moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }, {
                key: 'cust-16',
                title: 'Quality Check',
                view: false,
                moduleCode: MODULE_CODED_VALUES.QUALITY_CHECK,
                create: false,
                update: false,
                delete: false,
                sendEmail: false,
                fullControl: false,
            },
            {
                key: 'cust-18',
                title: 'Sales Support',
                moduleCode: MODULE_CODED_VALUES.SALES_SUPPORT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-19',
                title: 'Sales Report',
                moduleCode: MODULE_CODED_VALUES.SALES_REPORTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: 'cust-20',
                title: 'Sales Return/Replace',
                moduleCode: MODULE_CODED_VALUES.AMEND_SALES_ORDER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: false,
                fullControl: false,
            }
        ];

        let purchaseDataSource = [
            {
                key: '1',
                title: 'Suppliers',
                moduleCode: MODULE_CODED_VALUES.SUPPLIERS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '2',
                title: 'Suppliers Billing Address',
                moduleCode: MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '3',
                title: 'Suppliers Shipping Address',
                moduleCode: MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '4',
                title: 'Suppliers Activity',
                moduleCode: MODULE_CODED_VALUES.SUPPLIER_ACTIVITY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '5',
                title: 'Suppliers Communication',
                moduleCode: MODULE_CODED_VALUES.SUPPLIER_CONTACTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '6',
                title: 'Purchase Request',
                moduleCode: MODULE_CODED_VALUES.PURCHASE_REQUEST,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '7',
                title: 'Purchase Order',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '8',
                title: 'RFQ',
                moduleCode: MODULE_CODED_VALUES.RFQ,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '9',
                title: 'Purchase Order Multicurrency',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER_MULTI_CURRENCY,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '10',
                title: 'Purchase Invoice',
                moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '11',
                title: 'Purchase Invoice Multicurrency',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE_MULTI_CURRENCY,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '15',
                title: 'Purchase GRN',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_GRN,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: '5',
            //     title: 'Payment',
            //     view: true,
            //     moduleCode: "E",
            //     create: true,
            //     update: true,
            //     delete: true,
            //     sendEmail: true,
            //     fullControl: true,
            // },
            {
                key: '12',
                title: 'Purchase Payment',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PAY_INVOICE,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: '5',
            //     title: 'Recieve Payment',
            //     view: true,
            //     moduleCode: "BE",
            //     create: true,
            //     update: true,
            //     delete: true,
            //     sendEmail: true,
            //     fullControl: true,
            // },
            {
                key: '13',
                title: 'Debit Memo',
                view: false,
                moduleCode: MODULE_CODED_VALUES.DEBIT_MEMO,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '14',
                title: 'Landed Cost',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_LANDED_COST,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '14',
                title: 'Purchase Report',
                view: false,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_REPORTS,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let inventoryDataSource = [
            {
                key: 'inv-1',
                title: 'Product',
                moduleCode: MODULE_CODED_VALUES.PRODUCTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-2',
                title: 'Product Category',
                moduleCode: MODULE_CODED_VALUES.PRODUCT_CATEGORY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }, {
                key: 'inv-3',
                title: 'Product Manufacturer',
                moduleCode: MODULE_CODED_VALUES.PRODUCT_MANUFACTURER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-4',
                title: 'Product Brand',
                moduleCode: MODULE_CODED_VALUES.PRODUCT_BRAND,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-5',
                title: 'Recieve Payment',
                moduleCode: MODULE_CODED_VALUES.RECEIVE_PAYMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-6',
                title: 'Warehouse',
                moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-7',
                title: 'Stock Summary',
                moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-8',
                title: 'Stock Adjustment',
                moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }, {
                key: 'inv-9',
                title: 'Aged Stock',
                moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-10',
                moduleCode: MODULE_CODED_VALUES.PRODUCT_PRICE,
                title: 'Product Price',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-11',
                moduleCode: MODULE_CODED_VALUES.PRICE_LIST,
                title: 'Price List',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'inv-12',
            //     title: 'Extras',
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'inv-13',
                title: 'UOM',
                moduleCode: MODULE_CODED_VALUES.UOM,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-14',
                title: 'HSN',
                moduleCode: MODULE_CODED_VALUES.PRODUCT_HSN,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-15',
                title: 'Stock Transfer',
                moduleCode: MODULE_CODED_VALUES.STOCK_TRANSFER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-16',
                title: 'Inventory Reports',
                moduleCode: MODULE_CODED_VALUES.INVENTORY_REPORTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },

            {
                key: 'inv-17',
                title: 'Product Rating/Review',
                moduleCode: MODULE_CODED_VALUES.PRODUCT_REVIEW,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'inv-16',
                title: 'Warehouse Reports',
                moduleCode: MODULE_CODED_VALUES.WAREHOUSE_REPORTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },

        ];

        let financeDataSource = [
            {
                key: 'fin-x1',
                title: 'Banking',
                moduleCode: MODULE_CODED_VALUES.BANKING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-x2',
                title: 'Business Expense',
                moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-1',
                title: 'Ledger Accounts',
                moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-2',
                title: 'Balance Sheet',
                moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-3',
                title: 'Profit & loss',
                moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-4',
                title: 'Trial Balance',
                moduleCode: MODULE_CODED_VALUES.TRIAL_BALANCE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-5',
                title: 'Opening Balance',
                moduleCode: MODULE_CODED_VALUES.OPENING_BALANCE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-6',
                title: 'Journals',
                moduleCode: MODULE_CODED_VALUES.JOURNALS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'fin-7',
            //     title: 'Business Expense',
            //     moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'fin-8',
                title: 'Bank Reconciliation',
                moduleCode: MODULE_CODED_VALUES.BANK_RECONCILIATION,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-9',
                title: 'Taxes',
                moduleCode: MODULE_CODED_VALUES.TAXES,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-10',
                title: 'GST Fillings',
                moduleCode: MODULE_CODED_VALUES.GST_FILLING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-11',
                title: 'Financial Years',
                moduleCode:MODULE_CODED_VALUES.FINANCIAL_YEARS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'fin-12',
                moduleCode: MODULE_CODED_VALUES.COST_CENTERS,
                title: 'Cost Centers',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'fin-13',
            //     title: 'Finance Books',
            //     moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'fin-14',
                moduleCode: MODULE_CODED_VALUES.FINANCIAL_REPORTS,
                title: 'Financial Reports',
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let adminDataSource = [
            {
                key: 'sett-0',
                title: 'ERP Dashboard',
                moduleCode: MODULE_CODED_VALUES.ERP_DASHBOARD,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'sett-0',
                title: 'Account Profile',
                moduleCode: MODULE_CODED_VALUES.ACCOUNT_PROFILE,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'sett-0',
                title: 'Account Settings',
                moduleCode: MODULE_CODED_VALUES.ACCOUNT_SETTINGS,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'sett-0',
                title: 'Account Team Role',
                moduleCode: MODULE_CODED_VALUES.ACCOUNT_TEAM_ROLE,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'sett-0',
                title: 'Account Subscription',
                moduleCode: MODULE_CODED_VALUES.ACCOUNT_SUBSCRIPTION,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'sett-1',
                title: 'Settings',
                moduleCode: MODULE_CODED_VALUES.SETTINGS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },

            {
                key: 'sett-2',
                title: 'Document Number',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_DOCUMENTS_CENTER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-3',
                title: 'Email Template',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_EMAIL_TEMPLATES,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-4',
                title: 'Remarks',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_REMARKS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-5',
                title: 'Document Name',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_DOCUMENTS_NAME,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-6',
                title: 'Document Approoval',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_DOCUMENTS_APPROVAL,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-7',
            //     title: 'Registration Form',
            //     moduleCode: "BX",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-8',
                title: 'Communication',
                moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-9',
            //     title: 'Newsletter',
            //     moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            // {
            //     key: 'sett-10',
            //     title: 'Events',
            //     moduleCode: MODULE_CODED_VALUES.EVENTS,
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
           
            // {
            //     key: 'sett-11',
            //     title: 'Online Stores',
            //     moduleCode: "CH",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-12',
                title: 'Kiosk',
                moduleCode: MODULE_CODED_VALUES.KIOSK,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-13',
                title: 'Donation',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_DONATION,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-14',
            //     title: 'Company Information',
            //     moduleCode: "BP",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-15',
                title: 'User Management',
                moduleCode: MODULE_CODED_VALUES.SETTINGS_USER_MANAGEMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: 'sett-16',
            //     title: 'Membership',
            //     moduleCode: "CD",
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: 'sett-17',
                title: 'Fundraising',
                moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'sett-17',
                title: 'Marketing',
                moduleCode: MODULE_CODED_VALUES.MARKETING_EMAIL,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ];

        let integrationDataSource = [
            {
                key: 'ids-1',
                title: 'Payment Gateway',
                moduleCode: MODULE_CODED_VALUES.INTEGRATIONS_PAYMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'ids-2',
                title: 'Marketplace',
                moduleCode: MODULE_CODED_VALUES.INTEGRATION_MARKETPLACE,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'ids-3',
                title: 'Shipping',
                moduleCode: MODULE_CODED_VALUES.INTEGRATION_SHIPPING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'ids-4',
                title: 'Accounting',
                moduleCode: MODULE_CODED_VALUES.INTEGRATION_ACCOUNTING,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },

            {
                key: 'ids-5',
                title: 'Email',
                moduleCode: MODULE_CODED_VALUES.INTEGRATION_EMAIL,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
        ]

        let schoolDataSource = [
            {
                key: '1',
                title: 'Dashboard',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_DASHBOARD,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: '2',
                title: 'Department',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_DEPARTMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '3',
                title: 'Course Detail',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '4',
                title: 'Class Detail',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '5',
                title: 'Attendance',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: '6',
                title: 'Student',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: '7',
                title: 'Teacher',
                moduleCode:MODULE_CODED_VALUES.SCHOOL_TEACHER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
            ,
            {
                key: '8',
                title: 'School Invoice',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
                //moduleCode: "K",
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '16',
                title: 'Invoice Pay',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_PAYMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '9',
                title: 'Registration Form',
                moduleCode: MODULE_CODED_VALUES.EDUCATION_FORM_REGISTRATION,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '10',
                title: 'Communication',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_COMMUNICATION,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            // {
            //     key: '11',
            //     title: 'Broadcast Message',
            //     moduleCode:MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
            //     view: false,
            //     create: false,
            //     update: false,
            //     delete: false,
            //     sendEmail: true,
            //     fullControl: false,
            // },
            {
                key: '12',
                title: 'Registration',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '13',
                title: 'Online User',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_ONLINE_USER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '14',
                title: 'Documents',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_DOCUMENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '15',
                title: 'Recurring Payments',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '16',
                title: 'Assignments',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: '17',
                title: 'Familes',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_CUSTOMERS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cust-17',
                title: 'Student Invoice',
                moduleCode: MODULE_CODED_VALUES.SALES_INVOICE_STUDENT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },

        ]

        let crmDataSource = [
            {
                key: 'cds-1',
                title: 'All Leads',
                moduleCode: MODULE_CODED_VALUES.ALL_LEADS,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'cds-2',
                title: 'My Leads',
                moduleCode: MODULE_CODED_VALUES.MY_LEADS,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'cds-3',
                title: 'Lead Form Setting',
                moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-4',
                title: 'Customers',
                moduleCode: MODULE_CODED_VALUES.CRM_CUSTOMER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-5',
                title: 'Lead Reports',
                moduleCode: MODULE_CODED_VALUES.LEAD_REPORTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-6',
                title: 'CRM Reports',
                moduleCode: MODULE_CODED_VALUES.CRM_REPORTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-7',
                title: 'CRM All Reminder',
                moduleCode: MODULE_CODED_VALUES.CRM_LEAD_ALL_REMINDER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
              {
                key: 'cds-8',
                title: 'CRM Lead Notes',
                moduleCode: MODULE_CODED_VALUES.CRM_LEAD_NOTES,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-9',
                title: 'CRM Opportunity',
                moduleCode: MODULE_CODED_VALUES.CRM_OPPORTUNITY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-10',
                title: 'CRM All Lead Task',
                moduleCode: MODULE_CODED_VALUES.CRM_LEAD_TASK_LIST,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-11',
                title: 'CRM All Call Logs',
                moduleCode: MODULE_CODED_VALUES.CRM_CALL_LOGS_LIST,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-12',
                title: 'CRM Lead Transfer',
                moduleCode: MODULE_CODED_VALUES.CRM_LEAD_TRANSFER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'cds-13',
                title: 'CRM Lead Workflows',
                moduleCode: MODULE_CODED_VALUES.CRM_LEAD_WORKFLOWS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
              {
                key: 'cds-14',
                title: 'CRM All Lead Stage',
                moduleCode: MODULE_CODED_VALUES.CRM_LEAD_STAGE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
        ]

        let hrmsDataSource = [
            {
                key: 'hds-1',
                title: 'HRMS Employee',
                moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'hds-2',
                title: 'Company Hierarcy',
                moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-3',
                title: 'Attendance Management',
                moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'hds-4',
                title: 'Leave Management',
                moduleCode: MODULE_CODED_VALUES.HRMS_LEAVE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-5',
                title: 'Payroll Management',
                moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-6',
                title: 'Employee Services',
                moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-7',
                title: 'Learning Management System',
                moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_TRAINING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'hds-8',
                title: 'Asset Management',
                moduleCode: MODULE_CODED_VALUES.HRMS_ASSET,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ]

        let eStoreDataSource = [
            {
                key: 'eds-1',
                title: 'Appearance',
                moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'eds-2',
                title: 'Product Managment',
                moduleCode: MODULE_CODED_VALUES.E_STORE_PRODUCT_MGMT,
                view: false,
                create: false,
                update: false,
                delete: false,
                fullControl: false,
            },
            {
                key: 'eds-3',
                title: 'Store Settings',
                moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'eds-4',
                title: 'Blog Management',
                moduleCode: MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'eds-4',
                title: 'Testimonial Management',
                moduleCode: MODULE_CODED_VALUES.E_STORE_TESTIMONIALS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ]
        let communicationDataSource = [
            {
                key: 'comm-1',
                title: 'Notice',
                moduleCode: MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'comm-2',
                title: 'Newsletter',
                moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'comm-3',
                title: 'Events',
                moduleCode: MODULE_CODED_VALUES.EVENTS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'comm-4',
                title: 'Contacts',
                moduleCode: MODULE_CODED_VALUES.ALL_CONTACT,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'comm-4',
                title: 'Email Logs',
                moduleCode: MODULE_CODED_VALUES.COMMUNICATION_EMAIL_LOGS,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'comm-4',
                title: 'Distribution Group',
                moduleCode: MODULE_CODED_VALUES.COMMUNICATION_DG_GROUP,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            },
            {
                key: 'comm-4',
                title: 'Template Settings',
                moduleCode: MODULE_CODED_VALUES.COMMUNICATION_TEMPLATE_SETTING,
                view: false,
                create: false,
                update: false,
                delete: false,
                sendEmail: true,
                fullControl: false,
            }
        ]
          
        function hasPermission(dataSource,permision) {
            return dataSource.some(item => 
                permision[item.moduleCode]
            );
        }
        
        tabsToshow.sales = hasPermission(salesDataSource,props.permissions?.primary);
        tabsToshow.purchase = hasPermission(purchaseDataSource,props.permissions?.primary);
        tabsToshow.inventory = hasPermission(inventoryDataSource,props.permissions?.primary);
        tabsToshow.finance = hasPermission(financeDataSource,props.permissions?.primary);
        tabsToshow.administraction = hasPermission(adminDataSource,props.permissions?.primary);
        tabsToshow.integration = hasPermission(integrationDataSource,props.permissions?.primary);
        tabsToshow.school = hasPermission(schoolDataSource,props.permissions?.primary);
        tabsToshow.crm = hasPermission(crmDataSource,props.permissions?.primary);
        tabsToshow.hrms = hasPermission(hrmsDataSource,props.permissions?.primary);
        tabsToshow.eStore = hasPermission(eStoreDataSource,props.permissions?.primary);
        tabsToshow.communication = hasPermission(communicationDataSource,props.permissions?.primary);

//       making this code commented if needed in future uncomment it and use it for seprate dashboard permission (if needed)

//         let dashboardDataSource = [
           
// ,
//             {
//                 key: 'dash-2',
//                 title: 'School Dashboard',
//                 moduleCode: "CM",
//                 view: false,
//                 create: false,
//                 update: false,
//                 delete: false,
//                 fullControl: false,
//             }
//         ]

        this.state = {
            notifyUser: true,
            purchaseDataSource,
            salesDataSource,
            inventoryDataSource,
            financeDataSource,
            adminDataSource,
            integrationDataSource,
            schoolDataSource,
            crmDataSource,
            hrmsDataSource,
            eStoreDataSource,tabsToshow,communicationDataSource
  
        }

        if (props.userData) {

            const currentUserPermission = find(props.userData.boUserPermissionList, { clientUserAccountId: props.userData.userId });
            const { purchaseDataSource, salesDataSource, inventoryDataSource, financeDataSource, adminDataSource, 
                integrationDataSource, schoolDataSource, eStoreDataSource, crmDataSource, hrmsDataSource, tabsToshow,permissions,communicationDataSource} = this.state;
            // purchaseDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // salesDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // inventoryDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // financeDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // adminDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // integrationDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // schoolDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // eStoreDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });

            // alifAppDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });
            // alifAppAdministrationDataSource.forEach((d) => {
            //     d.view = false;
            //     d.create = false;
            //     d.update = false;
            //     d.delete = false;
            //     d.fullControl = false;
            // });


//             currentUserPermission.boUserPrimaryPermission.forEach((permission) => {
//                 const supplierCode = find(purchaseDataSource, { moduleCode: permission.moduleCode });
//                 const customerCode = find(salesDataSource, { moduleCode: permission.moduleCode });
//                 const inventoryCode = find(inventoryDataSource, { moduleCode: permission.moduleCode });
//                 const financeCode = find(financeDataSource, { moduleCode: permission.moduleCode });
//                 const adminCode = find(adminDataSource, { moduleCode: permission.moduleCode });
//                 const integrationCode = find(integrationDataSource, { moduleCode: permission.moduleCode });
//                 const schoolCode = find(schoolDataSource, { moduleCode: permission.moduleCode });
//                 const crmCode = find(crmDataSource, { moduleCode: permission.moduleCode });
//                 const hrmsCode = find(hrmsDataSource, { moduleCode: permission.moduleCode });
//                 const eStoreCode = find(eStoreDataSource, { moduleCode: permission.moduleCode });

// //        code commented if needed in future uncomment it and use it for seprate dashboard permission (if needed)
//                 // const dashboardCode = find(dashboardDataSource, { moduleCode: permission.moduleCode });

//                 // const alifAppCode = find(alifAppDataSource, { moduleCode: permission.moduleCode });
//                 // const alifAppAdministrationCode = find(alifAppAdministrationDataSource, { moduleCode: permission.moduleCode });

//                 if (supplierCode) {
//                     supplierCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     supplierCode.view = (permission.permisssionValue & 1) ? true : false;
//                     supplierCode.create = (permission.permisssionValue & 2) ? true : false;
//                     supplierCode.update = (permission.permisssionValue & 4) ? true : false;
//                     supplierCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     supplierCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     supplierCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     supplierCode.version = permission.version
//                 }
//                 if (customerCode) {
//                     customerCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     customerCode.view = (permission.permisssionValue & 1) ? true : false;
//                     customerCode.create = (permission.permisssionValue & 2) ? true : false;
//                     customerCode.update = (permission.permisssionValue & 4) ? true : false;
//                     customerCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     customerCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     customerCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     customerCode.version = permission.version
//                 } 
//                 if (inventoryCode) {
//                     inventoryCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     inventoryCode.view = (permission.permisssionValue & 1) ? true : false;
//                     inventoryCode.create = (permission.permisssionValue & 2) ? true : false;
//                     inventoryCode.update = (permission.permisssionValue & 4) ? true : false;
//                     inventoryCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     inventoryCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     inventoryCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     inventoryCode.version = permission.version
//                 }
//                 if (financeCode) {
//                     financeCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     financeCode.view = (permission.permisssionValue & 1) ? true : false;
//                     financeCode.create = (permission.permisssionValue & 2) ? true : false;
//                     financeCode.update = (permission.permisssionValue & 4) ? true : false;
//                     financeCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     financeCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     financeCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     financeCode.version = permission.version
//                 }
//                 if (adminCode) {
//                     adminCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     adminCode.view = (permission.permisssionValue & 1) ? true : false;
//                     adminCode.create = (permission.permisssionValue & 2) ? true : false;
//                     adminCode.update = (permission.permisssionValue & 4) ? true : false;
//                     adminCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     adminCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     adminCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     adminCode.version = permission.version
//                 }
//                 if (integrationCode) {
//                     integrationCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     integrationCode.view = (permission.permisssionValue & 1) ? true : false;
//                     integrationCode.create = (permission.permisssionValue & 2) ? true : false;
//                     integrationCode.update = (permission.permisssionValue & 4) ? true : false;
//                     integrationCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     integrationCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     integrationCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     integrationCode.version = permission.version
//                 }
//                 if (schoolCode) {
//                     schoolCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     schoolCode.view = (permission.permisssionValue & 1) ? true : false;
//                     schoolCode.create = (permission.permisssionValue & 2) ? true : false;
//                     schoolCode.update = (permission.permisssionValue & 4) ? true : false;
//                     schoolCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     schoolCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     schoolCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     schoolCode.version = permission.version
//                 }
//                 if (crmCode) {
//                     crmCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     crmCode.view = (permission.permisssionValue & 1) ? true : false;
//                     crmCode.create = (permission.permisssionValue & 2) ? true : false;
//                     crmCode.update = (permission.permisssionValue & 4) ? true : false;
//                     crmCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     crmCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     crmCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     crmCode.version = permission.version
//                 }
//                 if (hrmsCode) {
//                     hrmsCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     hrmsCode.view = (permission.permisssionValue & 1) ? true : false;
//                     hrmsCode.create = (permission.permisssionValue & 2) ? true : false;
//                     hrmsCode.update = (permission.permisssionValue & 4) ? true : false;
//                     hrmsCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     hrmsCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     hrmsCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     hrmsCode.version = permission.version
//                 }
//                 if (eStoreCode) {
//                     eStoreCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                     eStoreCode.view = (permission.permisssionValue & 1) ? true : false;
//                     eStoreCode.create = (permission.permisssionValue & 2) ? true : false;
//                     eStoreCode.update = (permission.permisssionValue & 4) ? true : false;
//                     eStoreCode.delete = (permission.permisssionValue & 8) ? true : false;
//                     eStoreCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                     eStoreCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                     eStoreCode.version = permission.version
//                 }
// //       code commented if needed in future uncomment it and use it for seprate dashboard permission (if needed)
//                 // if (dashboardCode) {
//                 //     dashboardCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                 //     dashboardCode.view = (permission.permisssionValue & 1) ? true : false;
//                 //     dashboardCode.create = (permission.permisssionValue & 2) ? true : false;
//                 //     dashboardCode.update = (permission.permisssionValue & 4) ? true : false;
//                 //     dashboardCode.delete = (permission.permisssionValue & 8) ? true : false;
//                 //     dashboardCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                 //     dashboardCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                 //     dashboardCode.version = permission.version
//                 // }

//                 // if (alifAppCode) {
//                 //     alifAppCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                 //     alifAppCode.view = (permission.permisssionValue & 1) ? true : false;
//                 //     alifAppCode.create = (permission.permisssionValue & 2) ? true : false;
//                 //     alifAppCode.update = (permission.permisssionValue & 4) ? true : false;
//                 //     alifAppCode.delete = (permission.permisssionValue & 8) ? true : false;
//                 //     alifAppCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                 //     alifAppCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                 //     alifAppCode.version = permission.version
//                 // }
//                 // if (alifAppAdministrationCode) {
//                 //     alifAppAdministrationCode.userPrimaryPermissionId = permission.userPrimaryPermissionId;
//                 //     alifAppAdministrationCode.view = (permission.permisssionValue & 1) ? true : false;
//                 //     alifAppAdministrationCode.create = (permission.permisssionValue & 2) ? true : false;
//                 //     alifAppAdministrationCode.update = (permission.permisssionValue & 4) ? true : false;
//                 //     alifAppAdministrationCode.delete = (permission.permisssionValue & 8) ? true : false;
//                 //     alifAppAdministrationCode.fullControl = (permission.permisssionValue & 15) ? true : false;
//                 //     alifAppAdministrationCode.sendEmail = (permission.permisssionValue & 16) ? true : false;
//                 //     alifAppAdministrationCode.version = permission.version
//                 // }
//             });

// Helper function to assign permissions
                    const assignPermissions = (code, permission) => {
                        if (code) {
                            code.userPrimaryPermissionId = permission.userPrimaryPermissionId;
                            code.view = (permission.permisssionValue & 1) ? true : false;
                            code.create = (permission.permisssionValue & 2) ? true : false;
                            code.update = (permission.permisssionValue & 4) ? true : false;
                            code.delete = (permission.permisssionValue & 8) ? true : false;
                            code.fullControl = (permission.permisssionValue & 15) ? true : false;
                            code.sendEmail = (permission.permisssionValue & 16) ? true : false;
                            code.version = permission.version;
                        }
                    };

                currentUserPermission.boUserPrimaryPermission.forEach((permission) => {
                    const supplierCode = find(purchaseDataSource, { moduleCode: permission.moduleCode });
                    const customerCode = find(salesDataSource, { moduleCode: permission.moduleCode });
                    const inventoryCode = find(inventoryDataSource, { moduleCode: permission.moduleCode });
                    const financeCode = find(financeDataSource, { moduleCode: permission.moduleCode });
                    const adminCode = find(adminDataSource, { moduleCode: permission.moduleCode });
                    const integrationCode = find(integrationDataSource, { moduleCode: permission.moduleCode });
                    const schoolCode = find(schoolDataSource, { moduleCode: permission.moduleCode });
                    const crmCode = find(crmDataSource, { moduleCode: permission.moduleCode });
                    const hrmsCode = find(hrmsDataSource, { moduleCode: permission.moduleCode });
                    const eStoreCode = find(eStoreDataSource, { moduleCode: permission.moduleCode });
                    const communicationCode = find(communicationDataSource, { moduleCode: permission.moduleCode });

                    // Assign permissions using the helper function
                    assignPermissions(supplierCode, permission);
                    assignPermissions(customerCode, permission);
                    assignPermissions(inventoryCode, permission);
                    assignPermissions(financeCode, permission);
                    assignPermissions(adminCode, permission);
                    assignPermissions(integrationCode, permission);
                    assignPermissions(schoolCode, permission);
                    assignPermissions(crmCode, permission);
                    assignPermissions(hrmsCode, permission);
                    assignPermissions(eStoreCode, permission);
                    assignPermissions(communicationCode, permission);


                    // Code commented if needed in future uncomment it and use it for separate dashboard permission (if needed)
                    // const dashboardCode = find(dashboardDataSource, { moduleCode: permission.moduleCode });


                    // assignPermissions(dashboardCode, permission);


                    // const alifAppCode = find(alifAppDataSource, { moduleCode: permission.moduleCode });
                    // const alifAppAdministrationCode = find(alifAppAdministrationDataSource, { moduleCode: permission.moduleCode });
                    // assignPermissions(alifAppCode, permission);
                    // assignPermissions(alifAppAdministrationCode, permission);
                });

    //    checking for the available record to set the user view what they can see what they cant see 
                    function hasPermission(dataSource,permision) {
                        return dataSource.some(item => 
                            permision[item.moduleCode]
                        );
                    }
                    
                    tabsToshow.sales = hasPermission(salesDataSource,props.permissions?.primary);
                    tabsToshow.purchase = hasPermission(purchaseDataSource,props.permissions?.primary);
                    tabsToshow.inventory = hasPermission(inventoryDataSource,props.permissions?.primary);
                    tabsToshow.finance = hasPermission(financeDataSource,props.permissions?.primary);
                    tabsToshow.administraction = hasPermission(adminDataSource,props.permissions?.primary);
                    tabsToshow.integration = hasPermission(integrationDataSource,props.permissions?.primary);
                    tabsToshow.school = hasPermission(schoolDataSource,props.permissions?.primary);
                    tabsToshow.crm = hasPermission(crmDataSource,props.permissions?.primary);
                    tabsToshow.hrms = hasPermission(hrmsDataSource,props.permissions?.primary);
                    tabsToshow.eStore = hasPermission(eStoreDataSource,props.permissions?.primary);
                    tabsToshow.communication = hasPermission(communicationDataSource,props.permissions?.primary);

                    
                    console.log("Permission check result:", tabsToshow);
//  -------------------------------  ends  here

            this.state = {
                isUserUpdate: true,
                notifyUser: props.userData.notifyUser ? true : false,
                relationshipEmployeeId: props.userData.relationshipEmployeeId,
                password: props.userData.password,
                repeatPassword: props.userData.password,
                email: props.userData.email,
                selectedEmployee: {
                    firstName: props.userData.firstName,
                    lastName: props.userData.lastName,
                    emailAddress: props.userData.email,
                    middleName: props.userData.middleName
                },
                userData: props.userData,
                salesDataSource,
                purchaseDataSource,
                inventoryDataSource,
                financeDataSource,
                adminDataSource,
                integrationDataSource,
                schoolDataSource,
                crmDataSource,
                hrmsDataSource,
                eStoreDataSource,
                communicationDataSource,
                tabsToshow,
                // dashboardDataSource,

                // isSalesVisible: salesDataSource.some(item => item.view),
                // isPurchaseVisible: purchaseDataSource.some(item => item.view),
                // isInventoryVisible: inventoryDataSource.some(item => item.view),
                // isFianceVisible: financeDataSource.some(item => item.view),
                // isIntegrationVisible: integrationDataSource.some(item => item.view),
                // isSchoolVisible: schoolDataSource.some(item => item.view),
                // isCrmVisible: crmDataSource.some(item => item.view),
                // isHrmsVisible: hrmsDataSource.some(item => item.view),
                // isEstoreVisible: eStoreDataSource.some(item => item.view),

                // alifAppDataSource,
                // alifAppAdministrationDataSource,
                
                version: currentUserPermission.version,
                userPermissionId: currentUserPermission.userPermissionId
            }
        }
    }

    componentDidMount() {

        const userInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 200,
        };
        this.props.fetchSalutation(payload);
        this.props.fetchAllEmployees(payload);
        // this.props.getAllStudentForm(payload);
    }


    render() {
        return (
            <>
                  {this.state.employeeDrawer && <EmployeeDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}

             <ManageUserComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} updateDrawer />
            </>
           
        )
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        salutations: state.common.salutations,
        allEmployee: state.settings.userForAlpideAccess,
        permissions: state.auth.permissions,

        // isAlifApp: state.common.isAlifApp,
        // studentFormList: state.membershipReducer.studentFormList,
        permissions: state.auth.permissions,
        listLoading: state.common.listLoading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalutation,
    createOrUpdteUser,
    fetchAllEmployees,
    deleteEmployee,
    showModal,
    hideModal,
    getAllStudentForm
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);