import React, { useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { Skeleton, Menu, Pagination, Empty } from "antd";
import PageBreadcrumb from "../../../PageBreadcrumb";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import { PictureOutlined,PlusOutlined } from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined } from "@ant-design/icons";
import "./index.scss";
import {
  checkACLPermission,
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  getPermissionData,
} from "../../../../utils";
import {
  CONSTANTS,
  ICONS,
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../static/constants";
import PageHeader from "../../../Common/pageHeader";
// import PriceListIcon from './../../../../assets/images/price-list.svg';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const PriceListListingComp = (props) => {
  const { permissions, priceList, companyInfo, showModal, isUpdate,updateState } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PRICE_LIST);
  },[])
  // const getRowHeight = (params) => {
  //     let height = 36;
  //     return height;
  // }

  // const updatePriceList = (payload) => {
  //     const modalBody =
  //         <FormattedMessage
  //             id='updatePriceList.confirmation'
  //             defaultMessage=''
  //         />;
  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => { props.createPriceList(payload) },
  //     };
  //     showModal(modalData);
  // }
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const newPriceListAddition = (e) => {
    const formData = e || {};
    formData.relationshipId = companyInfo.relationshipId;
    const payload = { formData: formData };
    const data = {
      title: !e.priceListId ? (
        <FormattedMessage id="addItem.text.createPriceList" defaultMessage="" />
      ) : (
        "Edit Price List"
      ),

      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PRICE_LIST,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };



 
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      
    };
    fetchPaginationDataIfNeeded("fetchPriceList", "priceList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
         };
    props.resetPaginatedData();
    props.fetchProducts(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };
  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='inventory.priceList.listing.header.name' defaultMessage='' />;
  //         },
  //         field: "priceListName",
  //         rowDrag: true,
  //         resizable: true,
  //         editable: true,
  //         cellEditor: 'customTextEditor',
  //         cellClass: 'cellInputControl'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='inventory.priceList.listing.header.description' defaultMessage='' />;
  //         },
  //         field: "description",
  //         resizable: true,
  //         editable: true,
  //         cellEditor: 'customTextEditor',
  //         cellClass: 'cellInputControl',
  //         cellRendererFramework: (params) => <div dangerouslySetInnerHTML={{
  //             __html: params.value || '-'
  //         }}>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='inventory.priceList.listing.header.percentage' defaultMessage='' />;
  //         },
  //         field: "percentage",
  //         resizable: true,
  //         editable: true,
  //         cellEditor: 'customNumberEditor',
  //         cellClass: 'cellInputControl'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='inventory.priceList.listing.header.type' defaultMessage='' />;
  //         },
  //         field: "type",
  //         resizable: true,
  //         cellRendererFramework: (params) =>
  //             <div className="priceList-dropdown">
  //                 {params.node.data.isToEdit ? <div>
  //                     <Dropdown
  //                         style={{ 'width': '100%' }}
  //                         items={['Markup', 'Markdown']}
  //                         value={params.data.type || 'Markup'}
  //                         placeholder={'Select Price List'}
  //                         onSelect={(selectedValue) => {
  //                             params.api.getDisplayedRowAtIndex(params.node.rowIndex).data.type = selectedValue;
  //                             params.api.refreshCells({ force: true });
  //                         }}
  //                     />
  //                 </div> : <div>
  //                     {params.data.type === 'Markup' ?
  //                         <div> {params.data.type} (<i className='fa fa-long-arrow-up markup-arrow' />)</div>
  //                         :
  //                         <div>
  //                             <div> {params.data.type} (<i className='fa fa-long-arrow-down markup-arrow' />)</div>
  //                         </div>
  //                     }
  //                 </div>}
  //             </div>
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='modal.common.priceList.startDate' defaultMessage='' />;
  //         },
  //         resizable: true,
  //         cellRendererFramework: (params) =>
  //             <div className="priceList-datepicker">
  //                 {params.node.data.isToEdit ?
  //                     <div>
  //                         <DatePicker
  //                         key={`${getMomentDateForUI({date: new Date(params.data.startDate ? params.data.startDate : new Date())})}`}
  //                         defaultValue={getMomentDateForUI({date: new Date(params.data.startDate ? params.data.startDate : new Date())})}

  //                             onChange={(dateObj) => {
  //                                 params.api.getDisplayedRowAtIndex(params.node.rowIndex).data.startDate = dateObj.format(CONSTANTS.BACKEND_FORMAT);
  //                                 params.api.refreshCells({ force: true });
  //                             }} />
  //                     </div>
  //                     :
  //                     <div>
  //                         {params.data.startDate ? getMomentDateForUIReadOnly({date: new Date(params.data.startDate), format}) : ''}
  //                     </div>
  //                 }
  //             </div>
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='modal.common.priceList.endDate' defaultMessage='' />;
  //         },
  //         resizable: true,
  //         cellRendererFramework: (params) =>
  //             <div>
  //                 {params.node.data.isToEdit ?
  //                     <div className="priceList-datepicker">
  //                         <DatePicker
  //                         key={`${getMomentDateForUI({date: new Date(params.data.endDate ? params.data.endDate : new Date())})}`}
  //                         defaultValue={getMomentDateForUI({date: new Date(params.data.endDate ? params.data.endDate : new Date())})}
  //                             onChange={(dateObj) => {
  //                                 params.api.getDisplayedRowAtIndex(params.node.rowIndex).data.endDate = dateObj.format(CONSTANTS.BACKEND_FORMAT);
  //                                 params.api.refreshCells({ force: true });
  //                             }} />
  //                     </div>
  //                 :
  //                     <div>
  //                         {params.data.endDate && getMomentDateForUIReadOnly({date: new Date(params.data.endDate), format})}
  //                     </div>
  //                 }
  //             </div>
  //     }
  // ];

  // if (permittedActions.update || permittedActions.delete) {
  //     columnDefs.push({
  //         // headerComponentFramework: () => {
  //         //     return <FormattedMessage id='customer.creditMemo.listing.header.Action' defaultMessage='' />;
  //         // },
  //         headerName: "",
  //         width: 110,
  //         cellClass: 'lastActionCell',
  //         cellRendererFramework: (params) =>
  //             <div>
  //                 {params.node.data.isToEdit ?
  //                     <div className="table-cell-action">
  //                         <Link onClick={() => {
  //                             updatePriceList(params.node.data);
  //                             params.api.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
  //                             params.api.refreshCells({ force: true });
  //                         }} >
  //                             <i className="fa fa-save" />
  //                         </Link>
  //                         <Link onClick={() => {
  //                             params.api.stopEditing(true);
  //                             params.api.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
  //                             params.api.refreshCells({ force: true });
  //                         }} >
  //                             <i className="fa fa-ban" />
  //                         </Link>
  //                     </div>
  //                 :
  //                     <div className="table-cell-action">
  //                         {
  //                             permittedActions.update
  //                                 ? (
  //                                     <Link onClick={() => {
  //                                         params.api.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
  //                                         params.api.refreshCells({ force: true });
  //                                     }}>
  //                                         <i className="fa fa-edit" />
  //                                     </Link>
  //                                 )
  //                                 : <Fragment />
  //                         }
  //                         {
  //                             permittedActions.delete
  //                                 ? (
  //                                     <Link onClick={() => deletePriceList(props, params.node)}>
  //                                         <i className="fa fa-remove" />
  //                                     </Link>
  //                                 )
  //                                 : <Fragment />
  //                         }

  //                     </div>
  //                 }
  //             </div>
  //     })
  // }

  // const onFirstDataRendered = (params) => {
  //     params.api.sizeColumnsToFit();
  // }

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.products" />
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.priceList" />
    },
  ];
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
{
  permissionsData?.update ?   <Menu.Item
  key="1"
  value="edit"
  onClick={() => {
    newPriceListAddition(e);
  }}
>
  <i className={ICONS.EDIT} />
  Edit
</Menu.Item>: ""
}
      

        {permissionsData.delete && (
          <Menu.Item
            key="2"
            onClick={() => {
              deletePriceList(props, e);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        )}
      </Menu>
    );
  };
  return (
    <>
      <PageHeader {...props} 
        pageName= "inventory.priceList.listing.header.priceList"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        
        onCreate={newPriceListAddition}
        moduleCode ={MODULE_CODED_VALUES.PRICE_LIST}
      />

      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="inventory.priceList.listing.header.priceList.table.heading"
                defaultMessage=""
              />
            </div>
            {props.priceList.length ? (
              <>
                <div className="vertical-sep" />
                <div>{props.priceList.length}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions"></div>
        </div>
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.priceList.listing.header.name"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.priceList.listing.header.description"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.priceList.listing.header.percentage"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.priceList.listing.header.type"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.common.priceList.startDate"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.common.priceList.endDate"
                      defaultMessage=""
                    />
                  </th>

                  {
                    (permissionsData?.delete || permissionsData?.update ) ?  <th>
                    <FormattedMessage
                      id="common.listing.header.action"
                      defaultMessage=""
                    />
                  </th>: ""
                  }
                 
                </tr>
              </thead>
              <tbody>
                {(priceList || []).length ?
                  priceList.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{rowData.priceListName}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: rowData.description || "-",
                            }}
                          ></div>
                        </td>
                        <td>{rowData.percentage}</td>
                        <td>
                          <div>
                            {rowData.type === "Markup" ? (
                              <div>
                                {" "}
                                {rowData.type} (
                                <i className="fa fa-long-arrow-up markup-arrow" />
                                )
                              </div>
                            ) : (
                              <div>
                                <div>
                                  {" "}
                                  {rowData.type} (
                                  <i className="fa fa-long-arrow-down markup-arrow" />
                                  )
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            {rowData.startDate
                              ? getMomentDateForUIReadOnly({
                                  date: new Date(rowData.startDate),
                                  format,
                                })
                              : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {rowData.endDate &&
                              getMomentDateForUIReadOnly({
                                date: new Date(rowData.endDate),
                                format,
                              })}
                          </div>
                        </td>
                        {
                          (permissionsData?.delete || permissionsData?.update ) ? <td style={{ paddingLeft: "50px" }}>
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </td>: ""
                        }
                        
                      </tr>
                    );
                  })
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          {/* <div className='agGridWrapper d-flex w-100 pt15'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={priceList || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                getRowHeight={getRowHeight}
                                onGridSizeChanged={onFirstDataRendered}
                                onGridReady={onFirstDataRendered}
                                editType={'fullRow'}
                            >
                            </CustomAgGrid>
                        </div>
                    </div> */}
        </Skeleton>
        <div className="footer">
            <div className="f-left"></div>

            <div className="f-right">
            <Pagination
          size="small"
          total={props.priceList.length}
          showTotal={showTotal}
          defaultPageSize={props.pageSize || 100}
          showSizeChanger
          pageSizeOptions={[10, 25, 50, 100, 200]}
          // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber, pageSize, props);
            }}
        />
            </div>
          </div>
        
      </div>
    </>
  );
};

const deletePriceList = (props, data) => {
  const modalData = {
    modalBody: <FormattedMessage id="deletePriceList" defaultMessage="" />,
    handleSubmit: () => {
      props.deletePriceList({
        id: data.priceListId,
        relationshipId: data.relationshipId,
      });
      props.hideModal();
    },
  };
  props.showModal(modalData);
};

export default injectIntl(PriceListListingComp);
