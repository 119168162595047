import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { AgGridReact } from 'ag-grid-react';
import './index.scss'
import { Skeleton, Pagination, Checkbox, Menu } from 'antd';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, showToasterMessage } from '../../../utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';
import * as find from 'lodash.find';
import {
    DownOutlined,
  } from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import { ICONS } from '../../../static/constants';
import onSale from './onSale';
import CategoryDetailsModel from '../../../containers/modal/modalBody/product/CategoryDetailsModel';

const AllStockComp = (props) => {
    const { products, updateState, companyInfo, productCount, pageNumber, selectedItems = [] } = props;

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchProducts', 'products', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
       
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchProducts', 'products', props, payload);
        // props.resetPaginatedData();
        // props.fetchProducts(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const itemSelection = (item) => {
        let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(obj => obj.itemId !== item.itemId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    }

    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < products[pageNumber].length; i++) {
            let item = products[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
    }

    const isCheckedAll = () => {
        if (!products || !products[pageNumber] || products[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < products[pageNumber].length; i++) {
            let usr = products[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(usr.itemId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

   
    
    const checkMenu = (e) => {
        
        return (
          <Menu className="row-action-dropdown">
            <Menu.Item key="0" value="title">
              Actions
            </Menu.Item>
    
            <Menu.Divider />
    
            {/* <Menu.Item
              key="1"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });

                if (selectedItems.length > 0) {
                    const addedItems = (selectedItems||[]).filter( item => item?.isPublisedItem)
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                (addedItems || []).map((item)=>{
                                   return  showToasterMessage({
                                       description: `${item?.itemName} is already added`,
                                       messageType: "error",
                                     });
                                })
                               //  selectAll(false);
                       }
                       else{
                        props.updatePublisedItem({
                            relationshipId: props.companyInfo.relationshipId,
                            itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                            isPublisedItem: 1,
                            pageNumber: props.pageNumber,
                            pageSize: props.pageSize
                        }, props)
                        selectAll(false);
                       }
                           
                            props.hideModal();
                           
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.SELECT_ALL} />  <FormattedMessage id='publish.text' />
            </Menu.Item>
    
            <Menu.Item
              key="2"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });
                if (selectedItems.length > 0) {
                    const addedItems = (selectedItems||[]).filter( item => item?.isPublisedItem === 0)
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                (addedItems || []).map((item)=>{
                                   return  showToasterMessage({
                                       description: `${item?.itemName} is already added`,
                                       messageType: "error",
                                     });
                                })
                               //  selectAll(false);
                       }
                       else{
                        props.updatePublisedItem({
                            relationshipId: props.companyInfo.relationshipId,
                            itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                            isPublisedItem: 0,
                            pageNumber: props.pageNumber,
                            pageSize: props.pageSize
                        }, props)
                        selectAll(false);
                       }
                           
                            props.hideModal();
                           
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.UNSELECT_ALL} />   <FormattedMessage id='unpublish.text' />
            </Menu.Item> */}
            <Menu.Item
              key="3"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });
                const addedItems = (selectedItems||[]).filter( item => item?.isClearanceItem)
                if (selectedItems.length > 0) {
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                (addedItems || []).map((item)=>{
                                   return  showToasterMessage({
                                       description: `${item?.itemName} is already added`,
                                       messageType: "error",
                                     });
                                })
                               //  selectAll(false);
                       }
                       else{
                        props.updateClearanceItem({
                            relationshipId: props.companyInfo.relationshipId,
                            itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                            isClearanceItem: 1,
                            pageNumber: props.pageNumber,
                            pageSize: props.pageSize
                        }, props)
                        selectAll(false);
                       }
                           
                            props.hideModal();
                           
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.ADD_ICON} />  <FormattedMessage id='clearance.text' />
            </Menu.Item>
    
            <Menu.Item
              key="4"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });
                const addedItems = (selectedItems||[]).filter( item => item?.isDealOfTheDayItem)
                if (selectedItems.length > 0) {
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                (addedItems || []).map((item)=>{
                                   return  showToasterMessage({
                                       description: `${item?.itemName} is already added`,
                                       messageType: "error",
                                     });
                                })
                               //  selectAll(false);
                       }
                       else{
                        props.updateDealOfTheDayItem({
                            relationshipId: props.companyInfo.relationshipId,
                            itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                            isDealOfTheDayItem: 1,
                            pageNumber: props.pageNumber,
                            pageSize: props.pageSize
                        }, props)
                        selectAll(false);
                       }
                            
                            props.hideModal();
                            
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.ADD_ICON} />  <FormattedMessage id='dealOfTheDay.text' />
            </Menu.Item>
            <Menu.Item
              key="5"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });
                if (selectedItems.length > 0) {
                   
                    const addedItems = (selectedItems||[]).filter( item => item?.isNewArrivalItem)
                    
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                     (addedItems || []).map((item)=>{
                                        return  showToasterMessage({
                                            description: `${item?.itemName} is already added`,
                                            messageType: "error",
                                          });
                                     })
                                    //  selectAll(false);
                            }
                            
                            else{
                                props.updateNewArrivalItem({
                                    relationshipId: props.companyInfo.relationshipId,
                                    itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                                    isNewArrivalItem: 1,
                                    pageNumber: props.pageNumber,
                                    pageSize: props.pageSize
                                }, props)
                                selectAll(false);
                            }
                           
                            props.hideModal();
                            
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.ADD_ICON} /><FormattedMessage id='newArrival.text' />
            </Menu.Item>
    
            <Menu.Item
              key="6"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });
                if (selectedItems.length > 0) {
                    const addedItems = (selectedItems||[]).filter( item => item?.isBestSellingItem)
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                (addedItems || []).map((item)=>{
                                   return  showToasterMessage({
                                       description: `${item?.itemName} is already added`,
                                       messageType: "error",
                                     });
                                })
                               //  selectAll(false);
                       }
                       else{
                        props.updateBestSellingItem({
                            relationshipId: props.companyInfo.relationshipId,
                            itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                            isBestSellingItem: 1,
                            pageNumber: props.pageNumber,
                            pageSize: props.pageSize
                        }, props)
                        selectAll(false);
                       }
                            
                            props.hideModal();
                           
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.ADD_ICON} />  <FormattedMessage id='bestSelling.text' />
            </Menu.Item>
            <Menu.Item
              key="7"
              onClick={() => {
               
                if ((selectedItems||[]).length > 0) {
                    const addedItems = (selectedItems||[]).filter( item => item?.onSale)
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            if(addedItems?.length > 0){
                                (addedItems || []).map((item)=>{
                                   return  showToasterMessage({
                                       description: `${item?.itemName} is already added`,
                                       messageType: "error",
                                     });
                                })
                               //  selectAll(false);
                       }
                       else{
                        props.updateOnSaleItem({
                            relationshipId: props.companyInfo.relationshipId,
                            itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                            onSale: 1,
                            pageNumber: props.pageNumber,
                            pageSize: props.pageSize
                        }, props)
                        selectAll(false);
                       }
                           
                            props.hideModal();
                           
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.ADD_ICON} />  <FormattedMessage id='onSale.text' />
            </Menu.Item>
           
          </Menu>
        );
      };
      const openClassDetailPopup = (item) => {
        const modalData = {
          title: <FormattedMessage id='inventory.invValuation.listing.model.title' defaultMessage='' />,
          modalBody: <CategoryDetailsModel {...props} CategoryObj={item} />,
          width: '50%',
          hideFooter: true,
        };
        props.pushModalToStack(modalData);
      }
    return (
        <Fragment>
            <div className="view-container-actions">
                <div className="left-actions">
                    <div className="table-heading">All Stock List</div>
                   
                           
                                <div className="vertical-sep" />
                                <div>{productCount}</div>
                            
                        
                </div>

              
            </div>
            <Skeleton loading={props.isLoading}
                active
                paragraph={{ rows: 12 }}
            >


               





              
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div className="check-cell">
                                    <Checkbox
                                        onChange={(e) => {
                                            selectAll(e.target.checked);
                                        }}
                                        checked={isCheckedAll()}
                                    />
                                    {
                                        (props.permissionData.update || props.permissionData?.create )? <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                                        <DownOutlined />
                                    </DropdownAction>:""
                                    }
                                        
                                    </div>
                                    


                                </th>
                                <th className='skuStockCol'><FormattedMessage id='drawer.inventory.sku' defaultMessage='' /></th>
                                <th className='productStockCol'><FormattedMessage id='modal.txDetails.common.productName' defaultMessage=''/></th>
                              
                                  <th><FormattedMessage id='drawer.inventory.category' defaultMessage='' /></th>
                                <th><FormattedMessage id='drawer.inventory.manufacturer' defaultMessage='' /></th>
                                <th><FormattedMessage id='drawer.inventory.brand' defaultMessage='' /></th>
                                <th><FormattedMessage id='drawer.inventory.warehouse' defaultMessage='' /></th>
                                <th><FormattedMessage id='b2bPrice.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='published.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='unpublish.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='clearance.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='dealOfTheDay.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='newArrival.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='bestSelling.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='onSale.text' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {products && products[pageNumber] ?
                                (products[pageNumber] || []).map((item, i) => {
                                    return (<tr key={i}>
                                        <td>
                                            <Checkbox
                                                onClick={() => {
                                                    itemSelection(item);
                                                }}
                                                checked={find(selectedItems, { itemId: Number(item.itemId) }) ? true : false}
                                            />
                                        </td>
                                        <td>{item.sku||<div className="empty-data"></div>}</td>
                                        <td> <div>{item.itemName||<div className="empty-data"></div>}</div> </td>
                                        <td className='text-center'>{item.categoryCount ? <span onClick={() => { openClassDetailPopup(item) }} className="cursor-pointer">{item.categoryCount}</span> : '0'}</td>
                                        <td>{item.manufacturerName||<div className="empty-data"></div>}</td>
                                        <td>{item.brandName||<div className="empty-data"></div>}</td>
                                        <td>{item.warehouseName||<div className="empty-data"></div>}</td>
                                        <td className='text-end'>{fixedDecimalNumber(item.retailPrice)}</td>
                                        <td className='text-center'>
                                            <div>
                                                {item.isPublisedItem ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                {item.isUnPublisedItem ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                {item.isClearanceItem ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                {item.isDealOfTheDayItem ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                {item.isNewArrivalItem ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>

                                        <td>
                                            <div>
                                                {item.isBestSellingItem ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {item.onSale ?
                                                    // <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />
                                                    "Yes" : "No"
                                                    // : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />
                                                }
                                            </div>
                                        </td>

                                    </tr>)
                                })

                                : 'No Data'}
                        </tbody>
                    </table>
                </div>

            </Skeleton>
            <Pagination
                size="small"
                total={productCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize} 
                showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                 
                    updateState({ pageSize: pageSize, pageNumber: pageNumber })
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber||1, pageSize, props)
                    updateState({ pageSize: pageSize, pageNumber: pageNumber })
                }}
            />

        </Fragment>
    );
};

export default injectIntl(AllStockComp);
