import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StockTransitComp from "../../../components/inventory/StockTransit";
import {
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
} from "../../../actions/commonActions";
import StockTransferFiltersComp from "../../../components/inventory/StockTransit/StockTransferFilter";
import { fetchstockTransfer, getStockTransferCount, ApproveStockTransfer, DeleteStockTransfer } from "./action";
import { fetchWarehouseNames } from "../../drawer/inventory/action";

class StockTransit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};

    const payload = {
      customerId: this.props.customerId || linkProps.customerId,
      soMasterId: this.props.soMasterId || linkProps.soMasterId,
      shipmentMasterId:
        this.props.shipmentMasterId || linkProps.shipmentMasterId,
      soMasterIdArr: this.props.soMasterIdArr || linkProps.soMasterIdArr,
      invoiceMasterId: this.props.invoiceMasterId || linkProps.invoiceMasterId,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      isClone: linkProps.clone,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
    };

    this.props.fetchstockTransfer(payload);
    this.props.getStockTransferCount(payload);
    this.props.fetchWarehouseNames(payload);
  }

  render() {
    return (
      <>
        {this.state.StockTransferFilterDrawerVisible && (
          <StockTransferFiltersComp
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}

        <StockTransitComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    stockTransferList: state.inventory.stockTransfer,
    allWarehouses: state.inventory.allWarehouses,
    stockTransferCount: state.inventory.stockTransferCount,
    listLoading: state.common.listLoading,
    userInfo: state.common.userInfo,
    permissions: state.auth.permissions,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      popModalFromStack,
      pushModalToStack,
      hideModal,
      fetchstockTransfer,
      fetchWarehouseNames,
      getStockTransferCount,
      ApproveStockTransfer,
      DeleteStockTransfer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StockTransit);
