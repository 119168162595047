import React,{useMemo} from "react";
import { Skeleton, Menu } from 'antd';

import { CONSTANTS, ICONS, MODAL_TYPE, MODULE_CODED_VALUES } from '../../../static/constants';
import { injectIntl, FormattedMessage} from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
// import { CustomAgGrid } from "../../general/CustomAgGrid";
import { getMomentDateForUIReadOnly,getPermissionData } from "../../../utils";
// import { TextBox } from "../../general/TextBox";
import checkIcon from '../../../assets/images/check.png';
import crossIcon from '../../../assets/images/cross.png';
import DropdownAction from "antd/lib/dropdown";
import {  sortTable } from '../../../utils';
import { MoreOutlined } from '@ant-design/icons';
import PageHeader from "../../Common/pageHeader";


// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }


const FinYearComp = (props) => {
    const { showModal, companyInfo, updateState, dir=0,permissions } = props;
    const allFinancialYears = props.financialYearList;
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionsData = useMemo(()=>{
      return getPermissionData(primaryPerm, MODULE_CODED_VALUES.FINANCIAL_YEARS )
    },[])
    // formData.relationshipId = companyInfo.relationshipId;
    const createOrUpdateFY = (finYearData) => {
        const formData = finYearData || {};
        const payload = { formData : formData};
        const data = {
            title: "Financial Year",
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.FINANCIAL_YEAR,
                data: payload,
            },

        };
        showModal(data);
    }
    const deleteFinancialYear = (payload) => {
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.fyName }}
            />;
        const modalData = {
            modalBody,
            handleSubmit: () => { props.deleteFY({ financialYearId: payload.financialYearId, relationshipId: companyInfo.relationshipId }, props) },
        };
        showModal(modalData);
    }


    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.name',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Name</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "fyName",
    //         cellRendererFramework: (link) => <div>
    //             <span>
    //                 {link.data.fyName}
    //             </span>
    //         </div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.fyEndDate',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Start Date</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "fyStartDate",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? getMomentDateForUIReadOnly({date: data.value, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.fyEndDate',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>End Date</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "fyEndDate",
    //         sortable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? getMomentDateForUIReadOnly({date: data.value, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.currentFy',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Current FY</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "isCurrentFY",
    //         cellRendererFramework: (link) => 
    //         { return <div>
    //             { link.data.isCurrentFY ? <img src={checkIcon} style={{ 'height': '20px', 'width': '20px' }} alt="Is Current FY ?" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Is Current FY ?" /> }
    //         </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'common.actions',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Actions</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "actions",
    //         resizable: true,
    //         cellRendererFramework: (param) => {
    //             return <div>
    //                     <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => createOrUpdateFY(param.node.data)}>
    //                         <i className='fa fa-pencil'  title={ props.intl.formatMessage({ id: 'commons.listing.edit.tooltip.msg' })}/>
    //                     </span>
    //                     <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => deleteFinancialYear(param.node.data)}>
    //                         <i className="fa fa-trash" title={props.intl.formatMessage({ id: 'delete.text' })} />
    //                     </span>
    //                 </div>
    //         }
    //     }
    // ]

    const breadCrumbList = [
        {
            name: 'Finance'
        }, {
            name: 'Financial Years'
        }
    ];

    const sortColumn = (e) => {
    sortTable(document.getElementById('fy-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
    }

    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />
    
            {permissionsData.update && <Menu.Item key="1" value="edit" onClick={()=>{
                    createOrUpdateFY(e);
                }}
              >
                <i className={ICONS.EDIT} />
                Edit
              </Menu.Item>}
    
              {permissionsData.delete && <Menu.Item key="2" onClick={()=>
                {
                    deleteFinancialYear(e);
                }
              }>
                <i className={ICONS.DELETE} />
                Delete
              </Menu.Item>}
    
            </Menu >
        )
      };

    return (
      <>
       
        <PageHeader {...props}
          pageName="heading.finance.financialYears"
          breadCrumbList={breadCrumbList}
          canCreate={true}
          onCreate={createOrUpdateFY}
          moduleCode={MODULE_CODED_VALUES.FINANCIAL_YEARS}
        />

        <div className="view-container">
          <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="finance.fyYears.table.heading"
                    defaultMessage=""
                  />
                </div>
                {/* {pageSize ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{pageSize}</div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
              <div className="right-actions"></div>
            </div>
            <div className="table-container">
              <table id="fy-table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="serial.no" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="tooltip.name" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="finance.table.header.fyStartDate" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="finance.table.header.fyEndDate" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="tooltip.currentFy" />
                    </th>
                  {permissionsData.update || permissionsData.delete ? <th width="6%">
                      <FormattedMessage id="common.actions" />
                    </th>: "" }
                  </tr>
                </thead>
                <tbody>
                  {allFinancialYears &&
                    allFinancialYears.map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{rowData.fyName || "-"}</td>
                          <td>
                            {rowData.fyStartDate
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.fyStartDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT_FY,
                                })
                              : "-"}
                          </td>
                          <td>
                            {rowData.fyEndDate
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.fyEndDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT_FY,
                                })
                              : "-"}
                          </td>
                          <td>
                            {rowData.isCurrentFY ? (
                              <img
                                src={checkIcon}
                                style={{ height: "20px", width: "20px" }}
                                alt="Yes"
                              />
                            ) : (
                              <img
                                src={crossIcon}
                                style={{ height: "10px", width: "10px" }}
                                alt="No"
                              />
                            )}
                          </td>
                          {permissionsData.update || permissionsData.delete ? <td style={{ paddingLeft: "50px" }}>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <MoreOutlined />
                            </DropdownAction>
                          </td>:""}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right"></div>
            </div>
            {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={allFinancialYears || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridReady={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                        />
                    </div>
                </div> */}
          </Skeleton>
        </div>
      </>
    );
};

export default injectIntl(FinYearComp);