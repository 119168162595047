import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, PRODUCT_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

 export const fetchInventoryItemDetails= (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_DRAWER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getInventoryItemDetails?itemId=${payload.itemId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_DETAILS, data: res.data  });
        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });
      })
      .finally(()=>{
        // dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_DETAILS, data: {}  });
      }

      )
  }
}

export const resetProductDetail= (payload) => {
  return dispatch => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_DETAILS, data: {}  });
  }
}

export const fetchSalesTransactions= (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryTransactions/getSalesTransactionsByItemId?itemId=${payload.itemId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) -1}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS;
        }
      })
  }
}

export const fetchSalesTransactionsCount= (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryTransactions/countInventorySalesTxByItemId?itemId=${payload.itemId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS_COUNT, data: res.data  });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const fetchPurchaseTransactions= (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryTransactions/getPurchaseTransactionsByItemId?itemId=${payload.itemId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) -1}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS, data  });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS;
        }
      })
  }
}

export const fetchPurchaseTransactionsCount = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryTransactions/countInventoryPurchaseTxByItemId?itemId=${payload.itemId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS_COUNT, data: res.data  });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const resetSalesPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PRODUCT_ACTION_LIST.RESET_PRODUCT_SALES_TRANSACTIONS });
  }
}
export const resetPurchasePaginatedData = () => {
  return dispatch => {
    dispatch({ type: PRODUCT_ACTION_LIST.RESET_PRODUCT_PURCHASE_TRANSACTIONS });
  }
}

export const resetInventoryItemDetails = () => {
  return dispatch => {
    dispatch({ type: PRODUCT_ACTION_LIST.RESET_PRODUCT_DETAILS });
  }
}