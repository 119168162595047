import React, { useMemo } from "react";
import { Tabs } from "antd";
import SalesOrder from "./salesOrder";
import SalesOrderOnlineListingComp from "./salesOrdersOnline";
import SalesOrderDraft from "./salesOrderDraft";
import { FormattedMessage } from "react-intl";
import SoCreate from '../../../../containers/customer/salesOrder/StandardSalesOrder';
import PageHeader from "../../../Common/pageHeader";
import SalesOrderCancelled from "./salesOrdersCancelled";
import AmendSalesOrder from "./amendSalesOrder";
import { checkACLPermission, getPermissionData } from "../../../../utils";
import { MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../../static/constants";


const { TabPane } = Tabs;

function onTabChange(key, props) {
  const payload = {
    companyInfo: props.companyInfo,
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: props.pageNumber,
    pageSize: props.pageSize,
  };

  switch (key) {
    case "1":
      props.updateState({ activeTab: "Sales Order", activeTabKey: "1" ,pageNumber:1,pageSize:25});
      props.fetchSalesOrders({...payload,pageNumber:1,pageSize:25});
    props.resetPaginatedData();

      
      break;
    case "2":
      props.updateState({ activeTab: "Online Order", activeTabKey: "2",pageNumber:1,pageSize:25 });
      props.fetchSalesOrdersOnlineOrders({...payload,pageNumber:1,pageSize:25});
      props.getSalesOrderOnlineCount(payload);
    props.resetPaginatedData();

      break;
    case "3":
      props.updateState({ activeTab: "Cancelled Order", activeTabKey: "3",pageNumber:1,pageSize:25 });
      props.fetchCancelledSalesOrder({...payload,pageNumber:1,pageSize:25});
      props.getCancelledSalesOrderCount(payload);
      break;
    case "4":
      props.updateState({ activeTab: "Return/Replace Order", activeTabKey: "4",pageNumber:1,pageSize:25 });
      props.fetchAmendSalesOrders({...payload,pageNumber:1,pageSize:25});
      // props.fetchAmendSalesOrdersCount(payload);
      break;
    case "5":
      props.updateState({ activeTab: "Draft", activeTabKey: "5",pageNumber:1,pageSize:25 });
      props.fetchSalesOrderDrafts({...payload,pageNumber:1,pageSize:25});
      props.getSalesOrderDraftCount(payload);
      break;
    default:
      break;
  }
}

const SalesOrderListingComp = (props) => {
  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const amendSalesOrderPermissions = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.AMEND_SALES_ORDER);
  },[])
    const openNewSO = () => {
      props.pushModalToStack({
        modalBody: <SoCreate {...props} />,
        width: '100%',
        hideTitle: true,
        hideFooter: true,
        keyboard:false,
        wrapClassName: 'new-transaction-wrapper',
        onCancel: () => {
          props.showModal({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
              props.popModalFromStack();
            }
          })
        }
      })
    }

    const breadCrumbList = [
        {
          name: <FormattedMessage id='common.sales' defaultMessage='' />,
        },
        {
          name: <FormattedMessage id={props.activeTabKey === "1" ? 'salesOrder.text' : props.activeTabKey === "2" ? 'ecomSalesOrder.text' : props.activeTabKey === "3" ? 'cancelledSalesOrder.text' : 'salesOrderDraft.text'} defaultMessage='' />,
        }
    ];
   
    return (<>
            
        <PageHeader {...props}
          pageName="heading.sales.salesOrder"
          breadCrumbList={breadCrumbList}
          canCreate={true}
          onCreate={openNewSO}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER}
        />
            <div className="view-container">
              <Tabs
                activeKey={props.activeTabKey || "1"} onChange={(key) => { onTabChange(key, props); }}
                type="line"
              >
                <TabPane tab={<FormattedMessage id='salesOrder.text' />} key="1">
                  <SalesOrder {...props} />
                </TabPane>
                <TabPane tab={<FormattedMessage id='ecomSalesOrder.text' />} key="2">
                  <SalesOrderOnlineListingComp {...props} />
                </TabPane>
                <TabPane tab={<FormattedMessage id='cancelledSalesOrder.text' />} key="3">
                  <SalesOrderCancelled {...props} />
                </TabPane>
               {amendSalesOrderPermissions.create? <TabPane tab="Return/Replace" key="4">
                  <AmendSalesOrder {...props} />
                </TabPane>:""}
                <TabPane tab="Draft" key="5">
                  <SalesOrderDraft {...props} />
                </TabPane>
              </Tabs>
            </div>
        </>
    )
};

export default SalesOrderListingComp;
