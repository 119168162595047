import React from "react";
import { Steps, Button, Col, Row, Divider, Skeleton } from 'antd';
import './style.scss';
import { FormattedMessage, } from 'react-intl';
import { getMomentDateForUIReadOnly } from '../../../../../utils';
import ShippingInfo from "./ShippingInfo";
import ShippingPrices from "./ShippingPrices";
import ShippingOrderInfo from "./ShippingOrderInfo";

const GenerateShippingLabelShiprocket = (props) => {

    const { current=0, ShippingPickupInfo={} } = props;

    const Step = Steps.Step;

    const steps = [
        {
            title: "Select",
            key: 0
        },
        {
            title: <FormattedMessage id='carriarsPrice.text' />,
            key: 1
        },
        {
            title: "Pickup Information",
            key: 2
        },
        {
            title: <FormattedMessage id='downloadShippingLabel.text' />,
            key: 3
        }
    ];

    console.log(`vvv`, props);
    
    return (<>
        <ShippingInfo {...props} />

        <Divider className="mt10 mb20" />

        <Steps current={current} status={props.stepError ? 'error' : 'process'}>
            {steps.map(item => <Step key={item.key} title={item.title} />)}
        </Steps>

        <div className="mt20" />

        {props.stepError ? <div className="required">{props.stepError}</div> : ''}

        {
            current === 0 ? <ShippingOrderInfo {...props} /> : ''
        }
        
        { 
            current === 1 ? <ShippingPrices {...props} /> : '' 
        }
        {
            current === 2 ? <>
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                    {ShippingPickupInfo ? 
                        <Row>
                            <Col span={12}>
                                Pickup schedule date
                            </Col>
                            <Col span={12}>
                                {ShippingPickupInfo?.response?.pickup_scheduled_date?  getMomentDateForUIReadOnly({date: ShippingPickupInfo?.response?.pickup_scheduled_date}) : '' }
                            </Col>

                            <Col span={12}>
                                Pickup token number
                            </Col>
                            <Col span={12}>
                                {ShippingPickupInfo?.response?.pickup_token_number || '-'}
                            </Col>

                            <Col span={12}>
                                Pickup generated date
                            </Col>
                            <Col span={12}>
                                {ShippingPickupInfo?.response?.pickup_generated_date?.date ? getMomentDateForUIReadOnly({date: ShippingPickupInfo?.response?.pickup_generated_date?.date}) : '' }
                            </Col>

                            <Col span={12}>
                                Additinal Information
                            </Col>
                            <Col span={12}>
                                {ShippingPickupInfo?.response?.data || '' }
                            </Col>
                        </Row>
                        :
                        ''
                    }
                </Skeleton>
                <div className="fot-fix" style={{ maxWidth: '100%' }}>
                    <div className="text-center">
                        <Button 
                            type="primary"
                            onClick={()=>{
                                props.updateState({current: props.current+1});
                            }}
                        > 
                            Download Shipping Label
                        </Button>
                    </div>
                </div>
            </>
            : 
            ''
        }

        {
            current === 3 ? 
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            Manifest
                        </Col>
                        <Col span={4} offset={6}>
                            {props.shippingManifestData?.status ? 
                                <Button
                                    type="primary"
                                    onClick={()=>{
                                        window.open(props.shippingManifestData?.manifest_url, '_blank').focus();
                                    }}
                                >
                                    Download Mainfest
                                </Button>
                                :
                                props.shippingManifestData?.error?.map((errMsg)=>{
                                    return <div className="required">{errMsg}</div>
                                })
                            }
                        </Col>

                        <Col span={12}>
                            Shipping Label
                        </Col>
                        <Col span={4} offset={6}>
                            {
                                props.shippingLabelData?.label_created ? 
                                    <Button
                                        type="primary"
                                        onClick={()=>{
                                            window.open(props.shippingLabelData?.label_url, '_blank').focus();
                                        }}
                                    >
                                        Download Shipping Label
                                    </Button>
                                    :
                                    <div className="required">Some error occured in generating label</div>
                            }
                        </Col>
                    </Row>
                </Skeleton>
                : 
                ''
        }
    </>
    );
}

export default GenerateShippingLabelShiprocket;