import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Checkbox, Menu } from 'antd';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, showToasterMessage } from '../../../utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import * as find from 'lodash.find';
import { ICONS } from '../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import {DownOutlined,} from "@ant-design/icons";
import CategoryDetailsModel from '../../../containers/modal/modalBody/product/CategoryDetailsModel';

const PublishedStockComp = (props) => {
   // const [isAllChecked, toggleAllChecked] = useState(false);
    const {  publishedProducts, updateState, companyInfo, publishedProductCount, pageNumber, selectedItems=[] } = props;

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchPublishedProducts', 'publishedProducts', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPublishedProductsPage();
        props.fetchPublishedProducts(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const itemSelection = (item) => {
        let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(obj => obj.itemId !== item.itemId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    }

    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < publishedProducts[pageNumber].length; i++) {
            let item = publishedProducts[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
    }

    const isCheckedAll = () => {
        if (!publishedProducts || !publishedProducts[pageNumber] || publishedProducts[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < publishedProducts[pageNumber].length; i++) {
            let usr = publishedProducts[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(usr.itemId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

    // const onGridReady = (params) => {
    //     params.api.sizeColumnsToFit();
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi,
    //     });
    // }

    // const columnDefs = [
    //     {
    //         field: 'isChecked',
    //         colId: 'isChecked_1',
    //         headerComponentFramework: () => <Fragment />,
    //         cellRendererFramework: (params) => {
    //             return <Checkbox
    //                 onChange={(e) => {
    //                     params.node.setDataValue('isChecked_1', e.target.checked);
    //                     params.node.data.isChecked = e.target.checked;
    //                 }}
    //                 checked={params.data.isChecked} />
    //         },
    //         width: 50,
    //         resizable: false
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='modal.txDetails.common.product' />;
    //         },
    //         field: "itemName",
    //         resizable: true,
    //         colId: 'itemName_1',
    //         cellRendererFramework: (link) => <div>{link.data.itemName}</div>,
    //     },
       
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='drawer.inventory.sku' defaultMessage='' />;
    //         },
    //         field: "sku",
    //         resizable: true,
    //         colId: 'sku_1',
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='b2bPrice.text' defaultMessage='' />   </div>;
    //         },
    //         field: "retailPrice",
    //         colId: 'retailPrice_1',
    //         width: 80,
    //         resizable: true,
    //     }, 
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='drawer.inventory.category' />;
    //         },
    //         field: "categoryName",
    //         resizable: true,
    //         colId: 'categoryName_1',
    //         cellRendererFramework: (link) => <div>{link.data.categoryName}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='drawer.inventory.manufacturer' defaultMessage='' />   </div>;
    //         },
    //         field: "manufacturerName",
    //         colId: 'manufacturerName_1',
    //         width: 120,
    //         resizable: true,
    //     }, 
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='drawer.inventory.brand' defaultMessage='' />   </div>;
    //         },
    //         field: "brandName",
    //         colId: 'brandName_1',
    //         width: 120,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='drawer.inventory.warehouse' defaultMessage='' />   </div>;
    //         },
    //         field: "warehouseName",
    //         colId: 'warehouseName_1',
    //         width: 80,
    //         resizable: true,
    //     }
    // ]


    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onModelUpdated = (params) => {
    //     params.api.resetRowHeights();
    // }
    const checkMenu = (e) => {
        return (
          <Menu className="row-action-dropdown">
            <Menu.Item key="0" value="title">
              Actions
            </Menu.Item>
    
            <Menu.Divider />
    
            <Menu.Item
              key="1"
              onClick={() => {
                // const itemList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         itemList.push(obj.data.itemId);
                //     }
                // });
                if (selectedItems.length > 0) {
                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                    const modalData = {
                        modalBody,
                        handleSubmit: () => {
                            props.updatePublisedItem({
                                relationshipId: props.companyInfo.relationshipId,
                                itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                                isPublisedItem: 0,
                                pageNumber: props.pageNumber,
                                pageSize: props.pageSize
                            },props)
                            props.hideModal();
                            selectAll(false);
                        },
                    };
                    props.showModal(modalData);
                } else {
                    return showToasterMessage({
                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                        messageType: 'error'
                    })
                }
            }}
            >
              <i className={ICONS.REMOVE_ICON} />  Unpublished
            </Menu.Item>
    
         
    
           
          </Menu>
        );
      };
      const openClassDetailPopup = (item) => {
        const modalData = {
          title: <FormattedMessage id='inventory.invValuation.listing.model.title' defaultMessage='' />,
          modalBody: <CategoryDetailsModel {...props} CategoryObj={item} />,
          width: '50%',
          hideFooter: true,
        };
        props.pushModalToStack(modalData);
      }
    return (
        <Fragment>
            <div className="view-container-actions">
                <div className="left-actions">
                    <div className="table-heading">Published Stock List</div>
                  
                                <div className="vertical-sep" />
                                <div>{publishedProductCount}</div>
                        
                </div>

             
            </div>
                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
              
                      
                            
                        <div className='table-container'>
                         <table >
                                <thead>
                                    <tr>
                                        <th>
                                        <div className="check-cell">
                                        <Checkbox
                                            onChange={(e) => {
                                                selectAll(e.target.checked);
                                            }}
                                            checked={isCheckedAll()}
                                        />
                                         {
                                        (props.permissionData.update || props.permissionData?.create )? <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                                        <DownOutlined />
                                    </DropdownAction>:""
                                    }
                                    </div>
                                           
                                        </th>

                                        <th className='skuCol'><FormattedMessage id='drawer.inventory.sku' defaultMessage='' /></th>
                                        <th className='productCol'><FormattedMessage id='modal.txDetails.common.productName' /></th>
                                        <th><FormattedMessage id='drawer.inventory.category' defaultMessage='' /></th>
                                        <th><FormattedMessage id='drawer.inventory.manufacturer' defaultMessage='' /></th>
                                        <th><FormattedMessage id='drawer.inventory.brand' defaultMessage='' /></th>
                                        <th><FormattedMessage id='drawer.inventory.warehouse' defaultMessage='' /></th>
                                        <th><FormattedMessage id='b2bPrice.text' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {publishedProducts && publishedProducts[pageNumber] ?
                                        (publishedProducts[pageNumber] || []).map((item, i) => {
                                            return (<tr key={i}>
                                                <td>
                                                    <Checkbox
                                                        onClick={() => {
                                                            itemSelection(item);
                                                        }}
                                                        checked={find(selectedItems, { itemId: Number(item.itemId) }) ? true : false}
                                                    />
                                                </td>
                                                <td>{item.sku}</td>
                                                <td> <div>{item.itemName}</div> </td>
                                                
                                                <td>{(item.inventoryItemCategoryRefs||[])?.length ? <span onClick={() => { openClassDetailPopup(item) }} className="cursor-pointer">{(item.inventoryItemCategoryRefs||[])?.length}</span> : '0'}</td>

                                                <td>{item.manufacturerName||<div className="empty-data"></div>}</td>
                                                <td>{item.brandName||<div className="empty-data"></div>}</td>
                                                <td>{item.warehouseName||<div className="empty-data"></div>}</td>
                                                <td>{item.retailPrice ? fixedDecimalNumber(item.retailPrice):<div className="empty-data"></div>}</td>
                                            </tr>)
                                        })

                                        : 'No Data'}
                                </tbody>
                            </table>
                            </div>
                 
                </Skeleton>
                <Pagination
                    size="small"
                    total={publishedProductCount}
                    showTotal={showTotal}
                    defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                   
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                />
       
        </Fragment>
    );
};

export default injectIntl(PublishedStockComp);
