import React, { Fragment, useMemo, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import config from "../../../../../config/environmentConfig";
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Skeleton, Tooltip, Menu, Pagination, Checkbox, Switch } from "antd";
import PageBreadcrumb from "../../../../PageBreadcrumb";
// import {  } from '@ant-design/icons';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import DropdownAction from "antd/lib/dropdown";
import {
  PictureOutlined,
  CloseCircleOutlined,
  MailOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import * as find from "lodash.find";
import ImagePlaceholderAlipde from "../../../../../assets/images/img-placeholder-alpide.jpg";
import PlusIcon from "../../../../../assets/images/plus.png";
import MinusIcon from "../../../../../assets/images/minus.png";

import "./index.scss";
import {
  CONSTANTS,
  ICONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../../static/constants";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
  getAWSFileURL,
  showToasterMessage,
  fetchPaginationDataIfNeeded,
  getPermissionData,
} from "../../../../../utils";
import PageHeader from "../../../../Common/pageHeader";
const format = CONSTANTS.TABLE_DATE_FORMAT;

let gridApi;
const CategoryListingComp = (props) => {
  const {
    permissions,
    productCategories,
    companyInfo,
    showModal,
    updateState,
    selectedItems = [],
  } = props;

  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PRODUCT_CATEGORY);
  },[]) 
  // const [openCategories, setOpenCategories] = useState({});
  // const getRowHeight = (params) => {
  //   let height = 50;
  //   return height;
  // }

  // const addItemConfirmationModal = (text) => {
  //   const labelName = <FormattedMessage id='addItem.text.productCategory' defaultMessage='' />;
  //   const modalBody = <span>
  //     <FormattedMessage
  //       id='addItem.confirmation'
  //       values={{ value: `${text} to ` }}
  //       defaultMessage=''
  //     />
  //     {labelName} ?
  //   </span>;
  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => {
  //       props.addCategory({ text: text, relationshipId: companyInfo.relationshipId })
  //       props.hideModal();
  //     },
  //   };
  //   showModal(modalData);
  // };

  // const newCategoryAddition = () => {
  //   const formData = {};
  //   formData.relationshipId = companyInfo.relationshipId;
  //   const payload = { formData: formData };
  //   const data = {
  //     title: <FormattedMessage id='addItem.text.productCategory' defaultMessage='' />,
  //     formData,
  //     modalData: { modalType: MODAL_TYPE.PRODUCT_CATEGORY, data: payload },
  //     handleSubmit: (formData = {}) => {
  //       if (formData.textBoxValue && formData.textBoxValue.length) {
  //         addItemConfirmationModal(formData.textBoxValue);
  //       } else {
  //         data.formData.submittedOnce = true;
  //         showModal(data);
  //       }
  //     }
  //   };
  //   showModal(data);
  // }

  // const toggleCategory = (categoryId) => {
  //   setOpenCategories((prevOpenCategories) => ({
  //     ...prevOpenCategories,
  //     [categoryId]: !prevOpenCategories[categoryId],
  //   }));
  // };

  const newCategoryAddition = (categoryData) => {
    let payload = {};
    let formData = {};

    formData.callback = function (contact) {
      props.fetchCategory({
        companyInfo: props.companyInfo,
        companyDescription: props.companyDescription,
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 100,
      });
    };
    payload.formData = formData;
    props.updateHeaderState({
      inventory: {
        categoryDrawerVisible: true,
        categoryData: { ...(categoryData || {}) },
        addAsSubcategory: categoryData.parentId === 0 ? false : true,
        parentName: (props.productCategories || []).find(
          (obj) => obj.inventoryItemCategoryId === categoryData.parentId
        )?.categoryName,
      },
    });

    // const data = {
    //   title: <FormattedMessage id='addItem.text.productCategory' defaultMessage='' />,
    //   formData,
    //   hideFooter: true,
    //   modalData: {
    //     modalType: MODAL_TYPE.PRODUCT_CATEGORY,
    //     data: payload,
    //     categoryData : categoryData || {},
    //   },
    //   handleSubmit: (formData = {}) => {
    //     data.formData.submittedOnce = true;
    //   }
    // };
    // pushModalToStack(data);
  };

  const newCategory = () => {
    props.updateHeaderState({
      inventory: {
        categoryDrawerVisible: true,
      },
    });
  };

  const deleteCategory = (payload) => {
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.categoryName }}
      />
    );
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteCategory({
          id: payload.inventoryItemCategoryId,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  const updateCategory = (payload) => {
    const modalBody = (
      <FormattedMessage
        id="updateItem.confirmation"
        defaultMessage=""
        values={{ value: payload.categoryName }}
      />
    );
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateCategory(payload);
      },
    };
    showModal(modalData);
  };


  const columnDefs = [
    {
      headerName: "#",
      resizable: true,
      width: 70,
      cellRendererFramework: (link) => <div>{link.rowIndex + 1}</div>,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="drawer.inventory.tabNames.image"
            defaultMessage=""
          />
        );
      },
      width: 100,
      field: "awsKeyName",
      resizable: true,
      cellRendererFramework: (link) => (
        <Tooltip
          placement="right"
          title={
            <div className="img-expand1">
              {link.data.awsKeyName ? (
                <img
                  style={{ height: "100px" }}
                  src={getAWSFileURL(
                    link.data.awsKeyName,
                    config.BUCKET_NAME.BO_INVENTORY
                  )}
                  alt="Avatar"
                  className="image"
                />
              ) : (
                <PictureOutlined style={{ fontSize: "100px" }} />
              )}
            </div>
          }
          key={link.rowIndex}
        >
          <div style={{ cursor: "pointer" }}>
            {link.data.awsKeyName ? (
              <img
                style={{ height: "40px", margin: "5px" }}
                src={getAWSFileURL(
                  link.data.awsKeyName,
                  config.BUCKET_NAME.BO_INVENTORY
                )}
                alt="Avatar"
                className="image"
              />
            ) : (
              <PictureOutlined style={{ fontSize: "40px", margin: "5px" }} />
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.extras.category.listing.header.categoryName"
            defaultMessage=""
          />
        );
      },
      field: "categoryName",
      editable: true,
      cellEditor: "customTextEditor",
      resizable: true,
      cellClass: "cellInputControl",
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.extras.category.listing.header.categoryDescription"
            defaultMessage=""
          />
        );
      },
      field: "categoryDesc",
      editable: true,
      cellEditor: "customTextEditor",
      resizable: true,
      cellRendererFramework: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value || "-",
          }}
        ></div>
      ),
      cellClass: "cellInputControl",
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.extras.category.listing.header.dateCreated"
            defaultMessage=""
          />
        );
      },
      resizable: true,
      cellRendererFramework: (link) => (
        <div>
          {getMomentDateForUIReadOnly({
            date: new Date(link.data.dateCreated),
            format,
          })}
        </div>
      ),
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.extras.category.listing.header.totalProducts"
            defaultMessage=""
          />
        );
      },
      resizable: true,
      field: "totalProducts",
    },
  ];

  if (permissionsData.update || permissionsData.delete) {
    columnDefs.push({
      pinned: "right",
      width: 80,
      suppressNavigable: true,
      cellClass: "lastActionCell",
      cellRendererFramework: (params) => (
        <div>
          {params.node.data.isToEdit && (
            <div className="table-cell-action">
              <Link
                onClick={() => {
                  updateCategory(params.data);
                  gridApi.getDisplayedRowAtIndex(
                    params.node.rowIndex
                  ).data.isToEdit = false;
                  gridApi.refreshCells({ force: true });
                }}
              >
                <i className="fa fa-save" />
              </Link>
              <Link
                onClick={() => {
                  params.api.stopEditing(true);
                  gridApi.getDisplayedRowAtIndex(
                    params.node.rowIndex
                  ).data.isToEdit = false;
                  gridApi.refreshCells({ force: true });
                }}
              >
                <i className="fa fa-ban" />
              </Link>
            </div>
          )}

          {!params.node.data.isToEdit && (
            <div className="table-cell-action srty">
              {permissionsData.update && (
                <Link
                  onClick={() => {
                    // gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                    /// gridApi.refreshCells({ force: true });
                    newCategoryAddition(params.data);
                  }}
                >
                  <i className="fa fa-edit" />
                </Link>
              )}
              {permissionsData.delete && (
                <Link onClick={() => deleteCategory(params.data)}>
                  <i className="fa fa-remove" />
                </Link>
              )}
            </div>
          )}
        </div>
      ),
    });
  }

  // const onFirstDataRendered = (params) => {
  //   //params.api.sizeColumnsToFit();
  // }

  // const agGridStyle = {
  //   height: '100%',
  //   width: '100%'
  // };

  // const onGridReady = (params) => {
  //   gridApi = params.api;
  //   params.api.sizeColumnsToFit();
  // }

  const breadCrumbList = [
    {
      name: "Products",
    },

    {
      name: "Category",
    },
  ];

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
          {
            permissionsData?.update ?  <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              newCategoryAddition({ ...e, isUpdate: true });
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>: ""
          }
       

        {permissionsData?.delete && (
          <Menu.Item
            key="2"
            onClick={() => {
              deleteCategory(e);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchCategory",
      "productCategories",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchProducts(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };
  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>

        <Menu.Item
          key="3"
          onClick={() => {
            const productCategories = [];
            selectedItems.forEach((obj) => {
              productCategories.push({
                schoolStudentId: obj.schoolStudentId,
                relationshipId: obj.relationshipId,
                subcourseId: obj.subcourseId,
                courseId: obj.courseId,
                orgRegistrationId: obj.orgRegistrationId,
                customerId: obj.customerId,
                contactId: obj.contactId || 0,
                firstName: obj.firstName,
                lastName: obj.lastName,
              });
            });
            if (productCategories.length) {
              props.updateState({
                emailDetailDrawerVisible: true,
                isSendBulkInvoice: true,
                isBulkInvoice: true,
                // selectedStudentList: studentList,
                docName: "students",
              });
              // props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <MailOutlined />
          Send Email
        </Menu.Item>
      </Menu>
    );
  };

  const isCheckedAll = () => {
    if (!props.productCategories || props.productCategories.length === 0) {
      return false;
    }
    for (let i = 0; i < props.productCategories.length; i++) {
      let usr = props.productCategories[i];
      let selectItem = find(selectedItems, {
        inventoryItemCategoryId: Number(usr.inventoryItemCategoryId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < productCategories.length; i++) {
      let item = productCategories[i];
      let selectItem = find(selectedItems, {
        inventoryItemCategoryId: Number(item.inventoryItemCategoryId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      inventoryItemCategoryId: Number(item.inventoryItemCategoryId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.inventoryItemCategoryId !== item.inventoryItemCategoryId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const [childOnList, setChildOn] = useState([]);

  const isChildOn = (id) => {
    if (childOnList.includes(id)) {
      return true;
    }
    return false;
  };
  const setChildOff = (data) => {
    const itemIndex = childOnList.indexOf(data);
    const newArr = childOnList.splice(itemIndex, 1);
  };

  const getsubcategories = (subcategories, index) => {
    return subcategories.map((childRowData, i) => {
      const imageUrl = getAWSFileURL(childRowData?.awsKeyName ,props.companyInfo.bucketName , childRowData?.folderName)

      return (
        <>
          <tr key={childRowData.inventoryItemCategoryId}>
            {/* <td>
              <Checkbox
                onClick={() => {
                  itemSelection(childRowData);
                }}
                checked={
                  find(selectedItems, {
                    inventoryItemCategoryId: Number(
                      childRowData.inventoryItemCategoryId
                    ),
                  })
                    ? true
                    : false
                }
              />
            </td> */}

            <td>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: (childRowData.subcategories || []).length
                      ? "20px"
                      : "55px",
                  }}
                >
                  {(childRowData.subcategories || []).length ? (
                    isChildOn(childRowData.inventoryItemCategoryId) ? (
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setChildOff(childRowData.inventoryItemCategoryId);
                        }}
                      >
                        <img
                          width={"15px"}
                          height={"15px"}
                          src={MinusIcon}
                          alt="-"
                        />
                      </span>
                    ) : (
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setChildOn([
                            ...childOnList,
                            childRowData.inventoryItemCategoryId,
                          ]);
                        }}
                      >
                        <img
                          width={"15px"}
                          height={"15px"}
                          src={PlusIcon}
                          alt="+"
                        />
                      </span>
                    )
                  ) : (
                    ""
                  )}
                  <div style={{ cursor: "pointer" }}>
                    {childRowData.awsKeyName ? (
                      <img
                        style={{ height: "40px", margin: "5px" }}
                        src={imageUrl}
                        alt="image"
                        className="image"
                      />
                    ) : (
                      <img
                        style={{ height: "40px", margin: "5px" }}
                        src={ImagePlaceholderAlipde}
                        alt="image"
                        className="image"
                      />
                    )}
                  </div>
                </div>
                <div>{childRowData.categoryName} </div>
              </div>
            </td>

            <td>
              <div
                dangerouslySetInnerHTML={{
                  __html: childRowData.categoryDesc || "-",
                }}
              ></div>
            </td>
            <td>
              <div>
                {getMomentDateForUIReadOnly({
                  date: new Date(childRowData.dateCreated),
                  format,
                })}
              </div>
            </td>

            <td>{childRowData.totalProducts}</td>
            <td>
              <div className="status-toggle">
                <Switch defaultChecked />
              </div>
            </td>
            {
              (permissionsData?.delete || permissionsData?.update ) ?  <td style={{ paddingLeft: "50px" }}>
              <div className="action-icon">
                <DropdownAction
                  overlay={actionMenu(childRowData)}
                  trigger={["click"]}
                >
                  <i className={ICONS.MORE} />
                </DropdownAction>
              </div>
            </td>: ""
            }
           
          </tr>
          {childRowData.subcategories &&
          childRowData.subcategories.length &&
          isChildOn(childRowData.inventoryItemCategoryId)
            ? getsubcategories(
                childRowData.subcategories,
                index + "." + Number(i + 1)
              )
            : ""}
        </>
      );
    });
  };

  return (
    <Fragment>
        <PageHeader {...props} 
        pageName= "inventory.extras.category.listing.header.productCategories"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        
        onCreate={newCategory}
        moduleCode ={MODULE_CODED_VALUES.PRODUCT_CATEGORY}
      />
      
      <div className="view-container">
        {/* <div className="page-title">
          <div><FormattedMessage id='inventory.extras.category.listing.header.productCategories' defaultMessage='' /></div>
          <div className="modal-sub-header"><FormattedMessage id='inventory.extras.category.listing.subHeader.text' defaultMessage='' /></div>
        </div> */}

        {/* <div className="action-dropdown-btn pull-right">
          {
            permittedAction.create && (
              <button className="right-border" onClick={newCategoryAddition}>
                <i className="fa fa-user" />
                <FormattedMessage id='inventory.extras.category.listing.header.newCategory' defaultMessage='' />
              </button>
            )
          }
        </div> */}

        <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="inventory.extras.category.listing.header.productCategories.table.heading"
                  defaultMessage=""
                />
              </div>
              {props.pageSize ? (
                <>
                  <div className="vertical-sep" />
                  <div>{props.productCategories.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions"></div>
          </div>
          {/* <div className='agGridWrapper d-flex w-100 pt15'>
            <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={productCategories || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                getRowHeight={getRowHeight}
                onGridSizeChanged={onFirstDataRendered}
                editType={'fullRow'}
              >
              </CustomAgGrid>
            </div>
          </div> */}
          <div className="table-container">
            {/* <table>
              <thead>
                <tr>
                  <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                  <th><FormattedMessage id='drawer.inventory.tabNames.image' defaultMessage=''/></th>
                  <th><FormattedMessage id='inventory.extras.category.listing.header.categoryName' defaultMessage=''/></th>
                  <th><FormattedMessage id='inventory.extras.category.listing.header.categoryDescription' defaultMessage=''/></th>
                  <th><FormattedMessage id='inventory.extras.category.listing.header.dateCreated' defaultMessage=''/></th>
                  <th><FormattedMessage id='inventory.extras.category.listing.header.totalProducts' defaultMessage=''/></th>
                  <th><FormattedMessage id='Action' defaultMessage=''/></th>
                </tr>
              </thead>
              <tbody>
                {productCategories && productCategories.sort((a, b) =>
                        a.inventoryItemCategoryId < b.inventoryItemCategoryId ? 1 : -1
                      ).map((rowData,index)=>{
                  return(
                    <tr key={index}>
                  <td>{index+1}</td>
                  <td><Tooltip placement='right' title={<div className='img-expand1'> {rowData.awsKeyName ?
         <img style={{ height: '100px' }} src={getAWSFileURL(rowData.awsKeyName, config.BUCKET_NAME.BO_INVENTORY)} alt="Avatar" className="image" />
        : <PictureOutlined style={{ fontSize: '100px' }} />}
      </div>} key={rowData.rowIndex}>
        <div style={{ cursor: 'pointer'}}> {rowData.awsKeyName ?
        <img style={{ height: '40px', margin: '5px' }} src={getAWSFileURL(rowData.awsKeyName, config.BUCKET_NAME.BO_INVENTORY)} alt="Avatar" className="image" />
        : <PictureOutlined style={{ fontSize: '40px', margin: '5px' }} />}
        </div></Tooltip></td>
                  <td>{rowData.categoryName}</td>
                  <td><div dangerouslySetInnerHTML={{
        __html: rowData.categoryDesc || '-'
      }}>
      </div></td>
                  <td><div>
        {getMomentDateForUIReadOnly({date: new Date(rowData.dateCreated), format})}
      </div></td>
                  <td>{rowData.totalProducts}</td>
                  <td style={{paddingLeft: '50px'}}> 
                        <div className="action-icon">
                          <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                            <MoreOutlined />
                          </DropdownAction>
                        </div>
                      </td>
                </tr>
                  )
                })}
              </tbody>
            </table> */}

            <table>
              <thead>
                <tr>
                  {/* <th width="5%">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    &nbsp;
                    <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                      <i className={ICONS.DOWN_ARROW} />
                    </DropdownAction>
                  </th> */}

                  <th style={{ padding: "10px 20px 10px 25px" }}>
                    <FormattedMessage
                      id="inventory.extras.category.listing.header.categoryList"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.category.listing.header.categoryDescription"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.category.listing.header.dateCreated"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.category.listing.header.totalProducts"
                      defaultMessage=""
                    />
                  </th>

                  <th>
                    <FormattedMessage
                      id="inventory.extras.category.listing.header.status"
                      defaultMessage=""
                    />
                  </th>
                  {
                    (permissionsData?.delete || permissionsData?.update ) ? <th className="text-center">
                    <FormattedMessage id="Action" defaultMessage="" />
                  </th>: ""
                  }
                  
                </tr>
              </thead>
              {/* {renderTable(productCategories)} */}
              <tbody>
                {(productCategories || [])
                  .filter((rowData) => rowData.parentId === 0)
                  .map((rowData, index) => {
                    const image = getAWSFileURL(rowData?.awsKeyName,props.companyInfo.bucketName , rowData?.folderName)

                    return (
                      <>
                        <tr key={index}>
                          {/* <td>
                            <Checkbox
                              onClick={() => {
                                itemSelection(rowData);
                              }}
                              checked={
                                find(selectedItems, {
                                  inventoryItemCategoryId: Number(
                                    rowData.inventoryItemCategoryId
                                  ),
                                })
                                  ? true
                                  : false
                              }
                            />
                          </td> */}
                          {/* <td>
                            <span className="mr5">
                              {Number(
                                (props.pageNumber - 1) * props.pageSize +
                                  index +
                                  1
                              )}
                            </span>
                            {rowData.subcategories &&
                            rowData.subcategories.length ? (
                              isChildOn(rowData.inventoryItemCategoryId) ? (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setChildOff(
                                      rowData.inventoryItemCategoryId
                                    );
                                  }}
                                >
                                  <MinusSquareOutlined />
                                </span>
                              ) : (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setChildOn([
                                      ...childOnList,
                                      rowData.inventoryItemCategoryId,
                                    ]);
                                  }}
                                >
                                  <PlusSquareOutlined />
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </td> */}
                          {/* <td>
                            <Tooltip
                              placement="right"
                              title={
                                <div className="img-expand1">
                                  {rowData.awsKeyName ? (
                                    <img
                                      style={{ height: "100px" }}
                                      src={getAWSFileURL(
                                        rowData.awsKeyName,
                                        config.BUCKET_NAME.BO_INVENTORY
                                      )}
                                      alt="Avatar"
                                      className="image"
                                    />
                                  ) : (
                                    <PictureOutlined
                                      style={{ fontSize: "100px" }}
                                    />
                                  )}
                                </div>
                              }
                              key={rowData.rowIndex}
                            >
                              <div style={{ cursor: "pointer" }}>
                                {rowData.awsKeyName ? (
                                  <img
                                    style={{ height: "40px", margin: "5px" }}
                                    src={getAWSFileURL(
                                      rowData.awsKeyName,
                                      config.BUCKET_NAME.BO_INVENTORY
                                    )}
                                    alt="Avatar"
                                    className="image"
                                  />
                                ) : (
                                  <PictureOutlined
                                    style={{ fontSize: "40px", margin: "5px" }}
                                  />
                                )}
                              </div>
                            </Tooltip>
                          </td> */}
                          {/* <td style={{ padding: "10px 20px 10px 0px" }}> */}
                            {/* <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center", 
                                
                                }}
                              > */}
                            {/* <div> */}
                            <td>
                            <div className="plus-minus-icon">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {rowData.subcategories &&
                                rowData.subcategories.length ? (
                                  isChildOn(rowData.inventoryItemCategoryId) ? (
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setChildOff(
                                          rowData.inventoryItemCategoryId
                                        );
                                      }}
                                    >
                                      <img
                                        className="plus-icon"
                                        src={MinusIcon}
                                        alt="PlusIcon"
                                      />
                                      {/* <MinusSquareOutlined /> */}
                                    </span>
                                  ) : (
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setChildOn([
                                          ...childOnList,
                                          rowData.inventoryItemCategoryId,
                                        ]);
                                      }}
                                    >
                                      {/* <PlusSquareOutlined /> */}
                                      <img
                                        className="plus-icon"
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                      />
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}

                                <div style={{ cursor: "pointer" }}>
                                  {rowData.awsKeyName ? (
                                    <img
                                      style={{ height: "40px", margin: "5px" }}
                                      src={image}
                                      alt="Avatar"
                                      className="image"
                                    />
                                  ) : (
                                    // <PictureOutlined
                                    //   style={{ fontSize: "40px", margin: "5px" }}
                                    // />
                                    <img
                                      style={{ height: "40px", margin: "5px" }}
                                      src={ImagePlaceholderAlipde}
                                      alt="Avatar"
                                      className="image"
                                    />
                                  )}
                                </div>

                                <div>{rowData.categoryName} </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {rowData.categoryDesc ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: rowData.categoryDesc,
                                }}
                              ></div>
                            ) : (
                              <div className="empty-data"></div>
                            )}
                          </td>
                          <td>
                            <div>
                              {getMomentDateForUIReadOnly({
                                date: new Date(rowData.dateCreated),
                                format,
                              })}
                            </div>
                          </td>

                          <td>
                            {(rowData.inventoryItemCategoryRefs || []).length ||
                              0}
                          </td>
                          <td>
                            <div className="status-toggle">
                              <Switch
                                checked={!!rowData.isPublished}
                                disabled={!(permissionsData?.update)}
                                onChange={(checked) => {
                                  
                                  props.addCategory(
                                    {
                                      ...rowData,
                                      isPublished: checked ? 1 : 0,
                                    },
                                    props
                                  );
                                }}
                              />
                            </div>
                          </td>
                          {
                            (permissionsData?.delete || permissionsData?.update ) ?  <td className="text-center">
                            <div className="action-icon">
                              <DropdownAction
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </div>
                          </td>: ""
                          }
                         
                        </tr>
                        {rowData.subcategories &&
                        rowData.subcategories.length &&
                        isChildOn(rowData.inventoryItemCategoryId)
                          ? getsubcategories(rowData.subcategories, index + 1)
                          : ""}
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={productCategories.length}
              showTotal={showTotal}
              defaultPageSize={100}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(CategoryListingComp);
