import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal } from '../../../../../actions/commonActions';

import { pushModalToStack } from '../../../../../actions/commonActions';
import { updateOrCreateEcomSettings } from '../../../../eStore/menus/action';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
// import SupportAndSocialComp from '../../../../../components/modal/modalBody/eStore/SupportAndSocial';
// import LanguagesComp from '../../../../../components/modal/modalBody/eStore/Languages/Languages';
import DomainsComp from '../../../../../components/modal/modalBody/eStore/Domains';
import { saveEcomWebsiteUrl,fetchEcomWebsiteUrl } from './action';

class Domains extends Component {
    constructor(props){
        super(props);
        this.state= {
            isHeaderMenuLoaded: false,
            websiteUrl: '',
            shopName:'',
        }
    }
    componentDidMount() {
        const companyInfo = this.props.userInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
       
        };
       
        this.props.fetchE_StoreData(payload)
        this.props.fetchEcomWebsiteUrl(payload)
        this.setState({
            storeValues:this.props.storeValues
        })
      }
      componentWillReceiveProps(props){
        if(!this.state.isHeaderMenuLoaded && Object.keys(props.storeValues || {}) ){
          
        
         
          this.setState({storeData:props.storeValues ,websiteUrl: props.storeValues.websiteUrl ,shopName: props.storeValues.shopName})
        }
    
      }
    
    render() {
        return (
          <DomainsComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        )
            
       
    }

}


const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      relationshipId: state.common.companyInfo.relationshipId,
      storeValues: state.common.store_values,
      ecomDomain: state.eStore.ecomDomainData,
      permissions: state.auth.permissions,
      
    };
  };
  
    const mapDispatchToProps = (dispatch) => bindActionCreators({
  
       pushModalToStack,
       updateOrCreateEcomSettings,
      fetchE_StoreData,fetchEcomWebsiteUrl,saveEcomWebsiteUrl,
      showModal,
      hideModal
      
    }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Domains);