import React, { useState ,useMemo} from "react";
import PageBreadcrumb from "../PageBreadcrumb";
import "./integration.scss";
import { FormattedMessage } from "react-intl";

import MenuIcon1 from "../../assets/images/icon/paymentGateWayIcon.png";
import MenuIcon2 from "../../assets/images/icon/marketPlaceIcon.png";
import MenuIcon3 from "../../assets/images/icon/foodIcon.png";
import MenuIcon4 from "../../assets/images/icon/shipingIcon.png";
import MenuIcon5 from "../../assets/images/icon/accountingIcon.png";
// import Enquiry from "../page/Enquiry";
import MarketPlace from "../../containers/integrations/MarketPlaceTab"
import Food from "../../containers/integrations/FoodTab"
import Shipping from "../../containers/integrations/ShippingTab"
import Accounting from "../../containers/integrations/AccountingTab"
import Payment from "../../containers/integrations/Payment";
import EmailIcon from "../../assets/images/email-icon.png";
import Email from "../../containers/integrations/Email";
import InPageMenu from "../Common/InPageMenu";
import { MODULE_CODED_VALUES } from "../../static/constants";
import { getPermissionData } from "../../utils";

const Integrations = (props) => {
  const primaryPerm = (props.permissions && props.permissions.primary) || [];
  const paymentPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATIONS_PAYMENT )
  },[])
  const marketPlacePermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATION_MARKETPLACE )
  },[])
  const shippingPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATION_SHIPPING )
  },[])
  const accountingPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATION_ACCOUNTING )
  },[])
  const emailPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATION_EMAIL )
  },[])
  const breadCrumbList = [
    // {
    //   name: <FormattedMessage id='integrations.pageName.integration' defaultMessage='' />,
    // },
    // {
    //   name: componentToDisplay,
    // },
  ];
  const menuList = [{
    path: 'a',
    icon: MenuIcon1,
    name: "integrations.menulabel.paymentGateWay",
    description: "You are powered with creating multiple users for your business.",
    moduleCode:MODULE_CODED_VALUES.INTEGRATIONS_PAYMENT,
    key:'Payment Gateway'

},
{
    icon: MenuIcon2,
    name: "integrations.menulabel.marketPlace",
    description: "You are powered with creating multiple users for your business.",
    moduleCode:MODULE_CODED_VALUES.INTEGRATION_MARKETPLACE,
    key:'Marketplace'

},
{
    icon: MenuIcon4,
    name: "integrations.menulabel.shipping",
    description: "You are powered with creating multiple users for your business.",
    moduleCode:MODULE_CODED_VALUES.INTEGRATION_SHIPPING,
    key:'Shipping'

},
{
    icon: MenuIcon5,
    name: "integrations.menulabel.accounting",
    description: "You are powered with creating multiple users for your business.",
    moduleCode:MODULE_CODED_VALUES.INTEGRATION_ACCOUNTING,
    key:'Accounting'
},
{
    icon: EmailIcon,
    name: "Email",
    description: "You are powered with creating multiple users for your business.",
    moduleCode:MODULE_CODED_VALUES.INTEGRATION_EMAIL,
    key:'Email'
},
]
;

  const handleActiveTab = (index) => {
    props.updateState({
        selectedMenuItem: index
    })
};

const activeTab = () => {

  console.log(props.selectedMenuItem,"tabssssssssss")
    switch (props.selectedMenuItem) {
        case "Payment Gateway":         
          return paymentPermission.read ? <Payment /> : "";     
      case "Marketplace":   
          return marketPlacePermission.read ? <MarketPlace /> : "";   
        case "Shipping":
          return shippingPermission.read ? <Shipping /> : "";
        case "Accounting":
          return accountingPermission.read ? <Accounting /> : "";
        case "Email":
            return emailPermission.read ? <Email /> : "";
       
        default:
            return null;
   
  }
};
  return (
    <div className="StoreSettings-container">
      <div className="page-title-bar">
        <div className="page-title">Integration</div>
        <div className="sep" />
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div>
      <div className="meta-container">
                <div className="meta-sidenav">
                    <InPageMenu menuList={menuList} onItemClick={handleActiveTab} {...props}/>
                </div>
                <div className="meta-main-body">
                    {activeTab()}                
                </div>
            </div>
    </div>
  );
};

export default Integrations;

