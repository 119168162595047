import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
import { EditOutlined, FilePdfOutlined, MailOutlined, PrinterOutlined, DeleteOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Select, Menu, Col } from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE, PERMISSION_VALUES, MODULE_CODED_VALUES, CONSTANTS_TRANSACTIONS} from '../../../../../static/constants';
import { fixedDecimalNumber, checkACLPermission, showToasterMessage, numberToWordsWithDecimals, getPermissionData } from '../../../../../utils';
import StandardTemplate from "./standard";
import Template2 from "./template2"; 
import StandardSalesOrder from "../../../../../containers/customer/salesOrder/StandardSalesOrder"
import StandardPackage from "../../../../../containers/customer/salesOrder/package/index"
import StandardPurchaseRequest from "../../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest"
import ShareOrder from '../ShareOrder';
import DropdownAction from "antd/lib/dropdown";
import ReactHtmlParser from 'react-html-parser';


const { Option } = Select;

const SODetails = (props) => {
    const { soDetail, companyInfo, templateName = 'Professional', permissions } = props;
    const primaryPerm = permissions.primary;
    const salesOrderPermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_ORDER);
      },[])

      const cancelOrderPermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.AMEND_SALES_ORDER);
      },[])
      const purchaseRequestPermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PURCHASE_REQUEST);
      },[])

      const salesInvoicePermissions = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_INVOICE);
      },[])

      const productPackagePermission = useMemo(()=>{
        return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_ORDER_PACKAGE);
      },[])

    const { customerSalesOrderDetailsList } = soDetail;
    const relationshipAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(soDetail.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (soDetail.placeOfSupply && relationshipAddress.stateName === soDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (customerSalesOrderDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true
    };
    if (soDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, soDetail, props);
    }   
    const [showComp, setShowComp] = useState(false);
    useEffect(()=>{
      const delay = setTimeout(()=>{
        setShowComp(true);
      }, 500);
      return ()=> clearTimeout(delay);
    }, []);
    const getPayloadForPdf = () => {
        return {
            templateName: templateName,
            fileName : (soDetail.soNumber || 'Sales Order')+'.pdf',
            transactionName: 'salesOrder',
            soDetail: soDetail,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            bucketName: config.BUCKET_NAME.BO_SALES_ORDER,
            stampAwsKey: soDetail.stampAwsKey
        }
    }
    const getVariantFragment = (item) => {
        let itemName = null;
        if (item.itemName) {
            itemName = item.itemName;
        }
        if (item.itemVariantName) {
            itemName = itemName + ' (' + item.itemVariantName + ')'
        }
        return itemName ? itemName : '';
    }
    
    const getDescriptionFragment = (soDetail, item, index) => {
        if (!item) return <Fragment />;
        const fragmentObj = <Fragment>
            {
                item.materialNumber
                    ? <div>
                        <span className="title"> Material # : </span>{item.materialNumber}
                        <br></br>
                        <br></br>
                    </div> :
                    ''
            }
            {
                item.stockNumber
                    ? <div>
                        <span className="title"> Stock # : </span>{item.stockNumber}
                        <br></br>
                        <br></br>
                    </div> :
                    ''
            }
            {
                item.partNumber
                    ? <div>
                        <span className="title"> Part # : </span>{item.partNumber}
                        <br></br>
                        <br></br>
                    </div> :
                    ''
            }
            {
                item.description
                    ? <div>
                        {ReactHtmlParser(item.description || '')}
                    </div> :
                    ''
            }
            {
                item.origin
                    ? <div>
                        <br></br>
                        <span className="title"> Origin : </span>{item.origin}
                    </div> :
                    ''
            }
            {
                item.hsCode
                    ? <div>
                        <span className="title"> HS Code : </span>{item.hsCode}
                        <br></br>
                        <br></br>
                    </div> :
                    ''
            }
            {
                item.warehouse
                    ? <div>
                        <span className="title"> Warehouse Name : </span> <span style={{ 'display': 'inline-block' }}>{ReactHtmlParser(item.warehouse || '')} </span>
                        <br></br>
                        <br></br>
                    </div> :
                    ''
            }
            {
                (soDetail.customerPONumber)
                    ? <div> (
                        {soDetail.customerPONumber ? <span className="title">PO {soDetail.customerPONumber} - </span> : ''}
                        {index ? <span className="title"> Line {index}</span> : ''}
                        )
                    </div> :
                    ''
            }
        </Fragment>
        return fragmentObj;
    }
    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired= true;
        payload.customerId = soDetail.customerId;
        payload.salesOrderMasterId = soDetail.salesOrderMasterId;

        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.SALES_ORDER,
        });
    };
    const actionMenu = (param) => {
        return (
            <Menu className="cell-action-dropdown">            
                <Menu.Item key="1" onClick={() => {
                    props.pushModalToStack({
                        title: <div>
                            <FormattedMessage id='shareOrder.text' defaultMessage='' />
                            <div style={{ 'fontSize': '12px' }}> {soDetail.customerName || ''}</div>
                        </div>,
                        modalBody: <ShareOrder {...props} />,
                        width: '50%',
                        hideFooter: true,
                    })
                }}><FormattedMessage id='shareOrder.text' defaultMessage='' />
                </Menu.Item>
            </Menu >)
    }
    const approveSO = () => {
        const modalBody = 'Are you sure you want to approve?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approveSO({
                    relationshipId: soDetail.relationshipId,
                    customerId: soDetail.customerId,
                    salesOrderMasterId: soDetail.salesOrderMasterId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props)
                props.popModalFromStack();
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = () => {
        const formData = {
            relationshipId: soDetail.relationshipId,
            customerId: soDetail.customerId,
            salesOrderMasterId: soDetail.salesOrderMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            approverEmpId: soDetail.approverEmpId,
            txType: TRANSACTION_TYPES.SALES_ORDER
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = () => {
        const formData = {
            relationshipId: soDetail.relationshipId,
            customerId: soDetail.customerId,
            salesOrderMasterId: soDetail.salesOrderMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.SALES_ORDER
        };
        const { showModal } = props;
        const data = {
            title: 'Reject',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.REJECT_APPROVAL,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const openAuditDrawer = (props, soDetail) => {
        props.updateState({
            title: <div className="drawer-title">
                <FormattedMessage id='modal.txDetails.common.auditTrail' defaultMessage='' />
            </div>,
            auditDrawerVisible: true,
            deletApiUrl: 'deleteAuditTrailSalesOrder',
            masterKey: 'soMasterId',
            masterKeyValue: soDetail.salesOrderMasterId,
            apiUrl: 'saveAuditTrailSalesOrder',
            customerId: soDetail.customerId,
            trailList: soDetail.auditTrailSalesOrderList,
            updateTrailListInTransaction: (list) =>{
                soDetail.auditTrailSalesOrderList = list;
            } 
        });
    }

    // const updatePRStatus = (props, soData) => {
    //     let payload = { formData: {maxLength: 500}, txData: soData };
    //     const formData = payload.formData;
    //     const { pushModalToStack } = props;

    //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
    //         addItemConfirmationModal(props, formData.textBoxValue, soData);
    //     } else {
    //         formData.textBoxValue= soData.statusPRMessage;
    //         const data = {
    //             title: <FormattedMessage id='modal.txDetails.soStatus' defaultMessage='' />,
    //             formData,
    //             modalData: { modalType: MODAL_TYPE.UPDATE_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER },
    //             handleSubmit: (formData = {}) => {
    //                 if (formData.textBoxValue && formData.textBoxValue.length) {
    //                     addItemConfirmationModal(props, formData.textBoxValue, soData);
    //                 } else {
    //                     data.formData.submittedOnce = true;
    //                     pushModalToStack(data);
    //                 }
    //             }
    //         };
    //         pushModalToStack(data);
    //     }
    // }

    // const addItemConfirmationModal = (props, text, soData) => {
    //     const { showModal, companyInfo } = props;
    //     const labelName = <FormattedMessage id='modal.txDetails.soStatus' defaultMessage='' />;
    //     const modalBody = <span>
    //         <FormattedMessage
    //             id='setItem.confirmation'
    //             values={{ value: `${text} to ` }}
    //             defaultMessage=''
    //         />
    //         {labelName} ?
    //   </span>;

    //     let payload = {
    //         statusPRMessage: text,
    //         relationshipId: companyInfo.relationshipId,
    //         salesOrderMasterId: soData.salesOrderMasterId,
    //         customerId: soData.customerId
    //     };

    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => {
    //            props.updatePRStatus(payload, props);
    //             props.hideModal();
    //             props.popModalFromStack();
    //         },
    //     };
    //     showModal(modalData);
    // }

    const updateUserStatusConfirmationModal = (props, text, color, soData) => {
        const { showModal, companyInfo } = props;
       
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage=''
            />
           
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            salesOrderMasterId: soData.salesOrderMasterId,
            customerId: soData.customerId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }

    const updateUserStatus = (props, soData) => {
        let payload = { formData: {}, txData: soData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            updateUserStatusConfirmationModal(props, formData.textBoxValue, formData.statusColor, soData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        updateUserStatusConfirmationModal(props, formData.textBoxValue, formData.statusColor, soData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    const handleConvertTo = (actionName) => {
        switch(actionName){
            case 'Purchase Request': {
                props.popModalFromStack();
                props.pushModalToStack({
                    modalBody: <StandardPurchaseRequest {...props} customerId={soDetail.customerId} customerName= {soDetail.customerName} salesOrderMasterId= {soDetail.salesOrderMasterId}/>,
                    width: '100%',
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                
                // props.history.push({
                //     pathname: '/admin/pr-create',
                //     state: {
                //         customerId: soDetail.customerId,
                //         customerName: soDetail.customerName,
                //         salesOrderMasterId: soDetail.salesOrderMasterId
                //     }
                // })
                break;
            }
            case 'Performa Invoice': {
                props.checkAndConvertToPerformaInvoice(props, soDetail)
                break;
            }
            case 'Sales Invoice': {
                // props.popModalFromStack();
                props.canInvoiceBeGenerated(soDetail, props, [soDetail.salesOrderMasterId]);
                // props.history.push({
                //     pathname: soDetail.isMultiCurrency ? 'sales-invoice-multi-currency' : '/admin/sales-invoice',
                //     state: {
                //         customerId: soDetail.customerId,
                //         soMasterId: soDetail.salesOrderMasterId,
                //         soMasterIdArr: soDetail.salesOrderMasterId
                //     }
                // })
                break;
            }
            case 'Package': {
                props.popModalFromStack();
                props.pushModalToStack({
                    modalBody: <StandardPackage {...props} connected={true} customerId={soDetail.customerId} soMasterId= {soDetail.salesOrderMasterId} />,
                    width: '100%',
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                // props.history.push({
                //     pathname: '/admin/so/package',
                //     state: {
                //         customerId: soDetail.customerId,
                //         soMasterId: soDetail.salesOrderMasterId
                //     }
                // })
                break;
            }
              default: {
                break;
              }
        }

    }
    return (
        <div className={"custom-modal show"}>
            
            {templateName === 'Standard' && <StandardTemplate {...props} />}
            {templateName === 'Professional' && <Template2 {...props} />}

            <div className="modal-dialog">
                <div className="modal-content full-width">
                { showComp ?
                    <>
                    {(soDetail.status !== 'Pending Approval' && soDetail.status !== 'Rejected') ?
                        <div className=" button-group-action header-control">
                            <div className="small-btn-group">
                                {salesOrderPermissions?.update ?  <Button
                                    style={{height:'38px'}} 
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        if(soDetail.status === "cancelled"){
                                            return  showToasterMessage({
                                                description:
                                                  "Order Cancelled cannot be edited",
                                              });
                                        }
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardSalesOrder {...props} 
                                            customerId={soDetail.customerId} 
                                            soEditDetail = {soDetail}
                                            salesOrderMasterId={ soDetail.salesOrderMasterId} 
                                            update={true} />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                    }}
                                >
                                </Button> : ""}
                               {salesOrderPermissions?.create ? <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardSalesOrder {...props} customerId={soDetail.customerId} salesOrderMasterId={ soDetail.salesOrderMasterId} clone={true} />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                    }}
                                >
                                </Button> : ""}
                               { salesOrderPermissions?.delete ?  <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        props.showModal({
                                            modalBody: "Are you sure you want to delete this transaction?",
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                props.deleteSalesOrder({
                                                    salesOrderMasterId: soDetail.salesOrderMasterId,
                                                    customerId: soDetail.customerId,
                                                    relationshipId: soDetail.relationshipId
                                                }, props)
                                            }
                                        })
                                    }}
                                >
                                </Button> : ""}
                            </div>
                            <div className="small-btn-group">

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button>
                            </div>
                          
                                  <div className="small-btn-group">
                                <Button  style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.auditTrail', defaultMessage: '' })}
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                        openAuditDrawer(props, soDetail);
                                    }}
                                >
                                </Button>
                            </div>
                          
                          
                            <div className="small-btn-group">

                            { ((purchaseRequestPermissions?.create || salesInvoicePermissions?.create || productPackagePermission?.create)&&(soDetail.status !== 'Cancelled' && soDetail.status !== 'cancelled' && soDetail.status !== 'cancellation.requested' && (soDetail.statusPR !== 'converted' || soDetail.statusInvoice !== 'converted' || soDetail.statusPackage !== 'fulfilled'))) &&
                                <Select defaultValue="Convert To" style={{ width: 120 }} onChange={handleConvertTo}>
                                    {( purchaseRequestPermissions?.create &&  soDetail.statusPR !== 'converted') ? <Option value="Purchase Request"><FormattedMessage id='modal.txDetails.salesOrder.convertTo.purchaseRequest' defaultMessage='' /></Option>:""}
                                    {(salesInvoicePermissions?.create && soDetail.statusInvoice !== 'fulfilled') ? <Option value="Sales Invoice"><FormattedMessage id='modal.txDetails.salesOrder.convertTo.salesInvoice' defaultMessage='' /></Option> : ""}
                                    { salesInvoicePermissions?.create ? <Option value="Performa Invoice"><FormattedMessage id='modal.txDetails.salesOrder.convertTo.proformaInvoice' defaultMessage='' /></Option>: ""}
                                    {(productPackagePermission?.create && soDetail.statusPackage !== 'fulfilled') ? <Option value="Package"><FormattedMessage id='modal.txDetails.salesOrder.convertTo.package' defaultMessage='' /></Option>:""}
                                </Select>
                            }
                            </div>

                            <div className="">
                                <Dropdown 
                                    style={{'width': '120px'}}
                                    placeholder="Template"
                                    value={templateName}
                                    disabled={soDetail.status === "cancelled"}
                                    items={['Standard', 'Professional']}
                                    onSelect={(selectedValue) => {
                                        props.updateState({
                                            templateName: selectedValue,
                                        });
                                    }}
                                />
                            </div>
                            {
                                salesOrderPermissions?.update ?   <div className="small-btn-group ml10">
                                <Button style={{width:'auto',height:'38px'}}
                                    onClick={() => {
                                        updateUserStatus(props, soDetail);
                                    }}
                                >
                                    <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' /> 
                                </Button>
     
                                </div>: ""
                            }
                          
                            {(cancelOrderPermissions?.update && soDetail.status !== "cancelled") ?
                            <div className='small-btn-group'>
                                <Button 
                                    style={{width:'auto',height:'38px'}}
                                    onClick={() => {
                                        const data = JSON.parse(soDetail.cancelOrderData|| "{}");
                              
                                       
                                        props.showModal({
                                            modalBody: soDetail.status === "cancellation.requested.partial" ?<>
                                            Are you sure you want to cancel this Request?
                                            <div className="modal-table-container mb15">
                                        <table className="transaction-details-table">
                                            <thead>
                                                <tr>
                                                    <th className="cell-size-50">#</th>
                                                    {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                                        {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                                        {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                                        {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                                    </th>}
                                                    {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(data?.customerSalesOrderDetailsListCanceled || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="cell-size-50">{i + 1}</td>
                                                            {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                                <strong>{getVariantFragment(item)}</strong>
                                                                {!isColumnEmpty.description && <div className="description-cell">
                                                                    {getDescriptionFragment(soDetail, item, i + 1)}
                                                                </div>}
                                                            </td>}
                                                            {!isColumnEmpty.qty && <td className="text-center">{item.quantityToInvoice + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                            {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                            {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                            {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                                    
           
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                   
                                    </div>
                                            </>: "Are you sure you want to cancel this order?",
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                if(soDetail.status === "cancellation.requested.partial"){
                                                    props.cancelSalesOrderPartially && props.cancelSalesOrderPartially(soDetail, props);
                                                }else{
                                                    props.cancelSalesOrder && props.cancelSalesOrder(soDetail, props);
                                                }
                                            }
                                        })
                                    }}
                                >
                                    Cancel Order 
                                </Button>
                            </div>
                            :""
                            
                            }
                        {(cancelOrderPermissions?.update && soDetail.status === "cancellation.requested")  &&    <div className='small-btn-group'>
                                <Button 
                                    style={{width:'auto',height:'38px'}}
                                    onClick={() => {
                                        props.showModal({
                                            modalBody: "Are you sure you want to cancel this Request?",
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                props.requestCancelSalesOrder && props.requestCancelSalesOrder(soDetail, props);
                                            }
                                        })
                                    }}
                                >
                                    Reject Cancellation 
                                </Button>
                            </div>}

                            {(cancelOrderPermissions?.update && soDetail.status === "cancellation.requested.partial")  &&    <div className='small-btn-group'>
                                <Button 
                                    style={{width:'auto',height:'38px'}}
                                    onClick={() => {
                                        const data = JSON.parse(soDetail.cancelOrderData|| "{}");
                                        props.showModal({
                                            modalBody: <>
                                            Are you sure you want to cancel this Request?
                                            <div className="modal-table-container mb15">
                                        <table className="transaction-details-table">
                                            <thead>
                                                <tr>
                                                    <th className="cell-size-50">#</th>
                                                    {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                                        {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                                        {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                                        {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                                    </th>}
                                                    {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(data?.customerSalesOrderDetailsListCanceled || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="cell-size-50">{i + 1}</td>
                                                            {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                                <strong>{getVariantFragment(item)}</strong>
                                                                {!isColumnEmpty.description && <div className="description-cell">
                                                                    {getDescriptionFragment(soDetail, item, i + 1)}
                                                                </div>}
                                                            </td>}
                                                            {!isColumnEmpty.qty && <td className="text-center">{item.quantityToInvoice + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                            {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                            {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                            {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                                    
           
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                   
                                    </div>
                                            </>,
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                props.requestCancelSalesOrder && props.requestCancelSalesOrder(soDetail, props);
                                            }     
                                        })
                                    }}
                                >
                                    Reject Cancellation 
                                </Button>
                            </div>}
                            {/* <div className="small-btn-group" style={{ marginLeft: '10px' }}>
                                <Button style={{ width: 'auto' }}
                                    onClick={() => {
                                        updatePRStatus(props, soDetail);
                                    }}
                                >
                                    <FormattedMessage id='modal.txDetails.soStatus' defaultMessage='' />
                                </Button>
                            </div> */}
                            <div>
                            <DropdownAction overlay={actionMenu()} trigger={['click']}>
                                <Button className="action-btn-readonly action-btn small">
                                    <i className="fa fa-ellipsis-v" />
                                </Button>
                            </DropdownAction>
                        </div>
                        </div> :
                        <div className="button-group-action">
                            {soDetail.status !== 'Rejected' ?
                                <div className=" button-group-action header-control">
                                    <Button type="primary" onClick={() => { approveSO(); }}>
                                    <FormattedMessage id='modal.txDetails.salesOrder.approveSO' defaultMessage='' />
                            </Button>
                                    <Button type="primary" onClick={() => { handleDelegateApprover(); }}>
                                    <FormattedMessage id='modal.txDetails.common.delegateApprover' defaultMessage='' />
                            </Button>
                                    <Button type="danger" onClick={() => { handleRejectApproval(); }}>
                                    <FormattedMessage id='modal.txDetails.common.reject' defaultMessage='' /> 
                            </Button>

                                </div> :
                                <div>
                                </div>
                            }

                        </div>
                    }
                    </>
                   :""}
                </div>
            </div>
        </div>
    );

}


const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerSalesOrderCOATxList) return;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return (fixedDecimalNumber(data[toReturn]));
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}

const calculateTaxRateList = (customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerSalesOrderDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'tax')) || {};
           // let tax2 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'sgst'));
            //let tax3 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'igst'));
           // let tax4 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'vat'));
            // if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //     taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
            //     taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //     taxObj.taxPercent = Number(tax3.amountPercent);
            //     taxObj.taxAmount = Number(tax3.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (tax4) {
            //     taxObj.taxPercent = Number(tax4.amountPercent);
            //     taxObj.taxAmount = Number(tax4.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            taxObj.taxPercent = Number(tax1.amountPercent);
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            taxObj.taxName = tax1.taxName;
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount))
            totalTax = totalTax + taxObj.taxAmount;
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });

    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, soDetail, props) => {
    if (!soDetail) return isColumnEmpty;
    for (let i in soDetail.customerSalesOrderDetailsList) {
        let row = soDetail.customerSalesOrderDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
       // if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
       // if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
      //  if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
       // if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}

export default SODetails;
