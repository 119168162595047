import { Button, Card, Col, Row, Switch, Upload } from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import storeDef from "../../../../../assets/images/store-def.jpg";
import Tabbar from "../../../../../assets/images/tabbar.svg";
import FavIcon from "../../../../../assets/images/favicon.ico";
import { getAWSFileURL, getPermissionData } from "../../../../../utils";
import config from '../../../../../config/environmentConfig'
import { MODULE_CODED_VALUES } from "../../../../../static/constants";
const HeaderFaviconComponent = (props) => {
      const {permissions} = props
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.E_STORE_APPEARANCE);
  },[])
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [favFileList, setfaveFileList] = useState([]);

console.log(permissionData ,"Check")
    const image = getAWSFileURL(props.storeData?.headerLogo ,props.companyInfo.bucketName , props?.storeData?.folderName)
    const image1 = getAWSFileURL(props.storeData?.lightBgLogo ,props.companyInfo.bucketName ,props?.storeData?.folderName)
    const favicon_image = getAWSFileURL(props.storeData?.faviconIcon ,props.companyInfo.bucketName ,props?.storeData?.folderName)
  const handleRemove = () => {
    setFileList([]);
  };
  const handleRemove1 = () => {
    setFileList([]);
  };
  const handleChange = (info) => {
    if (info.file.name) {
      // Call your function with the uploaded file
      const payload = {
        relationshipId: props.relationshipId,
        file: info.file,
        bucketName: props?.companyInfo?.bucketName,
      };
      props.updateoraddThemeHeaderLogo(payload);
    } else {
    }
  };
  const handleChange1 = (info) => {
    if (info.file.name) {
      // Call your function with the uploaded file
      const payload = {
        relationshipId: props.relationshipId,
        file: info.file,
        bucketName: props?.companyInfo?.bucketName,
      };
      
      props.updateoraddThemeHeaderLogo1(payload);
    } else {
    }
  };
  const UploadImage = (info) => {
    if (info.file.name) {
      // Call your function with the uploaded file
      const payload = {
        relationshipId: props.relationshipId,
        file: info.file,
        bucketName: props?.companyInfo?.bucketName,
      };

      props.updateoraddThemeStoreFavicon(payload);
    } else {
    }
  };
  const onChangeCheck = (checked) => {};

  const handleAddBanner = () => {};

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col span="24">
          <Card className="common-card">
            <div className="inner-card-content">
              <h5 className="card-heading">Store logo</h5>
              <Row>
                <Col span={12}>
                <h5 className="">Dark background logo</h5>
                <div className="upload-profile-img">
                {fileList.length > 0 ? (
                  <Card className="card-img-prev">
                    <img src={URL.createObjectURL(fileList[0])} alt="Preview" />
                    <Button className="close-btn-pre" onClick={handleRemove}>
                      <CloseOutlined />
                    </Button>
                  </Card>
                ) : (
                  <Card
                    className="card-img-prev"
                    cover={<img src={image?image:storeDef} alt="Placeholder" />}
                  />
                )}
                <Upload
                  fileList={fileList}
                  name="file"
                
                  onChange={handleChange}
                  beforeUpload={(file) => {
                    // Allow only a single file upload, you can adjust this as needed
                    setFileList([file]);
                    return false;
                  }}
                >
                  <Button disabled={!(permissionData.create || permissionData.update)}  icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
              </div>
                </Col>
                <Col span={12}>
                <h5 className="">Light background logo</h5>
                <div className="upload-profile-img">
                {fileList1.length > 0 ? (
                  <Card className="card-img-prev">
                    <img src={URL.createObjectURL(fileList1[0])} alt="Preview" />
                    <Button className="close-btn-pre" onClick={handleRemove1}>
                      <CloseOutlined />
                    </Button>
                  </Card>
                ) : (
                  <Card
                    className="card-img-prev"
                    cover={<img src={image1?image1:storeDef} alt="Placeholder" />}
                  />
                )}
                <Upload
                  fileList={fileList1}
                  name="file"
                 
                  onChange={handleChange1}
                  beforeUpload={(file) => {
                    // Allow only a single file upload, you can adjust this as needed
                    setFileList1([file]);
                    return false;
                  }}
                >
                  <Button disabled={!(permissionData.create || permissionData.update)} icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
              </div>
                </Col>
              </Row>
             
            </div>
          </Card>
        </Col>

        <Col span="24">
          <Card className="common-card">
            <div className="store-name-container">
              <div>
                <h5 className="card-heading">Store logo</h5>
                <p>
                  Show store name alongside the store logo in the navigation bar
                </p>
              </div>

              <div>
                <Switch defaultChecked onChange={onChangeCheck} />
              </div>
            </div>
          </Card>
        </Col>

        <Col span="24">
          <Card className="common-card">
            <h5 className="card-heading">Favicon</h5>
            <p>Favicon should be a square and atleast 48px*48px.</p>

            <div className="faviIcon-wrapper">
              <img src={Tabbar} alt="" />
              <div className="faviIcon-wrapper-upload">
                <div className="faviIcon-show-icon">
                  {favFileList.length > 0 ? (
                    <img
                      className="fav-icon-image"
                      src={URL.createObjectURL(favFileList[0])}
                      alt="Preview"
                    />
                  ) : (
                    <img
                      className="fav-icon-image"
                      src={favicon_image?favicon_image:FavIcon}
                      alt="Fallback"
                    />
                  )}
                  <p>A2Z Furnitures - Online Store</p>
                </div>
                <div className="fav-upload-wrapper">
                  {favFileList.length > 0 ? (
                    <img
                      className="fav-icon-image"
                      src={URL.createObjectURL(favFileList[0])}
                      alt="Preview"
                    />
                  ) : (
                    <img
                      className="fav-icon-image"
                      src={favicon_image?favicon_image:FavIcon}
                      alt="Fallback"
                    />
                  )}
                  <Upload
                    fileList={favFileList}
                    onChange={UploadImage}
                    beforeUpload={(file) => {
                      setfaveFileList([file]);
                      return false;
                    }}
                  >
                    <Button disabled={!(permissionData.create || permissionData.update)} >Change image</Button>
                  </Upload>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div className="update-data">
            <Button type="primary" onClick={handleAddBanner}>
              Update
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default HeaderFaviconComponent;
