import React, { Fragment, useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Skeleton,Menu, Pagination } from 'antd';
import PageBreadcrumb from '../../../../PageBreadcrumb';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { PictureOutlined,PlusOutlined } from '@ant-design/icons';
import { MoreOutlined } from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";
import './index.scss';
import { checkACLPermission, getMomentLoclDateForUIReadOnly, getPermissionData } from '../../../../../utils';
import {
  CONSTANTS, ICONS, MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../../static/constants';
import PageHeader from '../../../../Common/pageHeader';

const UOMListingComp = (props) => {
  const { permissions, allUOM, companyInfo, showModal } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.UOM);
  },[]) 
  // const getRowHeight = (params) => {
  //   let height = 36;
  //   return height;
  // }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const addItemConfirmationModal = (text ,e) => {
    const labelName = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.addUOM({ text: text, relationshipId: companyInfo.relationshipId , id: e.uomId ,version: e.version}, props)
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const newUOMAddition = (e) => {
   
    const formData = {};
    formData.relationshipId = companyInfo.relationshipId;
    formData.selectedValue = e.uomName;
    formData.textBoxValue = e.uomName;
    const payload = { formData: formData };
    const data = {
      title: <FormattedMessage id='addItem.text.uom' defaultMessage='' />,
      formData,
      modalData: { modalType: MODAL_TYPE.UOM, data: {payload , textBoxValue : e.uomName} },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(formData.textBoxValue , e);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      }
    };
    showModal(data);
  }

  const deleteUOM = (payload) => {
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.uomName }}
      />;
    const modalData = {
      modalBody,
      handleSubmit: () => { props.deleteUOM({ id: payload.uomId, relationshipId: companyInfo.relationshipId }) },
    };
    showModal(modalData);
  }

  // const updateUOM = (payload) => {
  //   const modalBody =
  //     <FormattedMessage
  //       id='updateItem.confirmation'
  //       defaultMessage=''
  //       values={{ value: payload.uomName }}
  //     />;
  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => { props.updateUOM(payload) },
  //   };
  //   showModal(modalData);
  // }

 
  // const columnDefs = [
  //   {
  //     headerName: "#",
  //     width: 70,
  //     resizable: true,
  //     cellRendererFramework: (link) => <div>
  //       {link.rowIndex + 1}
  //     </div>
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.uom.listing.header.uom' defaultMessage='' />;
  //     },
  //     field: "uomName",
  //     editable: true,
  //     cellEditor: 'customTextEditor',
  //     resizable: true,
  //     cellClass: 'cellInputControl'
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.uom.listing.header.isDefault' defaultMessage='' />;
  //     },
  //     resizable: true,

  //     cellRendererFramework: (link) => <div>
  //       {link.data.isDefault ? 'Yes' : 'No'}
  //     </div>,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.uom.listing.header.description' defaultMessage='' />;
  //     },
  //     field: "description",
  //     resizable: true,
  //     editable: true,
  //     cellEditor: 'customTextEditor',
  //     cellRendererFramework: (params) => <div dangerouslySetInnerHTML={{
  //       __html: params.value || '-'
  //     }}>
  //     </div>,
  //     cellClass: 'cellInputControl'
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.uom.listing.header.dateCreated' defaultMessage='' />;
  //     },
  //     resizable: true,
  //     cellRendererFramework: (link) => <div>
  //       {getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format})}
  //     </div>,
  //   }
  // ];

  // if (permittedAction.update || permittedAction.delete) {
  //   columnDefs.push({
  //     pinned: 'right',
  //     width: 120,
  //     suppressNavigable: true,
  //     cellClass: 'lastActionCell',
  //     cellRendererFramework: (params) => <div>
  //       {params.node.data.isToEdit &&
  //         <div className="table-cell-action">
  //           <Link onClick={() => {
  //             updateUOM(params.data);
  //             gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
  //             gridApi.refreshCells({ force: true });
  //           }} >
  //             <i className="fa fa-save" />
  //           </Link>
  //           <Link onClick={() => {
  //             params.api.stopEditing(true);
  //             gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
  //             gridApi.refreshCells({ force: true });
  //           }} >
  //             <i className="fa fa-ban" />
  //           </Link>
  //         </div>
  //       }

  //       {!params.node.data.isToEdit &&
  //         <div className="table-cell-action" >
  //           {
  //             permittedAction.update && (
  //               <Link onClick={() => {
  //                 gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
  //                 gridApi.refreshCells({ force: true });
  //               }}>
  //                 <i className="fa fa-edit" />
  //               </Link>
  //             )
  //           }
  //           {
  //             permittedAction.delete && (
  //               <Link onClick={() => deleteUOM(params.data)}>
  //                 <i className="fa fa-remove" />
  //               </Link>
  //             )
  //           }
  //         </div>}
  //     </div>
  //   });
  // }

  // const onFirstDataRendered = (params) => {
  //   params.api.sizeColumnsToFit();
  // }

  // const agGridStyle = {
  //   height: '100%',
  //   width: '100%'
  // };

  // const onGridReady = (params) => {
  //   gridApi = params.api;
  // }

  const breadCrumbList = [
    {
      name: 'Products',
    },
    {
      name: 'UOM',
    }
  ];
  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />
           {
            permissionsData?.update ? <Menu.Item key="1" value="edit" onClick={()=>{
              //  function need to be added 
              newUOMAddition(e)
                  }}
                >
                 <i className={ICONS.EDIT} />
                  Edit
                </Menu.Item>: ""
           }
          

           { permissionsData.delete ?<Menu.Item key="2" onClick={()=>
            {
              deleteUOM(e)
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>:""}

        </Menu >
    )
  };
  return (
    <Fragment>
      <PageHeader {...props}
        pageName="inventory.extras.uom.listing.header.uom-unit"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={ newUOMAddition}
        moduleCode = {MODULE_CODED_VALUES.UOM}
      
        />
     
      <div className='view-container'>
        {/* <div className="page-title">
        <div><FormattedMessage id='inventory.extras.uom.listing.header.uom' defaultMessage='' /></div>
          <div className="modal-sub-header"><FormattedMessage id='inventory.extras.uom.listing.subHeader.text' defaultMessage='' /></div>
        </div> */}
        {/* <div className="action-dropdown-btn pull-right">
          {
            permittedAction.create
              ? (
                <button className="right-border" onClick={newUOMAddition}>
                  <i className="fa fa-user" />
                 <FormattedMessage id='inventory.extras.uom.listing.header.newUOM' defaultMessage='' />
                </button>
              )
              : <Fragment />
          }
        </div> */}

        <Skeleton loading={props.isLoading}
          active
          paragraph={{ rows: 12 }}
        >
           <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='inventory.extras.uom.listing.header.uom-list' defaultMessage='' />
                        </div>
                        {
                          (allUOM || []).length ? <>
                              <div className="vertical-sep" />
                              <div>{allUOM.length}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions"></div>
                </div>
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                        <th><FormattedMessage id='inventory.extras.uom.listing.header.column.uom' defaultMessage=''/></th>
                        <th><FormattedMessage id='inventory.extras.uom.listing.header.isDefault' defaultMessage=''/></th>
                        {/* <th><FormattedMessage id='inventory.extras.uom.listing.header.description' defaultMessage=''/></th> */}
                        <th><FormattedMessage id='inventory.extras.uom.listing.header.dateCreated' defaultMessage=''/></th>
                        {
                          (permissionsData?.delete || permissionsData?.update ) ?   <th><FormattedMessage id='common.listing.header.action' defaultMessage=''/></th>: ""
                        }
                      
                      </tr>
                    </thead>

                    <tbody>
                      {
                        (allUOM || []).length && allUOM.map((rowData,index)=>{
                         return (<tr key={index}>
                        <td>{index+1}</td>
                        <td>{rowData.uomName}</td>
                        <td><div>
        {rowData.isDefault ? 'Yes' : 'No'}
      </div>
      </td>
                        {/* <td><div dangerouslySetInnerHTML={{
        __html: rowData.description || '-'
      }}>
      </div></td> */}
                        <td>{rowData.dateCreated ? <div>
        {getMomentLoclDateForUIReadOnly({date: rowData.dateCreated,  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMA})}
      </div> : "-"}</td>

      {
        (permissionsData?.delete || permissionsData?.update ) ? <td style={{paddingLeft: '50px'}}> 
        <div className="action-icon">
          <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
            <MoreOutlined />
          </DropdownAction>
        </div>
      </td>: ""
      }
                        
                      </tr>)
                        })
                      }
                    </tbody>
                  </table>
                </div>
          {/* <div className='agGridWrapper d-flex w-100 pt15'>
            <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={allUOM || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                getRowHeight={getRowHeight}
                onGridSizeChanged={onFirstDataRendered}
                editType={'fullRow'}
              >
              </CustomAgGrid>
            </div>
          </div> */}
        </Skeleton>
        <div className="footer">
            <div className="f-left"></div>

            <div className="f-right">
            <Pagination
                size="small"
                total={props.allUOM.length}
                showTotal={showTotal}
                defaultPageSize={100}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                 onChange={(pageNumber, pageSize) => {
                  // loadPageData(pageNumber, pageSize);
                  // updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  // handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
      </div>
    </Fragment>

  );
};

export default injectIntl(UOMListingComp);
