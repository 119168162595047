import React, { Fragment } from "react";
// import { Form } from '@ant-design/compatible';
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip,Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import * as filter from "lodash.filter";
import * as find from "lodash.find";

import { Dropdown } from "../../../general/Dropdown";
import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";
// import config from '../../../../config/environmentConfig';
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../static/constants";
import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import { showToasterMessage, getMomentDateForUI, addDaysToDate, getPermissionData } from "../../../../utils";
// import LoadingIcon from '../../../../assets/images/loading.gif';
// import { LogoComponent } from '../../../general/LogoComponent';
import { TextBox } from "../../../general/TextBox";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class SoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
    };
  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS &&
      (!formData || !formData.customerId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Customer",
      });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = {
        addressType: "relationship",
        locationType: "RelationshipBillingAddress",
      };
    }
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (
        address.locationType === "BillingAddress" ||
        address.locationType === "ShippingAddress"
      ) {
        props.fetchAddresses({
          customerId: address.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType,
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.customer && props.customer.customerTaxIdentificationsList) {
        let list = props.customer.customerTaxIdentificationsList || [];
        list.push(taxIdentification);
        props.updateState({
          customer: { ...props.customer, customerTaxIdentificationsList: list },
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewEmployeeAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.employee" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYEE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
        props.fetchAllContacts({
          customerId: contact.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.customerName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.project" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  handleNewPaymentTermAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, hideModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
      handleSubmit: (formData = {}) => {
        if (
          formData.termName &&
          formData.termName.length &&
          formData.days !== 0 &&
          formData.days
        ) {
          hideModal();
          formData.relationshipId = (props.companyInfo || {}).relationshipId;
          props.addPaymentTerm(formData);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.SO_SOURCE: {
        addFunc = props.addSalesOrderSources;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_ORDER];
        break;
      }
      case MODAL_TYPE.INCOTERM: {
        addFunc = props.addIncoterm;
        payload.txType = CONSTANTS_TRANSACTIONS.INCOTERM;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = (
          <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.SO_SOURCE: {
        title = (
          <FormattedMessage id="addItem.text.soSource" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{this.props.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.SHIPPING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.shippingAddress"
              defaultMessage=""
            />
            <h6>{this.props.customerName || ""} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.taxIdentification"
              defaultMessage=""
            />
          </div>
        );
        break;
      }
      case MODAL_TYPE.INCOTERM: {
        title = (
          <div>
            <FormattedMessage id="addItem.text.incoterm" defaultMessage="" />
          </div>
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {
      if (modalType === MODAL_TYPE.CONTACT && props.customer.customerId) {
        props.fetchAllContacts({
          customerId: props.customer.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
      if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.SO_SOURCE: {
        deleteFunc = props.deleteSalesOrderSources;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        deleteFunc = props.deleteEmployee;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        deleteFunc = props.deleteRelationshipTaxIdentifications;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
        deleteFunc = props.deleteCustomerTaxIdentifications;
        break;
      }
      case MODAL_TYPE.INCOTERM: {
        deleteFunc = props.deleteIncoterm;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          customerId: (props.customer || {}).customerId,
          callback,
        });
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
        if (
          modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER &&
          props.customer &&
          props.customer.customerTaxIdentificationsList
        ) {
          let list = (
            props.customer.customerTaxIdentificationsList || []
          ).filter((x) => x.customerTaxIdentificationId !== payload.id);
          props.updateState({
            customer: {
              ...props.customer,
              customerTaxIdentificationsList: list,
            },
          });
        }
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_ORDER],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };

  render() {
  
    const { size } = this.state;
    const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
    const { companyInfo ,permissions} = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const supplierAddressPermissions = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SUPPLIER_ADDRESS);
    const customerContactPermissions =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_CONTACTS)
    const customerShippingAddress = getPermissionData(primaryPerm , MODULE_CODED_VALUES.CUSTOMER_ADDRESS)
    const customerBillingAddress = getPermissionData(primaryPerm , MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS)
   
    if (rowNode) {
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD
      ] = (this.props.shippingAddress || {}).streetAddress1 || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD
      ] = (this.props.billingAddress || {}).streetAddress1 || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PLACE_OF_SUPPLY_FIELD
      ] = this.props.placeOfSupply || "";
      // rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD, (this.props.paymentTermName || ''));
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD
      ] = this.props.customerPO;
      rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SOURCE] =
        this.props.soSourceName;
      rowNode.data.soSourceName = this.props.soSourceName || "";
      rowNode.data.customerInquiryNumber =
        this.props.customerInquiryNumber || "";
      rowNode.data.customerRFQNumber = this.props.customerRFQNumber || "";
      rowNode.data.customerPO = this.props.customerPO || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.REFERENCE_NUMBER
      ] = this.props.referenceNumber;
      this.gridApi.refreshCells({ force: true });
      this.gridApi.sizeColumnsToFit();
      // rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRIORITY, this.props.priority);
      // rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SALES_PERSON, this.props.salesPersonName);
    }

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.placeOfSupply' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PLACE_OF_SUPPLY_FIELD,
    //     resizable: true,
    //     hide: (this.props.companyInfo || {}).countryName !== 'India',
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     pinned: 'left',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.states || [],
    //         optionKeyName: 'stateId',
    //         valueKeyName: 'stateName',
    //         onSelect: (selectedValue) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //           this.props.updateState({ placeOfSupply: selectedValue });
    //         },
    //         // allowClear: true,
    //         // onClear: () => {
    //         //   //obj.node.setDataValue(obj.colDef.field, undefined);
    //         //   this.props.updateState({
    //         //     placeOfSupply: undefined
    //         //   });
    //         // },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.soSource' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SOURCE,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.sources || [],
    //         optionKeyName: 'soSourceId',
    //         valueKeyName: 'soSourceName',
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.SO_SOURCE, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             textBoxValue: payload.textEntered,
    //             submittedOnce: false
    //           }
    //           this.handleNewItemAddition(this.props, payload, MODAL_TYPE.SO_SOURCE)
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //           this.props.updateState({ soSourceName: selectedValue, soSourceId: optionObj.key });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             soSourceName: undefined,
    //             soSourceId: undefined
    //           });
    //         },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.customerPO' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD,
    //     resizable: true,
    //     editable: true, cellEditor: 'customTextEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           //obj.node.setDataValue(obj.colDef.field, value);
    //           this.props.updateState({ customerPO: value });
    //         },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.txDetails.inquiry.customerInquiryNumber' defaultMessage='' />;
    //     },
    //     field: 'customerInquiryNumber',
    //     hide: this.props.customerInquiryNumber ? false : true,
    //     resizable: true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesQuote.form.gridHeader.customerRFQ' defaultMessage='' />;
    //     },
    //     field: 'customerRFQNumber',
    //     hide: this.props.customerRFQNumber ? false : true,
    //     resizable: true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <div>
    //         <FormattedMessage id='customer.salesOrder.form.gridHeader.refNumber' defaultMessage='' />
    //         <Tooltip placement="top" title={this.props.intl.formatMessage(
    //           {
    //             id: 'tooltip.referenceNumberAssign',
    //             defaultMessage: ''
    //           }
    //         )} trigger="click">
    //           <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //         </Tooltip>
    //       </div>
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.REFERENCE_NUMBER,
    //     editable: true,
    //     cellEditor: 'customTextEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           //obj.node.setDataValue(obj.colDef.field, value);
    //           this.props.updateState({ referenceNumber: value });
    //         },
    //       }
    //     }
    //   },
    //   // {
    //   //   headerComponentFramework: () => {
    //   //     return <FormattedMessage id='customer.salesOrder.form.gridHeader.salesPerson' defaultMessage='' />;
    //   //   },
    //   //   field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.SALES_PERSON,
    //   //   editable: true,
    //   //   cellEditor: 'customDropDownEditor',
    //   //   cellEditorParams: (obj) => {
    //   //     return {
    //   //       lastColumnCell: false,
    //   //       items: this.props.employees || [],
    //   //       optionKeyName: 'relationshipEmployeeId',
    //   //       valueKeyName: 'firstName',
    //   //       lastNameKey:'lastName',
    //   //       canAddNew: true,
    //   //       canDelete: true,
    //   //       deleteClickHandler: (payload) => {
    //   //         this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
    //   //       },
    //   //       onAddNewClick: (payload) => {
    //   //         // payload.formData = {
    //   //         //   firstName: payload.textEntered,
    //   //         //   submittedOnce: false
    //   //         // }
    //   //         // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
    //   //         this.props.updateHeaderState({
    //   //           company: {
    //   //               ...this.props.company,
    //   //               employeeDrawerVisible: true,
    //   //           }
    //   //       })
    //   //       },
    //   //       onSelect: (selectedValue, option) => {
    //   //         //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //   //         this.props.updateState({
    //   //           salesPersonId: option.key, salesPersonName: selectedValue
    //   //         });
    //   //       },
    //   //       allowClear: true,
    //   //       onClear: () => {
    //   //         //obj.node.setDataValue(obj.colDef.field, undefined);
    //   //         this.props.updateState({
    //   //           salesPersonId: undefined,
    //   //           salesPersonName: undefined
    //   //         });
    //   //       },
    //   //     }
    //   //   }
    //   // },
    //   // {
    //   //   headerComponentFramework: () => {
    //   //     return <FormattedMessage id='customer.salesOrder.form.gridHeader.priority' defaultMessage='' />;
    //   //   },
    //   //   field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRIORITY,
    //   //   editable: true,
    //   //   cellEditor: 'customDropDownEditor',
    //   //   cellEditorParams: (obj) => {
    //   //     return {
    //   //       lastColumnCell: false,
    //   //       items: ['Low', 'Medium', 'Urgent'],
    //   //       onSelect: (selectedValue) => {
    //   //         //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //   //         this.props.updateState({ priority: selectedValue });
    //   //       },
    //   //       allowClear: true,
    //   //       onClear: () => {
    //   //         //obj.node.setDataValue(obj.colDef.field, undefined);
    //   //         this.props.updateState({
    //   //           priority: undefined
    //   //         });
    //   //       },
    //   //     }
    //   //   }
    //   // },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.billingAddress' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       const allBillingAddresses = this.props.allBillingAddress;
    //       return {
    //         //TODO need to use the variable declared above
    //         lastColumnCell: false,
    //         items: allBillingAddresses,
    //         optionKeyName: 'locationId',
    //         valueKeyName: 'streetAddress1',
    //         addressLineKey1: 'streetAddress2',
    //         addressLineKey2: 'cityName',
    //         addressLineKey3: 'stateName',
    //         addressLineKey4: 'zipCode',
    //         optionClassAddress: 'custom-address-dropdown',
    //         canAddNew: true,
    //         canDelete: true,
    //         isFavouriteKeyName: 'isDefault',
    //         permissions: this.props.permissions,
    //         moduleName: MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS,
    //         operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.BILLING_ADDRESS,
    //             customerId: (this.props.customer || {}).customerId,
    //             pageNumber: this.props.pageNumber
    //           }
    //           this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           const billingAddress = find(allBillingAddresses, { locationId: Number(optionObj.key) });
    //           this.props.updateState({
    //             billingAddress
    //           })
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             billingAddress: undefined
    //           });
    //         },
    //       }
    //     }
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='customer.salesOrder.form.gridHeader.shippingAddress' defaultMessage='' />;
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       const allShippingAddresses = this.props.allShippingAddress;
    //       return {
    //         //TODO need to use the variable declared above
    //         lastColumnCell: false,
    //         items: allShippingAddresses,
    //         optionKeyName: 'locationId',
    //         valueKeyName: 'streetAddress1',
    //         addressLineKey1: 'streetAddress2',
    //         addressLineKey2: 'cityName',
    //         addressLineKey3: 'stateName',
    //         addressLineKey4: 'zipCode',
    //         isFavouriteKeyName: 'isDefault',
    //         optionClassAddress: 'custom-address-dropdown',
    //         canAddNew: true,
    //         permissions: this.props.permissions,
    //         moduleName: MODULE_CODED_VALUES.CUSTOMER_ADDRESS,
    //         operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.SHIPPING_ADDRESS, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.SHIPPING_ADDRESS,
    //             customerId: (this.props.customer || {}).customerId,
    //             pageNumber: this.props.pageNumber,
    //           }
    //           this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.SHIPPING_ADDRESS)
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           const shippingAddress = find(allShippingAddresses, { locationId: Number(optionObj.key) });
    //           this.props.updateState({
    //             shippingAddress,
    //             placeOfSupply: shippingAddress.stateName
    //           })
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             shippingAddress: undefined
    //           });
    //         },
    //       }
    //     }
    //   },
    //   // {
    //   //   headerComponentFramework: () => {
    //   //     return <FormattedMessage id='customer.salesOrder.form.gridHeader.paymentTerms' defaultMessage='' />;
    //   //   },
    //   //   field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
    //   //   editable: true,
    //   //   pinned: 'right',
    //   //   cellEditor: 'customDropDownEditor',
    //   //   cellEditorParams: (obj) => {
    //   //     return {
    //   //       lastColumnCell: false,
    //   //       items: this.props.paymentTerms,
    //   //       optionKeyName: 'paymentTermId',
    //   //       valueKeyName: 'termName',
    //   //       canAddNew: true,
    //   //       canDelete: true,
    //   //       permissions: this.props.permissions,
    //   //       moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
    //   //       operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //   //       deleteClickHandler: (payload) => {
    //   //         this.deleteClickHandler(this.props, MODAL_TYPE.PAYMENT_TERM, payload);
    //   //       },
    //   //       onAddNewClick: (payload) => {
    //   //         payload.formData = {
    //   //           termName: payload.textEntered,
    //   //           days: null,
    //   //           description: '',
    //   //           isDefaultPayment: false,
    //   //           submittedOnce: false
    //   //         }
    //   //         this.handleNewPaymentTermAddition(this.props, payload, MODAL_TYPE.PAYMENT_TERM)
    //   //       },
    //   //       onSelect: (selectedValue, option) => {
    //   //         //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //   //         this.props.updateState({
    //   //           paymentTermId: option.key, paymentTermName: selectedValue
    //   //         });
    //   //       },
    //   //       allowClear: true,
    //   //       onClear: () => {
    //   //         //obj.node.setDataValue(obj.colDef.field, undefined);
    //   //         this.props.updateState({
    //   //           paymentTermId: undefined,
    //   //           paymentTermName: undefined
    //   //         });
    //   //       },
    //   //     }
    //   //   }
    //   // }
    // ]

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   this.gridColumnApi = params.columnApi;
    // }
  
    return (
      <Fragment>
        <Row>
          {/* org info */}
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {companyInfo.storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  this.props.relationshipBillingAddress ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo,
                            this.props
                          )}
                        </div>
                        <div
                          onClick={() => {
                            this.props.updateState({
                              addressHide: true,
                            });
                          }}
                        >
                          <EditOutlined />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      items={this.props.allRelationshipBillingAddress}
                      value={
                        (this.props.relationshipBillingAddress || {})
                          .streetAddress1
                      }
                      canAddNew={ true }
                      canDelete={ true }
                      visible={false}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      moduleName={MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      onSelect={(value) => {
                        const selectedRelationshipAddress = find(
                          this.props.allRelationshipBillingAddress,
                          { locationId: Number(value) }
                        );
                        this.props.updateState({
                          placeOfSupply: selectedRelationshipAddress.stateName,
                          relationshipBillingAddress:
                            selectedRelationshipAddress,
                          addressHide: false,
                        });
                      }}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
              {/* <Col span={24} className='address-container mt5 mb5'>
                {
                  getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                }
              </Col> */}
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.form.taxIdentification.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.taIdentification",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>

                <MultipleSelectDropdown
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode="multiple"
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props;
                    selectedRelationshipTaxIdentifications = filter(
                      selectedRelationshipTaxIdentifications,
                      (taxId) => {
                        return taxId !== value;
                      }
                    );
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  valueKeyName="taxIdentificationType"
                  valueKeyName2="taxIdentificationNumber"
                  selectedValue={
                    (this.props.allRelationshipTaxIdentificatins || []).length
                      ? this.props.selectedRelationshipTaxIdentifications || []
                      : []
                  }
                  optionKeyName="relationshipTaxIdentificationId"
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications =
                      this.props.selectedRelationshipTaxIdentifications || [];
                    if (
                      selectedRelationshipTaxIdentifications.indexOf(
                        relationshipTaxIdentificationId
                      ) === -1
                    ) {
                      selectedRelationshipTaxIdentifications.push(
                        relationshipTaxIdentificationId
                      );
                    } else {
                      selectedRelationshipTaxIdentifications =
                        selectedRelationshipTaxIdentifications.filter((el) => {
                          return el !== selectedRelationshipTaxIdentifications;
                        });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewTaxIdentificationAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION
                    );
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>

          <Col span={1}></Col>

          {/* customer info */}

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Customer Information
              </Col>
              {this.props.customerName ? (
                <>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.salesOrder.form.customer.label"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="deatils-field-value">
                      {this.props.customerName}
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="contact" defaultMessage="" />
                      <Tooltip
                        placement="right"
                        title={this.props.intl.formatMessage({
                          id: "tooltip.customerContact",
                          defaultMessage: "",
                        })}
                        trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        ((this.props.selectedContact || {}).firstName || "") +
                        " " +
                        ((this.props.selectedContact || {}).middleName || "") +
                        " " +
                        ((this.props.selectedContact || {}).lastName || "")
                      }
                      items={this.props.boContactList}
                      valueKeyName="firstName"
                      lastNameKey="lastName"
                      optionKeyName="contactId"
                      placeholder="Please select contact name"
                      canAddNew={  true }
                      canDelete={ true}
                      moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}
                      isFavouriteKeyName="isPrimaryContact"
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.CONTACT,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          customerId: (this.props.customer || {}).customerId,
                          pageNumber: this.props.pageNumber || 1,
                          customerName: this.props.customerName,
                        };
                        this.handleNewContactAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.CONTACT
                        );
                      }}
                      onSelect={(contactId) => {
                        let contact =
                          find(this.props.boContactList || [], {
                            contactId: +contactId,
                          }) || {};
                        this.props.updateState({
                          selectedContact: contact,
                        });
                      }}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          selectedContact: undefined,
                        });
                      }}
                    />

                    {/* {
                          getAddressInfoFragment({}, this.props.selectedContact || {}, this.props.customer)
                        } */}
                  </Col>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.form.taxIdentification.label"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={this.props.intl.formatMessage({
                          id: "taxIdentification.tooltip",
                          defaultMessage: "",
                        })}
                        trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24}>
                    <MultipleSelectDropdown
                      style={{ width: "100%" }}
                      items={
                        this.props.customer.customerTaxIdentificationsList || []
                      }
                      placeholder="Tax Identification(s)"
                      mode="multiple"
                      onDeSelect={(value) => {
                        let { selectedCustomerTaxIdentifications } = this.props;
                        selectedCustomerTaxIdentifications = filter(
                          selectedCustomerTaxIdentifications,
                          (taxId) => {
                            return taxId !== value;
                          }
                        );
                        this.props.updateState({
                          selectedCustomerTaxIdentifications,
                        });
                      }}
                      valueKeyName="taxIdentificationType"
                      valueKeyName2="taxIdentificationNumber"
                      selectedValue={
                        (
                          this.props.customer.customerTaxIdentificationsList ||
                          []
                        ).length
                          ? this.props.selectedCustomerTaxIdentifications || []
                          : []
                      }
                      optionKeyName="customerTaxIdentificationId"
                      onSelect={(customerTaxIdentificationId) => {
                        let selectedCustomerTaxIdentifications =
                          this.props.selectedCustomerTaxIdentifications || [];
                        if (
                          selectedCustomerTaxIdentifications.indexOf(
                            customerTaxIdentificationId
                          ) === -1
                        ) {
                          selectedCustomerTaxIdentifications.push(
                            customerTaxIdentificationId
                          );
                        } else {
                          selectedCustomerTaxIdentifications =
                            selectedCustomerTaxIdentifications.filter((el) => {
                              return el !== customerTaxIdentificationId;
                            });
                        }
                        this.props.updateState({
                          selectedCustomerTaxIdentifications,
                        });
                      }}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          customerId: (this.props.customer || {}).customerId,
                          pageNumber: this.props.pageNumber || 1,
                          customerName: this.props.customerName,
                        };
                        this.handleNewTaxIdentificationAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION
                        );
                      }}
                    />
                  </Col>
                </>
              ) : (
                "Please select customer for details"
              )}
            </Row>
            </Skeleton>
          </Col>
          <Col span={1}></Col>

          {/* Delivery info */}
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Delivery Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.deliveryDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${
                    this.props.deliveryDate
                      ? getMomentDateForUI({
                          date: new Date(this.props.deliveryDate),
                          format,
                        })
                      : getMomentDateForUI({ date: new Date(), format })
                  }`}
                  defaultValue={
                    this.props.deliveryDate
                      ? getMomentDateForUI({
                          date: new Date(this.props.deliveryDate),
                          format,
                        })
                      : getMomentDateForUI({ date: new Date(), format })
                  }
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      deliveryDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.gridHeader.paymentTerms"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  items={this.props.paymentTerms}
                  optionKeyName="paymentTermId"
                  valueKeyName="termName"
                  isLockKeyName="isSystemGenerated"
                  //isFavouriteKeyName='isDefault'
                  value={this.props.paymentTermName || ""}
                  canAddNew={true}
                  canDelete={true}
                  //permissions={this.props.permissions}
                  //moduleName={[MODULE_CODED_VALUES.PAYMENT_TERM]}
                  //operations={[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.PAYMENT_TERM,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      termName: payload.textEntered,
                      days: null,
                      description: "",
                      isDefaultPayment: false,
                      submittedOnce: false,
                    };
                    this.handleNewPaymentTermAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.PAYMENT_TERM
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    const paymentTerm = find(this.props.paymentTerms,{paymentTermId:option.value})
                    this.props.updateState({
                      paymentTermId: option.key,
                      paymentTermName: option.name,
                      invoiceDueDate: paymentTerm.numberOfDays ? addDaysToDate((new Date()), paymentTerm.numberOfDays) : null,
                      deliveryDate: paymentTerm.numberOfDays ? addDaysToDate((new Date()), paymentTerm.numberOfDays) : null,
                    });
                  }}
                ></Dropdown>
              </Col>
              { (this.props.companyInfo || {}).countryName ==="India" ?
              <><Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.placeOfSupply"
                        defaultMessage="" />
                    </div>
                  </Col><Col span={24}>
                      <Dropdown
                        items={this.props.states
                          ? this.props.states
                            .slice()
                            .sort((a, b) => a.stateName.localeCompare(b.stateName)
                            )
                          : []}
                        optionKeyName="stateId"
                        valueKeyName="stateName"
                        value={this.props.placeOfSupply}
                        onSelect={(selectedValue, obj) => {
                          this.props.updateState({ placeOfSupply: obj.name });
                        } } />
                    </Col></>
:""}
              <Col span={24}>
                <FormattedMessage
                  id="customer.salesOrder.form.gridHeader.incoterm"
                  defaultMessage=""
                />
                <Tooltip
                  placement="left"
                  title={this.props.intl.formatMessage({
                    id: "tooltip.internationalCommercial",
                    defaultMessage: "",
                  })}
                >
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
              <Col span={24}>
                <Dropdown
                  items={this.props.incoterms || []}
                  optionKeyName={"inctermId"}
                  valueKeyName={"incotermName"}
                  defaultValue={
                    (this.props.incoterms || []).length
                      ? this.props.incotermName
                      : ""
                  }
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.INCOTERM,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.INCOTERM
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      inctermId: option.key,
                      incotermName: option.name,
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      inctermId: undefined,
                      incotermName: undefined,
                    });
                  }}
                ></Dropdown>
              </Col>

              {/* <Col span={24}>
                <FormattedMessage id='customer.salesOrder.form.gridHeader.priority' defaultMessage='' />
              </Col> */}

              {/* <Col span={24}>
                <Dropdown
                  items={['Low', 'Medium', 'Urgent']}
                  defaultValue={this.props.priority}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      priority: selectedValue
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      priority: undefined
                    });
                  }
                  }>
                  
                </Dropdown>
              </Col>

              <Col span={24} >
                <FormattedMessage id='customer.salesOrder.form.gridHeader.salesPerson' defaultMessage='' />
              </Col>
              <Col span={24}>
                <Dropdown
                  items={this.props.employees || []}
                  optionKeyName={'relationshipEmployeeId'}
                  defaultValue={(this.props.employees || []).length ? (this.props.salesPersonName || this.props.salesPersonId || null) : ""}
                  valueKeyName={'firstName'}
                  lastNameKey={'lastName'}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
                  }}
                  onAddNewClick={(payload) => {
                    this.props.updateHeaderState({
                      company: {
                        ...this.props.company,
                        employeeDrawerVisible: true,
                      }
                    })
                  }}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      salesPersonId: option.key, salesPersonName: option.name
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      salesPersonId: undefined,
                      salesPersonName: undefined
                    });
                  }
                  }></Dropdown>
              </Col>

              <Col span={24} >
                <FormattedMessage id='customer.salesOrder.form.projectDropdown.label' defaultMessage='' />
              </Col>

              <Col span={24}>
                <Dropdown
                  items={this.props.projectList}
                  valueKeyName='projectName'
                  value={this.props.projectName}
                  optionKeyName='projectMasterId'
                  projectIdKey='projectMasterId'
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      projectName: payload.projectName,
                    }
                    this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                  }}
                  onSelect={(projectMasterId, option) => {
                    const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key) }) || {};
                    this.props.updateState({
                      projectMasterId: selectedProject.projectMasterId,
                      projectName: selectedProject.projectName,
                      projectNumber: selectedProject.projectNumber
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      projectMasterId: undefined,
                      projectName: undefined,
                      projectNumber: undefined
                    })
                  }}
                />
              </Col> */}
            </Row>
            </Skeleton>
          </Col>
          <Col span={1}></Col>

          <Col span={6} className="order-value-info">
            {/* <Row>
              <Col span={24}>
                <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                  {
                    id: 'tooltip.generateSONumber',
                    defaultMessage: ''
                  } 
                )}>
                  <span className="tooltip-title">
                    <FormattedMessage id='customer.salesOrder.form.soNumber.label' defaultMessage='' />
                  </span>
                </Tooltip>
              </Col>
              <Col span={24}>
                {this.props.nextTxNumber ?
                  <span>{getFormatedNextTxNumber(this.props)}</span>
                  //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
                  :
                  <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'margin-top': '-25px', 'margin-bottom': '-25px' }} />
                }
              </Col>
            </Row> */}
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Order Information
              </Col>
              {this.props.quotationNumber && (
                <>
                  <Col span={12}>
                    <FormattedMessage
                      id="customer.salesOrder.form.quotationNumber.label"
                      defaultMessage=""
                    />
                  </Col>
                  <Col span={12}>{this.props.quotationNumber}</Col>
                </>
              )}

              <Col span={24}>
                <FormattedMessage
                  id="customer.salesOrder.form.soDate.label"
                  defaultMessage=""
                />
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${getMomentDateForUI({
                    date: new Date(this.props.salesOrderDate),
                    format,
                  })}`}
                  defaultValue={getMomentDateForUI({
                    date: new Date(this.props.salesOrderDate),
                    format,
                  })}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      salesOrderDate: selectedDate,
                    });
                    // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <FormattedMessage
                  id="customer.salesOrder.form.documentDropdown.label"
                  defaultMessage=""
                />
                <Tooltip
                  placement="left"
                  title={this.props.intl.formatMessage({
                    id: "documentTitle.tooltip",
                    defaultMessage: "",
                  })}
                >
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
              <Col span={24}>
                <Dropdown
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.SALES_ORDER
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName && this.props.documentNameId}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 30,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedObj.documentNameId,
                      docName: selectedObj.docName,
                    });
                  }}
                  // allowClear={true}
                  // onClear={() => {
                  //   this.props.updateState({
                  //     documentNameId: undefined,
                  //     docName: undefined
                  //   })
                  // }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                />
              </Col>
            </Row>
        </Skeleton>
            {this.props.qualityChecklistSetting.isQualityChecklistRequired ? (
              <>
                <Col span={24}>
                  <FormattedMessage
                    id="customer.salesOrder.form.qualityCheck.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="left"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.qualityChekSetting",
                      defaultMessage: "",
                    })}
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </Col>
                <Col span={24} className="documentTitleDropdown">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      this.props.updateState({
                        title: (
                          <div className="drawer-title">Quality Check</div>
                        ),
                        qualityCheckDrawerVisible: true,
                        qualityCheckAction: "add",
                        addQualityCheckToSO: function (qualityCheckList) {
                          this.updateState({
                            qualityCheckList: qualityCheckList,
                          });
                        },
                      });
                    }}
                  >
                    <div className="deatils-field-value">
                      {!this.props.qualityCheckList ||
                      !this.props.qualityCheckList.length
                        ? "Add Quality Check"
                        : this.props.qualityCheckList.length}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>

        {/* <div style={{marginTop: '20px'}}>
          <CustomAgGrid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            disableFocusOnClickOutside={true}
            rowData={[{}]}
            gridStyle={{
              width: '100%',
              height: '100px'
            }} />
        </div> */}

        <Row>
        <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 2 }} active>

          <Col span={24}>
            <div className="txn-table">
              <table>
                <thead>
                  <tr>
                    {/* <th><FormattedMessage id='customer.salesOrder.form.gridHeader.placeOfSupply' defaultMessage='' /></th> */}
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.soSource"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.customerPO"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.refNumber"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.priority"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.salesPerson"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.projectDropdown.label"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.billingAddress"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="customer.salesOrder.form.gridHeader.shippingAddress"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <td>
                      <Dropdown
                        items= {this.props.states || []}
                        optionKeyName= 'stateId'
                        valueKeyName= 'stateName'
                        onSelect={ (selectedValue) => {
                          this.props.updateState({ placeOfSupply: selectedValue });
                        }}
                      
                      />
                    </td> */}
                    <td>
                      <Dropdown
                        items={this.props.sources || []}
                        optionKeyName="soSourceId"
                        valueKeyName="soSourceName"
                       
                        canAddNew={true}
                        value={this.props?.soSourceName}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.SO_SOURCE,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            textBoxValue: payload.textEntered,
                            submittedOnce: false,
                          };
                          this.handleNewItemAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.SO_SOURCE
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          this.props.updateState({
                            soSourceName: optionObj.name,
                            soSourceId: optionObj.key,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            soSourceName: undefined,
                            soSourceId: undefined,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <TextBox
                        value={this.props.customerPO}
                        onChange={(value) => {
                          this.props.updateState({
                            customerPO: value.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <TextBox
                        value={this.props.referenceNumber}
                        onChange={(value) => {
                          this.props.updateState({
                            referenceNumber: value.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        items={["Low", "Medium", "High", "Urgent"]}
                        defaultValue={this.props.priority}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            priority: selectedValue,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            priority: undefined,
                          });
                        }}
                      ></Dropdown>
                    </td>
                    <td>
                      <Dropdown
                        items={this.props.employees || []}
                        optionKeyName={"relationshipEmployeeId"}
                        defaultValue={
                          (this.props.employees || []).length
                            ? this.props.salesPersonName ||
                              this.props.salesPersonId ||
                              null
                            : ""
                        }
                        moduleName={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
                        valueKeyName={"firstName"}
                        lastNameKey={"lastName"}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.EMPLOYEE,
                            payload
                          );
                        }}
                       
                        onAddNewClick={(payload) => {
                          this.props.updateHeaderState({
                            company: {
                              ...this.props.company,
                              employeeDrawerVisible: true,
                            },
                          });
                        }}
                        onSelect={(selectedValue, option) => {
                          this.props.updateState({
                            salesPersonId: option.key,
                            salesPersonName: option.name,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            salesPersonId: undefined,
                            salesPersonName: undefined,
                          });
                        }}
                      ></Dropdown>
                    </td>
                    <td>
                      <Dropdown
                        items={this.props.projectList}
                        valueKeyName="projectName"
                        value={this.props.projectName}
                        optionKeyName="projectMasterId"
                        projectIdKey="projectMasterId"
                        moduleName={MODULE_CODED_VALUES.PROJECT}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.PROJECT,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            projectName: payload.projectName,
                          };
                          this.handleNewProjectAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.PROJECT
                          );
                        }}
                        onSelect={(projectMasterId, option) => {
                          const selectedProject =
                            find(this.props.projectList, {
                              projectMasterId: Number(option.key),
                            }) || {};
                          this.props.updateState({
                            projectMasterId: selectedProject.projectMasterId,
                            projectName: selectedProject.projectName,
                            projectNumber: selectedProject.projectNumber,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            projectMasterId: undefined,
                            projectName: undefined,
                            projectNumber: undefined,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        items={this.props.allBillingAddress}
                        optionKeyName="locationId"
                        value={(this.props.billingAddress || {}).streetAddress1}
                        valueKeyName="streetAddress1"
                        addressLineKey1="streetAddress2"
                        addressLineKey2="cityName"
                        addressLineKey3="stateName"
                        addressLineKey4="zipCode"
                        optionClassAddress="custom-address-dropdown"
                        canAddNew={true}
                        canDelete={true}
                        isFavouriteKeyName="isDefault"
                        permissions={this.props.permissions}
                        moduleName={
                          MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS
                        }
                        
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.BILLING_ADDRESS,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.BILLING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber,
                          };
                          this.handleNewAddressAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.BILLING_ADDRESS
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          const billingAddress = find(
                            this.props.allBillingAddress,
                            { locationId: optionObj.value }
                          );
                          this.props.updateState({
                            billingAddress: billingAddress,
                            placeOfSupply: (this.props.billingAddress || {})
                              .stateName,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            billingAddress: undefined,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        items={this.props.allShippingAddress}
                        optionKeyName="locationId"
                        value={
                          (this.props.shippingAddress || {}).streetAddress1
                        }
                        valueKeyName="streetAddress1"
                        addressLineKey1="streetAddress2"
                        addressLineKey2="cityName"
                        addressLineKey3="stateName"
                        addressLineKey4="zipCode"
                        isFavouriteKeyName="isDefault"
                        optionClassAddress="custom-address-dropdown"
                        canAddNew={true}
                        permissions={this.props.permissions}
                        moduleName={MODULE_CODED_VALUES.CUSTOMER_ADDRESS}
                       
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.SHIPPING_ADDRESS,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType: CONSTANTS.SHIPPING_ADDRESS,
                            customerId: (this.props.customer || {}).customerId,
                            pageNumber: this.props.pageNumber,
                          };
                          this.handleNewAddressAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.SHIPPING_ADDRESS
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          const shippingAddress = find(
                            this.props.allShippingAddress,
                            { locationId: Number(optionObj.key) }
                          );

                          this.props.updateState({
                            shippingAddress,
                            placeOfSupply: shippingAddress.stateName,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            shippingAddress: undefined,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          </Skeleton>
        </Row>
      </Fragment>
    );
  }
}

const getAddressInfoFragment = (
  obj,
  customerContactObj,
  customerObj,
  props
) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }

  let contactName = "";
  if (customerContactObj.salutationName)
    contactName += customerContactObj.salutationName + " ";
  if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  if (customerContactObj.middleName)
    contactName += " " + customerContactObj.middleName;
  if (customerContactObj.lastName)
    contactName += " " + customerContactObj.lastName;
  const fragmentObj = (
    <Fragment>
      {/* {obj.streetAddress1 ? (
        <div className="billing-address">{obj.streetAddress1}</div>
      ) : (
        ""
      )}
      {obj.streetAddress2 ? (
        <div className="billing-address">{obj.streetAddress2}</div>
      ) : (
        ""
      )}
      {obj.cityName ? (
        <div className="billing-address">
          {`${obj.cityName || ""}${obj.stateName ? "," + obj.stateName : ""}${
            obj.zipCode ? "," + obj.zipCode : ""
          }`}
        </div>
      ) : (
        ""
      )} */}
      <div className="billing-address first-caps">
    {obj.streetAddress1 || obj.streetAddress2 ? (    
        <div>
          {obj.streetAddress1} {obj.streetAddress2}{","}
        </div>    
    ) : ""}
    {obj.cityName || obj.stateName || obj.zipCode ? (      
        <div>
          {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
        </div>      
    ) : ""}
    {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
  </div>
      {customerContactObj.firstName ? (
        <div className="billing-address">{contactName}</div>
      ) : (
        ""
      )}
      {customerContactObj.workPhone ? (
        <div className="billing-address">
          <FormattedMessage
            id="customer.salesOrder.form.phone.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.workPhone}
        </div>
      ) : (
        ""
      )}
      {customerContactObj.email ? (
        <div className="billing-address">
          <FormattedMessage
            id="customer.salesOrder.form.email.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.email}
        </div>
      ) : (
        ""
      )}
      {/* {
      customerObj.gstNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
        </div> :
        ''
    }
    {
      customerObj.panNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
        </div> :
        ''
    } */}
    </Fragment>
  );

  return fragmentObj;
};

// const getFormatedNextTxNumber = (props) => {
//   // const { customer } = props;
//   // if (!customer || !customer.customerId) {
//   //   return <span>{props.nextTxNumber}</span>;
//   // }
//   // return <span>{props.nextTxNumber.replace('SO-00', 'SO-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
//   return <span>{props.nextTxNumber}</span>;
// }

export default injectIntl(SoDetails);
