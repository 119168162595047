import React from "react";
import { Button, Col, Row, Skeleton, Radio } from 'antd';
import './style.scss';
import { CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage, } from '../../../../../utils';
import * as find from 'lodash.find';
import moment from "moment-timezone";
import { Dropdown } from "../../../../general/Dropdown";

const ShippingOrderInfo = (props) => {

    const { shiprocketCarrierDetails={}, soDetail={} } = props;
    const {txData={}} = props.data;

    const { data: { available_courier_companies = [] } = {} } = shiprocketCarrierDetails || {};


    const getRatesForCarriers = (orderId) =>{
        const {deliveyNotesData} = props;
        const relationshipAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
        const shippingAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};

        const payloadShipRocket = {
          relationshipId: props.companyInfo?.relationshipId || 0,
          pickup_postcode: relationshipAddress.zipCode,
          delivery_postcode: shippingAddress.zipCode,
          cod: 0,
          weight: deliveyNotesData.weight,
          length: deliveyNotesData.length,
          breadth: deliveyNotesData.width,
          height: deliveyNotesData.height,
          order_id: orderId,
        }

        props.getCarrierPricesShiprocket(payloadShipRocket);
    }

    return (
        <>
            <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        Select Channel
                    </Col>
                    <Col span={12}>
                        <Dropdown
                            items={props.shiprocketIntgratedChannels || []}
                            valueKeyName='name'
                            optionKeyName='id'
                            value={''}
                            placeholder='Select Channel'
                            onSelect={(optionValue, option) => {
                                let selectedChannel = find(props.shiprocketIntgratedChannels || [], { channel_id: option.value }) || {};
                                props.updateState({
                                    selected: selectedChannel,
                                })
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        Pickup Address<span className="required">*</span>
                    </Col>
                    <Col span={12}>
                        <Dropdown
                            items={props.shiprocketAccountAddress || []}
                            optionKeyName='pickup_location'
                            valueKeyName='address'
                            valueKeyName2='pickup_location'
                            addressLineKey1='address_2'
                            addressLineKey2='city'
                            addressLineKey3='state'
                            addressLineKey4='pin_code'
                            optionClassAddress='custom-address-dropdown'
                            value={props.selectedAddress || ''}
                            placeholder='Select Pickup Address'
                            onSelect={(optionValue, option) => {
                                props.updateState({
                                    selectedAddress: optionValue,
                                })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                            Payment Method<span className="required">*</span>
                    </Col>
                    <Col span={12}>
                        <Radio.Group 
                            onChange={(e)=>{
                                props.updateState({selectedPaymentMethod: e.target.value})
                            }} 
                            value={props.selectedPaymentMethod  || 'Prepaid'}
                        >
                            <Radio value={'Prepaid'}>Prepaid</Radio>
                            <Radio value={'COD'}>COD(Cash On Delivery)</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </Skeleton>

            <div className="fot-fix" style={{ maxWidth: '100%' }}>
                <div className="text-center">
                    <Button 
                        loading={props.saveLoading}
                        type="primary"
                        onClick={()=>{
                            if(!props.selectedAddress){
                                return showToasterMessage({ description: 'Please select pickup address' });
                            }
                            const billingAddress = props.deliveyNotesData?.boLocationShipmentList?.find(obj=>obj.locationType === CONSTANTS.BILLING_ADDRESS)
                            const payload = {
                                order_id: props.deliveyNotesData.shipmentMasterId,
                                order_date: moment().format(CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT),
                                pickup_location: props.selectedAddress,
                                comment: "",
                                billing_customer_name: txData.customerName,
                                billing_last_name: "",
                                billing_address: billingAddress?.streetAddress1,
                                billing_address_2: billingAddress?.streetAddress2,
                                billing_city: billingAddress?.cityName,
                                billing_pincode: billingAddress?.zipCode,
                                billing_state: billingAddress?.stateName,
                                billing_country: billingAddress?.countryName,
                                billing_email: "vivek.gupta@alpide.com",
                                billing_phone: "7355745541",
                                shipping_is_billing: 1,
                                // shipping_customer_name: "John",
                                // shipping_last_name: "Doe",
                                // shipping_address: "123 MG Road",
                                // shipping_address_2: "Apt 4B",
                                // shipping_city: "Bengaluru",
                                // shipping_pincode: "227817",
                                // shipping_country: "India",
                                // shipping_state: "KA",
                                // shipping_email: "john.doe@example.com",
                                // shipping_phone: "9876543210",
                                order_items: soDetail?.customerSalesOrderDetailsList.map(
                                    (product)=> {
                                        return {
                                            name: `${product.itemName} - ${product.itemVariantName}`,
                                            sku: product.sku || '',
                                            units: product.quantity || 0,
                                            selling_price: product.anItemSalePrice || 0,
                                            discount: 0,
                                            tax: "18.00",
                                            hsn: "123456"
                                        }
                                    }),
                                payment_method: props.selectedPaymentMethod || 'Prepaid',
                                shipping_charges: "0.00",
                                giftwrap_charges: "0.00",
                                transaction_charges: "0.00",
                                total_discount: "0.00",
                                sub_total: "0.00",
                                length: props.deliveyNotesData?.length,
                                breadth: props.deliveyNotesData?.width,
                                height: props.deliveyNotesData?.height,
                                weight: props.deliveyNotesData?.weight
                            }

                            props.createShiprocketShipmentOrder(payload, props, getRatesForCarriers);
                        }}
                    > 
                        Create Order 
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ShippingOrderInfo;