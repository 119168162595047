import React, { useMemo, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Pagination, Input, Empty, Menu, Checkbox } from "antd";
import {
  capitalizeFirstLetter,
  exportExcel,
  fetchPaginationDataIfNeeded,
  getCurrencySymbol,
  getMomentDateForUIReadOnly,
  getPermissionData,
  showToasterMessage,
  sortTable,
} from "../../../../../utils";
import { Dropdown } from "../../../../general/Dropdown";
import "ag-grid-community/dist/styles/ag-grid.css";
import DropdownAction from "antd/lib/dropdown";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import PageBreadcrumb from "../../../../PageBreadcrumb";
import "./index.scss";
import cloneDeep from "lodash/cloneDeep";
import * as debounce from "lodash.debounce";
import { CONSTANTS,ICONS, MODULE_CODED_VALUES } from "../../../../../static/constants";
import { PlusSquareOutlined, MinusSquareOutlined,FileExcelOutlined ,DownloadOutlined} from "@ant-design/icons";
import CurrentStockDetails from "../../../../modal/modalBody/inventory/StockSummary/CurrentStockDetails";
import find from "lodash.find";


const { Search } = Input;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const onSearchedInputChange = debounce((searchedText, props) => {
  handleSearchedText(searchedText, props);
}, 500);

const handleSearchedText = (searchedText, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    searchedText: searchedText,
    warehouseMasterId: props.warehouseMasterId || 0,
  };
  if (searchedText.length) {
    if (
      searchedText.length >= 3 &&
      !props.searchedText?.toLowerCase().startsWith(searchedText?.toLowerCase())
    ) {
      props.resetPaginatedData();
      props.getInventoryItemsByWarehouseCount(payload);
      props.fetchInventoryItemsByWarehouse(payload);
    }
  } else {
    props.resetPaginatedData();
    props.getInventoryItemsByWarehouseCount(payload);
    props.fetchInventoryItemsByWarehouse(payload);
  }
};

const handleWarehouseChanged = (warehouseMasterId, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    warehouseMasterId: warehouseMasterId || props.warehouseMasterId,
  };
  props.resetPaginatedData();
  props.getInventoryItemsByWarehouseCount(payload);
  props.fetchInventoryItemsByWarehouse(payload);
};

const StockSummaryListingComp = (props) => {
  const {
    updateState,
    searchedText,
    companyInfo,
    warehouseMasterId,
    intl,
    dir = 0,
    pageNumber,
    selectedItems,
    stockSummaryCount,
    permissions
  } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.STOCK_SUMMARY);
  },[]) 
  const [childOnList, setChildOn] = useState([]);
  const getStockDetail = (data, keyName, type) => {
    let sum = data[keyName] || 0;
    let variantCount = 0;
    data.inventoryItemVariantList &&
      data.inventoryItemVariantList.forEach(function (ele) {
        if (ele[keyName]) {
          variantCount = variantCount + 1;
          sum = sum + parseInt(ele[keyName] || 0);
        }
      });

    if (type === "sum") return sum;
    if (type === "detail") {
      let variantVal = variantCount > 1 ? "variants" : "variant";
      return "From " + variantCount + " " + variantVal;
    }
  };

  const getValuation = (data) => {
    let sum = 0;
    (data?.inventoryItemVariantList || []).forEach(function (ele) {
        sum = getVariantValuation(ele);
      });
    return sum;
  };

  const getVariantValuation = (variantObj) => {
    let sum = 0;
    variantObj.itemVariantStockList &&
      variantObj.itemVariantStockList.forEach(function (ele) {
        if (ele.currentStock || ele.purchasePrice) {
          sum = sum + (ele.currentStock * ele.purchasePrice);
        }
      });
    return sum;
  };
  const getVariantName = (data) => {
    const {attributeValue1, attributeValue2, attributeValue3} = data;
    const arr = [attributeValue1, attributeValue2, attributeValue3];
    return (
      <span>
        {arr.join(" | ")}
      </span>
    );
  };
  const _getDataList = (dataList = []) => {
    dataList.forEach((dt, dtIndex) => {
        dt.key = dtIndex;
        dt.currentStock = getStockDetail(dt, "currentStock", "sum");
        dt.salesCommitted = getStockDetail(dt, "salesCommitted", "sum");
        dt.qtyAvailable = (dt.currentStock - dt.salesCommitted)  + getStockDetail(dt, "onOrder", "sum");
        dt.qtyOnPO = getStockDetail(dt, "onOrder", "sum");
        dt.reorderQty = getStockDetail(dt, "reorderQty", "sum");
        dt.lowStockQty = getStockDetail(dt, "lowStockQty", "sum");
        dt.valuation = getValuation(dt);
        dt.purchasedPrice = dt.inventoryItemVariantList?.[0]?.itemVariantStockList?.[0]?.purchasePrice

        if (dt.hasVariant && (dt.inventoryItemVariantList || []).length)
          dt.children = dt.inventoryItemVariantList || [];

          (dt.children || []).forEach((dt2, dt2Index) => {
            dt2.itemName = getVariantName(dt2);
            dt2.key = dtIndex + "" + dt2Index;
            dt2.qtyAvailable = (dt2.currentStock - dt2.salesCommitted) + dt2.onOrder;
            dt2.qtyOnPO = dt2.onOrder;
            // dt2.valuation = getVariantValuation(dt2);
            dt2.valuation = getVariantValuation(dt2);
            dt2.purchasedPrice = dt2.purchasedPrice
          });
      });

    return dataList || [];
  };
  const stockSummaryList = _getDataList(
    cloneDeep(props.stockSummaryList[props.pageNumber])
  );
  if (!selectedItems) {
    props.updateState({ selectedItems: [] });
  }
  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      itemId: Number(item.itemId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.itemId !== item.itemId
      );
    } else {
      updatedItems = selectedItems;
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };
  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < stockSummaryList.length; i++) {
      let item = stockSummaryList[i];
      let selectItem = find(selectedItems, {
        itemId: Number(item.itemId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !stockSummaryList ||
      !stockSummaryList ||
      stockSummaryList.length === 0
    ) {
      return false;
    }
    for (let i = 0; i < stockSummaryList.length; i++) {
      let usr = stockSummaryList[i];
      let selectItem = find(selectedItems, {
        itemId: Number(usr.itemId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const exportExcelData = (summaryList, props) => {

     
    const dataList = (summaryList || []).flatMap((item) => {
    
      if(item?.hasVariant){
       
        const list = (item?.inventoryItemVariantList||[])?.map((val)=>{
         
          return {
            "SKU": val?.sku,
            "Product Name": item?.itemName,
            "Current Stock": val.currentStock,
            "Qty on SO": val.salesCommitted,
            "Qty Available": val.qtyAvailable,
            "Qty on PO": val.qtyOnPO,
            "Reorder Level":val.reorderQty,
            "Low Stock Level":val.lowStockQty,
            "Weight": val.attributeValue1,
            "Weight Type": val.attributeValue2,

             "Package Weight":val.attributeValue3,
            "Last Purchased On":val?.procurementDate
              ? getMomentDateForUIReadOnly({
                date: val?.procurementDate,
                format: format,
              })
              : "",
            "Last Purchase Price": val?.purchasedPrice,
            "Valuation": val?.valuation,
    
         };
       
        })
       
        return list;
      }
      else{
              return  {
                "SKU": item?.sku,
                "Product Name": item?.itemName,
                "Current Stock": item.currentStock,
                "Qty on SO": item.salesCommitted,
                "Qty Available": item.qtyAvailable,
                "Weight": item.attributeValue1,
            "Weight Type": item.attributeValue2,

             "Package Weight":item.attributeValue3,
                "Qty on PO": item.qtyOnPO,
                "Reorder Level":item.reorderQty,
                "Low Stock Level":item.lowStockQty,
                "Last Purchased On":item?.procurementDate
                  ? getMomentDateForUIReadOnly({
                    date: item?.procurementDate,
                    format: format,
                  })
                  : "",
                "Last Purchase Price": item?.purchasedPrice,
                "Valuation": item?.valuation,
          
             };
      }
     
   
   
     
    });
    
    
    exportExcel(dataList, "Stock Summary List");
  
  };
  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />
      
        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            const invoiceList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         invoiceList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              invoiceList.push(obj);
            });

            if (invoiceList.length) {
              exportExcelData(invoiceList);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

      
      </Menu>
    );
  };
 

 


  // const columnDefs = [
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.product' defaultMessage='' />,
  //         dataIndex: "itemName",
  //         key: "itemName",
  //         width: 200,
  //         className: 'leftAlignCell'
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.sku' defaultMessage='' />,
  //         dataIndex: "sku",
  //         key: "sku",
  //         width: 70,
  //         className: 'leftAlignCell'
  //     },
  //     // {
  //     //     title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.category' defaultMessage='' />,
  //     //     dataIndex: "categoryName",
  //     //     key: "categoryName"
  //     // },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.currentStock' defaultMessage='' />,
  //         dataIndex: "currentStock",
  //         key: "currentStock",
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.qtyOnSO' defaultMessage='' />,
  //         dataIndex: "salesCommitted",
  //         key: "salesCommitted"
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.qtyAvailable' defaultMessage='' />,
  //         dataIndex: 'qtyAvailable',
  //         key: 'qtyAvailable',
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.qtyOnPO' defaultMessage='' />,
  //         dataIndex: 'qtyOnPO',
  //         key: 'qtyOnPO',
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.reorderLevel' defaultMessage='' />,
  //         dataIndex: 'reorderQty',
  //         key: 'reorderQty',
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.stockSummary.listing.header.lowStockLevel' defaultMessage='' />,
  //         dataIndex: 'lowStockQty',
  //         key: 'lowStockQty',
  //     },
  //     {
  //         title: <FormattedMessage id='lastPurchasedOn.text' defaultMessage='' />,
  //         dataIndex: 'procurementDate',
  //         key: 'procurementDate',
  //         render(text, record) {
  //             return {
  //                 children: <div>{text ? getMomentDateForUIReadOnly({ date: text, format: format }) : ""}</div>
  //             };
  //         }
  //     },
  //     {
  //         title: <FormattedMessage id='inventory.warehouse.agedStock.listing.header.lastPurchasePrice' defaultMessage='' />,
  //         dataIndex: "purchasedPrice",
  //         key: 'purchasedPrice',
  //         render(text, record) {
  //             return {
  //                 children: <div style={{textAlign: 'right'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{(text || 0).toFixed(2)}</div>
  //             };
  //         }
  //     },
  //     {
  //         title: <FormattedMessage id='product.listing.header.valuation' defaultMessage='' />,
  //         dataIndex: "currentStock",
  //         key: 'currentStock',
  //         render(text, record) {
  //             return {
  //                 children: <div style={{textAlign: 'right'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{((record.currentStock || 0) * (record.purchasedPrice || 0)).toFixed(2)}</div>
  //             };
  //         }
  //     },
  // ]

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      warehouseMasterId: warehouseMasterId,
    };
    fetchPaginationDataIfNeeded(
      "fetchInventoryItemsByWarehouse",
      "stockSummaryList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      warehouseMasterId: warehouseMasterId,
    };
    props.resetPaginatedData();
    props.fetchInventoryItemsByWarehouse(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.stockSummary" defaultMessage="" />
    },
  ];

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("stock-summary-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  const isChildOn = (id) => {
    if (childOnList.includes(id)) {
      return true;
    }
    return false;
  };

  const setChildOff = (data) => {
    const itemIndex = childOnList.indexOf(data);
    const newArr = childOnList.splice(itemIndex, 1);
  };

  const openVarCurrentStockDetails = (item,e) => {
    props.pushModalToStack({
      title: <div>
        <div className="ant-modal-title">
          Current Stock Details
        </div>
        <div className="ant-modal-subtitle extra-sm">
          {capitalizeFirstLetter(e.itemName)}
          {item.itemName}
        </div>
        <div>
          <div className="w-200">{item.sku}</div>
          <div className="w-200">Total Stock : {item.currentStock}</div>
        </div>
      </div>,
      modalBody: <CurrentStockDetails {...props} stockSummaryData={item} />,
      width: 1000,
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openCurrentStockDetails = (item) => {
    const data = item.inventoryItemVariantList?.flatMap(i => i.itemVariantStockList || []) || [];

    console.log(data, ":::::::::;evv");
    
    props.pushModalToStack({
      title: <div>
            <div className="ant-modal-title">
              Current Stock Details
            </div>
            <div className="ant-modal-subtitle extra-sm">
              {item.itemName}
            </div>
            <div>
              <div className="w-200">{item.sku}</div>
              <div className="w-200">Total Stock : {item.currentStock}</div>
            </div>
          </div>,
      modalBody: <CurrentStockDetails {...props} stockSummaryData={data} />,
      width: 1000,
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.inventry.stockSummary"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>

      <div className="view-container">
        
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="inventory.stockSummary.table.heading"
                defaultMessage=""
              />
            </div>
            {stockSummaryCount? (
              <>
                <div className="vertical-sep" />
                <div>{stockSummaryCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
            <Search
              placeholder="Enter 3 Characters of SKUID or Product"
              value={searchedText}
              onChange={(e) => {
                updateState({ searchedText: e.target.value, pageNumber: 1 });
                onSearchedInputChange(e.target.value, props);
              }}
              onSearch={(searchedText) => {
                updateState({ searchedText, pageNumber: 1 });
                onSearchedInputChange(searchedText, props);
                handleSearchedText(searchedText, props);
              }}
              className="search-col"
            />
            {props.listLoading && !props.warehouseMasterId ? <Skeleton.Input active /> :
            <Dropdown
              style={{ width: 150 }}
              sortItems={true}
              items={props.allWarehouses || []}
              valueKeyName="warehouseName"
              optionKeyName="warehouseMasterId"
              value={props.warehouseMasterId || ""}
              placeholder={intl.formatMessage({
                id: "inventory.warehouse.stockSummary.listing.warehouseFilter.placeholder",
                defaultMessage: "",
              })}
              onSelect={(warehouseMasterId) => {
                updateState({ warehouseMasterId });
                handleWarehouseChanged(warehouseMasterId, props);
              }}
            />}
             <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>
          </div>
        </div>

        <Skeleton loading={props.listLoading} active paragraph={{ rows: 10 }}>
          <div className="table-container">
            <table id="stock-summary-table" className="stock-summary-table">
              <thead>
                <tr>
                  <th>
                  <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                  </th>
                  <th onClick={sortColumn} className="skuId">
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.sku"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="productName">
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.product"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.currentStock"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.qtyOnPO"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.qtyOnSO"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                 
                 
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.qtyAvailable"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.lowStockLevel"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.reorderLevel"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                 
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="lastPurchasedOn.text"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-end">
                    <FormattedMessage
                      id="inventory.warehouse.agedStock.listing.header.lastPurchasePrice"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-end">
                    <FormattedMessage
                      id="product.listing.header.valuation"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                </tr>
              </thead>

              <tbody>
                {(stockSummaryList || []).length ? stockSummaryList.map((rowData, index) => {
                      return (
                        <>
                          <tr key={index}>
                          <td>
                          <Checkbox
                            onChange={() => {
                              itemSelection(rowData);
                            }}
                            checked={
                              find(selectedItems, {
                                itemId: Number(rowData.itemId),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="one-line-view">{rowData.sku} </td>

                            <td>{rowData.itemName || ""} </td>
                            <td className="text-center">
                              <div
                                className={
                                  (rowData.currentStock !== 0 )
                                    ? "link-text"
                                    : ""
                                }
                                onClick={() => {
                                  if (rowData.currentStock !== 0 ) {
                                    openCurrentStockDetails(rowData);
                                  }
                                }}
                              >
                                {rowData.currentStock}
                              </div>

                            </td>
                            <td className="text-center"> {rowData.qtyOnPO} </td>
                            <td className="text-center"> {rowData.salesCommitted}</td>
                            <td className="text-center">{(rowData.qtyAvailable)} </td>
                            <td className="text-center">{rowData.lowStockQty} </td>
                            <td className="text-center">{rowData.reorderQty} </td>
                           
                            <td className="text-center">
                              {" "}
                              <div>
                                {(rowData?.inventoryItemVariantList||[])[0]?.procurementDate
                                  ? getMomentDateForUIReadOnly({
                                    date: rowData?.inventoryItemVariantList[0]?.procurementDate,
                                    format: format,
                                  })
                                  :<div className="empty-data-box"/>}
                              </div>{" "}
                            </td>
                            <td className="one-line-view text-end">
                              {getCurrencySymbol(props.companyInfo.currencyCode)}{(rowData.purchasedPrice || 0).toFixed(2)}
                            </td>
                            <td className="one-line-view text-end ">
                              {getCurrencySymbol(props.companyInfo.currencyCode)}{(rowData.valuation || 0).toFixed(2)}
                            </td>
                          </tr>
                          {
                            
                            !!(rowData.children || []).length &&
                            isChildOn(rowData.itemId)
                            && rowData.children.map((childRowData, i) => {
                              return (
                                <tr key={childRowData.rfqChildMasterId}>
                                  <td>
                                    <span className="child-sr">
                                      {/* {index + 1 + "." + Number(i + 1)} */}
                                      {(props.pageNumber - 1) * props.pageSize + (index + 1)}.{i + 1}
                                    </span>{" "}
                                    &nbsp;
                                  </td>
                                  <td className="one-line-view">{childRowData.sku} </td>
                                  <td>
                                    {`${childRowData.attributeValue1} ${childRowData.attributeValue2 ? " |" + childRowData.attributeValue2 : ''}`}
                                  </td>
                                 
                                  <td className="text-center">
                                    <div
                                      // className="table-link-text"
                                      className={
                                        childRowData.currentStock
                                          ? "link-text"
                                          : <div className="empty-data"></div>
                                      }
                                      onClick={() => {
                                        if (childRowData.currentStock !== 0) {

                                          openVarCurrentStockDetails(childRowData,rowData);
                                        }
                                      }}
                                    >
                                      {childRowData.currentStock}
                                    </div>

                                  </td>
                                  <td className="text-center"> {childRowData.qtyOnPO} </td>
                                  <td className="text-center"> {childRowData.salesCommitted}</td>
                                  <td className="text-center">{(childRowData.qtyAvailable)} </td>
                                  <td className="text-center">{childRowData.lowStockQty} </td>
                                  <td className="text-center">{childRowData.reorderQty} </td>
                                 
                                  <td className="text-center">
                                    {" "}
                                    <div>
                                      {childRowData?.procurementDate
                                        ? getMomentDateForUIReadOnly({
                                          date: childRowData?.procurementDate,
                                          format: format,
                                        })
                                        : ""}
                                    </div>{" "}
                                  </td>
                                  <td className="one-line-view text-end">
                                    {getCurrencySymbol(props.companyInfo.currencyCode)}
                                    {(childRowData.purchasedPrice || 0).toFixed(2)}
                                  </td>
                                  <td className="one-line-view text-end">
                                    {getCurrencySymbol(props.companyInfo.currencyCode)}
                                    {((childRowData.currentStock || 0) * (childRowData.purchasedPrice || 0)).toFixed(2)}
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </>
                      );
                    })
                    :
                    <tr key="empty-data-box">
                      <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                      </td>
                    </tr>
                  }
              </tbody>
            </table>
          </div>

           {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham nested-table" style={agGridStyle}>
                            {(stockSummaryList && stockSummaryList.length) ?
                                <Table pagination={false} columns={columnDefs} dataSource={stockSummaryList || []} />
                                : ''
                            }
                         <AgGridReact
                            columnDefs={columnDefs}
                            rowData={stockSummaryList || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            getRowHeight={getRowHeight}
                            onGridSizeChanged={onFirstDataRendered}
                    
                        </AgGridReact>
           </div> 
         </div>  */}

        </Skeleton>

        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              current={props.pageNumber}
              total={stockSummaryCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize || 25}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(StockSummaryListingComp);
