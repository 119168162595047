import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextBox } from "../../../../general/TextBox";
import { Row, Col, Button, Checkbox, Switch, Tabs, Select } from "antd";
import InventoryModel from "../../../../drawer/inventory/InventoryModel";
import { MODAL_TYPE, ICONS } from "../../../../../static/constants";
import SelectedProductModal from "./productSelection";
import CategoryModel from "../../../../drawer/inventory/CategoryDrawer/CategoryModel";
import CategorySelection from "./categorySelection";
import { Dropdown } from "../../../../general/Dropdown";

function Replacement(props) {
const { Option } = Select;

    const { intl, replaceSetting } = props;
    const handleParentCategory = () => {
        props.pushModalToStack({
            modalBody: <CategorySelection {...props} isReturnPolicy={true} cancelSet={true} replaceSet={true} returnSet={false} />,
            title: (
                <FormattedMessage
                    id="drawer.inventory.select.category.placeholder"
                    defaultMessage=""
                />
            ),
            width: "25%",
            hideTitle: false,
            hideFooter: true,
            wrapClassName: "cat-modal-custom",
        });
    };

    const getCategoryNames = () => {
        const { notReturnableCategoryRefs } = replaceSetting;
        const categoryNames = (notReturnableCategoryRefs || []).map((ref) => {
            const matchingCategory = props.productCategories.find(
                (category) => category.inventoryItemCategoryId === ref.inventoryItemCategoryId
            );
            return matchingCategory ? matchingCategory.categoryName : "";
        });
        return categoryNames?.join(", ");
    };

    const handleSelectedProduct = () => {
        props.pushModalToStack({
            modalBody: <SelectedProductModal {...props} replaceSet={true} cancelSet={true} returnSet={false} />,
            title: (
                <FormattedMessage
                    id="selectProduct.modal.title"
                    defaultMessage=""
                />),
            width: "25%",
            hideTitle: false,
            hideFooter: true,
            wrapClassName: "cat-modal-custom",
        });
    };
    const addReturnReason = (payload) => {
        const { showModal, hideModal } = props;
        const data = {
            title: "Reason",
            modalData: { modalType: MODAL_TYPE.RETURN_REASON, data: payload },
            hideFooter: false,
            handleSubmit: (value) => {
                let rasons = replaceSetting.returnReason;
                rasons.push({ reason: value.textBoxValue, available: 0 });

                props.updateState({
                    replaceSetting: {
                        ...replaceSetting,
                        returnReason: rasons,
                    }
                })
                hideModal();
            },
        };
        showModal(data);
    };
    const handleSave = () => {
        const payload = {
            ecomReturnSettingId: replaceSetting.ecomReturnSettingId || 0,
            relationshipId: props.companyInfo.relationshipId,
            isActive: replaceSetting.isActive,
            isOnlyByRequest:replaceSetting.isOnlyByRequest,
            returnDuration: replaceSetting.returnDuration,
            createReplaceOrder:replaceSetting.createReplaceOrder,
            type: "Replace",
            displayMessage: replaceSetting.displayMessage,
            notReturnableCategoryRefs:replaceSetting.isActive ? replaceSetting.notReturnableCategoryRefs?.length && (replaceSetting.notReturnableCategoryRefs || []).map((val) => {
                return {
                    inventoryItemCategoryId: val.inventoryItemCategoryId || null,
                    relationshipId: props.relationshipId,
                    notReturnableCategoryRefsId:0
                }
            }) || [{}]:[],

            notReturnableInventoryItemRefs:replaceSetting.isActive ? replaceSetting.notReturnableInventoryItemRefs.map(itemId => ({
                itemId: itemId,
                relationshipId: props.relationshipId,
                notReturnableCategoryRefsId:0
            })) || [{}]:[],
            version:props.version,
            returnReasons: JSON.stringify(replaceSetting.returnReason||"[]")

        }
        const modalData = {
            modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
            handleSubmit: () => {
                // props.hideModal();
                // if (props.replaceSameAsReturn === 1) {
                //     let pay = { ...payload, type: "Replace" }
                //     // props.saveReturnSettings([payload, pay], props);
                // }
                // else {
                    props.saveReturnSetting(payload, props);
                    props.hideModal();
                // }
            },
        };
        props.showModal(modalData);
    }
    return <>
        <div className="heading">
            <FormattedMessage id="eStore.storeSettings.replacement.label" defaultMessage="" />
        </div>
        <div className="model-container pt20">
            <Row gutter={[16, 16]}>
                <Col span={24} className="strong-txt">Allow Customers</Col>

                <Col span={12}>
                    <div> Replace order items</div>
                    Customers can replace the delivered order items with the same item or its variants.
                </Col>
                <Col span={12}>

                    <Switch checked={replaceSetting.isActive} disabled={props.replaceSameAsReturn === 1} onChange={(checked) => {
                        props.updateState({
                            replaceSetting: { ...replaceSetting, isActive: checked ? 1 : 0 }
                        })
                    }} />
                </Col>
            </Row>
              {replaceSetting.isActive ?  <>

            <Row gutter={[16, 16]}>
                <Col span={24} className="strong-txt">Include Shpping Charge</Col>

                <Col span={12}>
                    <div> Replace order items</div>
                    Customers can replace the delivered order items with the same item or its variants.
                </Col>
                <Col span={12}>

                    <Switch checked={replaceSetting.isOnlyByRequest} disabled={props.replaceSameAsReturn === 1} onChange={(checked) => {
                        props.updateState({
                            replaceSetting: { ...replaceSetting, isOnlyByRequest: checked ? 1 : 0 }
                        })
                    }} />
                </Col>
            </Row>
           
                
                <Row gutter={[16, 8]}>
                    <Col span={24} className="strong-txt mt20">Replacement Duration</Col>


                    <Col span={6}>
                        <TextBox suffix="days" disabled={props.replaceSameAsReturn === 1} value={replaceSetting.returnDuration} onChange={(e) => {
                            props.updateState({

                                replaceSetting: { ...replaceSetting, returnDuration: e.target.value }

                            });
                        }} />
                    </Col>

                    <Col span={24} className="strong-txt mt20">Select When Replacement Order will Geenratee</Col>


                    <Col span={6}>
                    <Dropdown
                        items={["Order", "Package", "Shipment"]}
                        defaultValue={replaceSetting.createReplaceOrder}
                        onSelect={(selectedValue, option) => {
                          props.updateState({
                            replaceSetting: { ...replaceSetting, createReplaceOrder: selectedValue }

                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          props.updateState({
                            
                            replaceSetting: { ...replaceSetting, createReplaceOrder: null }

                          });
                        }}
                      ></Dropdown>
                    </Col>
                    {replaceSetting.returnDuration && <Col span={24}>
                        Return will be allowed till {replaceSetting.returnDuration} days after delivery.
                    </Col>}
                </Row>
                    <Row gutter={[16,16]}>
                        {(replaceSetting.returnReason||[])?.map((item, index) => (
                            <Col span={3} key={index}>
                                <Checkbox
                                    checked={item.available}
                                    disabled={props.replaceSameAsReturn === 1}
                                    onChange={(e) => props.updateState({
                                        replaceSetting: {
                                            ...replaceSetting,
                                            returnReason: (replaceSetting.returnReason||[]).map(reason =>
                                                reason.reason === item.reason
                                                    ? { ...reason, available: e.target.checked ? 1 : 0 }
                                                    : reason
                                            )
                                        }
                                    })}
                                    className="mr5"
                                />
                                {item.reason}
                            </Col>
                        ))}
                    </Row><Col>
                        <Button disabled={props.replaceSameAsReturn === 1} onClick={() => {
                            addReturnReason(props, MODAL_TYPE.RETURN_REASON)
                        }}>Add More</Button>
                    </Col>
                    <Row gutter={[16, 8]}>
                        <Col span={24} className="strong-txt mt20">
                            Remove Replacement from Product & Category
                        </Col>
                        <Col span={24} className='i-label'>Category</Col>
                        <Col span={6}>
                            <div onClick={() => handleParentCategory()} onFocus={handleParentCategory}>
                                <TextBox
                                    value={getCategoryNames()}
                                    disabled={props.replaceSameAsReturn === 1}
                                    placeholder={intl.formatMessage({
                                        id: "drawer.inventory.select.category.placeholder",
                                        defaultMessage: "",
                                    })} />

                            </div>
                        </Col>

                        <Col span={6}>
                            {(replaceSetting.notReturnableInventoryItemRefs || []).length ?
                                <div onClick={() => handleSelectedProduct()} onFocus={handleSelectedProduct}
                                //   className="count-box"
                                >
                                    {/* {(replaceSetting.preSelectedItem || [])?.length} */}
                                    <TextBox
                                        value={(replaceSetting.notReturnableInventoryItemRefs || [])?.length}
                                        disabled={props.replaceSameAsReturn === 1}
                                    />
                                </div>
                                : ""
                            }
                        </Col>
                    </Row><Row gutter={[16, 8]}>

                        <Col span={12}>

                            {getCategoryNames() ? (
                                <>
                                    <div>Exempt Message </div>

                                    {!props.editMode ?
                                        <div onClick={() => {
                                            props.updateState({ editMode: true });
                                        }}>
                                            <span className="mr5">

                                                This Product/Category not applicable on Return Policy
                                            </span>
                                            <i className={ICONS.EDIT}></i>
                                        </div>
                                        : ""}

                                </>
                            ) : (
                                ""
                            )}

                            {props.editMode && (
                                <TextBox value={replaceSetting.displayMessage}
                                    disabled={props.replaceSameAsReturn === 1}
                                    onChange={(e) => {
                                        props.updateState({
                                            replaceSetting: { ...replaceSetting, displayMessage: e.target.value }

                                        });
                                    }} />
                            )}

                        </Col>

                    </Row>
                </>:""}
                    <div className="footer-btn" >
                        {
         props.replaceSameAsReturn === 1?"":
                        <Button type="primary" onClick={() => handleSave()}>Submit</Button>
                        }
                    </div>
                
        </div>
    </>
}
export default injectIntl(Replacement);