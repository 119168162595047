import React from "react";
import LeadStageListComp from "../../../components/leads/LeadStageList";
import LeadStageListDrawer from "../../drawer/leads/leadStageList";
// import { getAllLeadStage } from "../../modal/modalBody/leads/allLeads/action";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createLeadStage } from "../../drawer/leads/leadStageList/action";
import { deleteCrmLeadStage, getAllLeadStage } from "./action";
import { showModal, hideModal } from "../../../actions/commonActions";

class LeadStageList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
      pageSize: 100,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
    }
    this.props.getAllLeadStage(payload);
  }

  render() {
    return (
      <>
        <LeadStageListComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {this.state.leadStageListDrawer && <LeadStageListDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      crmLeadStageList: state.crm.crmLeadStatusList,
    permissions: state.auth.permissions,

  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllLeadStage,
  createLeadStage,
  deleteCrmLeadStage,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadStageList);
